/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import TextOnlyButton from "./TextOnlyButton";
import IconLeftButton from "./IconLeftButton";
import IconRightButton from "./IconRightButton";
import TwoIconsButton from "./TwoIconsButton";
import IconOnlyButton from "./IconOnlyButton";
import ButtonMui from "@mui/material/Button";
import { chooseValue } from "../../../utils/helpers";

type LinkButtonVariants =
    | "textOnly"
    | "iconLeft"
    | "iconRight"
    | "twoIcons"
    | "iconOnly";

type LinkButtonSizes = "small" | "normal" | "large";

type LinkButtonColors = "primary" | "secondary";

export interface LinkButtonProps {
    children?: any;
    variant: LinkButtonVariants;
    size: LinkButtonSizes;
    color: LinkButtonColors;
    compact?: boolean;
    icon?: any;
    isActive?: boolean;
    focus?: boolean;
    fullWidth?: boolean;
    onClick: () => void;
    idForTesting: string;
}
const LinkButton = ({
    children,
    variant, // textOnly, iconLeft, iconRight, twoIcons, iconOnly,
    size, // small, normal, large
    color, // primary, secondary
    compact,
    icon,
    isActive,
    focus,
    idForTesting,
    ...props
}: LinkButtonProps) => {
    const {
        colors: {
            gray100,
            gray200,
            blue200,
            blue400,
            blue700,
            blue800,
            blue900,
            textDark,
        },
    } = useContext(ThemeContext);

    const styleVariants = {
        colors: {
            primary: {
                text: {
                    rest: blue700,
                    hover: blue800,
                    active: blue900,
                },
                background: {
                    rest: "transparent",
                    hover: chooseValue(compact, "transparent", blue200),
                    active: chooseValue(compact, "transparent", blue400),
                },
            },
            secondary: {
                text: {
                    rest: textDark,
                    hover: chooseValue(compact, blue800, textDark),
                    active: chooseValue(compact, blue900, textDark),
                },
                background: {
                    rest: "transparent",
                    hover: chooseValue(compact, "transparent", gray100),
                    active: chooseValue(compact, "transparent", gray200),
                },
            },
        },
        paddings: {
            textOnly: {
                small: chooseValue(compact, "8px 0", "8px 16px"),
                normal: chooseValue(compact, "12px 0", "12px 16px"),
                large: chooseValue(compact, "15px 0", "15px 24px"),
            },
            iconLeft: {
                small: chooseValue(compact, "6px 0", "6px 16px 6px 12px"),
                normal: chooseValue(compact, "10px 0", "10px 16px 10px 12px"),
                large: chooseValue(compact, "14px 0", "14px 24px 14px 16px"),
            },
            iconRight: {
                small: chooseValue(compact, "6px 0", "6px 12px 6px 16px"),
                normal: chooseValue(compact, "10px 0", "10px 12px 10px 16px"),
                large: chooseValue(compact, "14px 0", "14px 16px 14px 24px"),
            },
            twoIcons: {
                small: chooseValue(compact, "6px 0", "6px 12px"),
                normal: chooseValue(compact, "10px 0", "10px 12px"),
                large: chooseValue(compact, "14px 0", "14px 16px"),
            },
            iconOnly: {
                small: "6px",
                normal: "10px",
                large: "14px",
            },
        },
        typography: {
            small: {
                fontSize: "14px",
                letterSpacing: "0.1px",
            },
            normal: {
                fontSize: "14px",
                letterSpacing: "0.1px",
            },
            large: {
                fontSize: "16px",
                letterSpacing: "0.15px",
            },
        },
    };

    const bgColorRest = styleVariants.colors[color].background.rest;
    const bgColorHover = styleVariants.colors[color].background.hover;
    const bgColorActive = styleVariants.colors[color].background.active;

    const textColorRest = styleVariants.colors[color].text.rest;
    const textColorHover = styleVariants.colors[color].text.hover;
    const textColorActive = styleVariants.colors[color].text.active;

    const padding = styleVariants.paddings[variant][size];
    const fontSize = styleVariants.typography[size].fontSize;
    const letterSpacing = styleVariants.typography[size].letterSpacing;

    const renderButtonVariant = () => {
        switch (variant) {
            case "iconLeft":
                return <IconLeftButton children={children} icon={icon} />;
            case "iconRight":
                return <IconRightButton children={children} icon={icon} />;
            case "twoIcons":
                return <TwoIconsButton children={children} icon={icon} />;
            case "iconOnly":
                return <IconOnlyButton icon={icon} />;
            default:
                return <TextOnlyButton children={children} />;
        }
    };

    return (
        <ButtonMui
            {...props}
            data-test={idForTesting}
            autoFocus={focus}
            variant="contained"
            css={css`
                background-color: ${isActive ? bgColorActive : bgColorRest};
                color: ${isActive ? textColorActive : textColorRest};
                font-size: ${fontSize};
                letter-spacing: ${letterSpacing};
                padding: ${padding};

                &:hover {
                    color: ${textColorHover};
                    background-color: ${isActive
                    ? bgColorActive
                    : bgColorHover};
                }
            `}
        >
            {renderButtonVariant()}
        </ButtonMui>
    );
};

export default LinkButton;
