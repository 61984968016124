import React, { useContext, useReducer } from "react";

import ReactGA from "react-ga4";

import useApi from "../../utils/useApi";
import useTct from "../../utils/useTct";
import LanguageContext from "../language/languageContext";

import {
    DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS,
    RELOAD_MENU_ITEM,
    RESET_LAYOUT_DATA,
    SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX,
    SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX,
    SET_ACTIVE_FRAME_INDEX,
    SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME,
    SET_DEVICE_GUIDE,
    SET_DRAWER_BLOCK_NAME,
    SET_DRAWER_DATA,
    SET_LAYOUT_DATA,
    SET_LAYOUT_ERROR,
    SET_LIST_VALUES,
    SET_NEXT_MENU_ITEM_INDEX,
    SET_ONLINE_LAYOUT,
    SET_UPLOADED_FILE_DATA_TO_LAYOUT,
    SET_UPLOAD_FILE_LOAD_STATUS,
    SET_UPLOAD_FILE_NAME,
    START_ACTION_LOADING,
    STOP_ACTION_LOADING,
} from "./layoutActions";
import LayoutContext from "./layoutContext";
import LayoutReducer from "./layoutReducer";

interface LayoutStateProps {
    children: React.ReactNode;
}

const LayoutState = ({ children }: LayoutStateProps) => {
    const { getSettingsWithQueryParamsAsync } = useTct();

    const initialState = {
        layoutLoading: true,
        layoutError: false,
        layoutData: null,
        actionLoading: false,
        rebooting: false,
        readingAccelometer: false,
        capturingDump: false,
        fwUpdating: false,
        configResetting: false,
        navigationDisabled: false,
        menuItemIndex: null,
        activeFrameIndex: null,
        activeDeviceGuideComponentIndex: null,
        activeDeviceGuideFrameIndex: null,
        listValues: { 0: [] },
        drawerParameterId: 0,
        drawerBlockName: "",
        bluetoothLayoutResetting: false,
        reloadMenuItem: false,
        layoutErrorMessage: "",
        deviceConfigureButtonPortName: "",
        deviceGuide: null,
        uploadFileLoadStatus: "",
        uploadFileName: "",
    };

    const [state, dispatch] = useReducer(LayoutReducer, initialState);

    const setDeviceConfigureButtonPortName = (port: string) => {
        dispatch({
            type: SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME,
            payload: port,
        });
    };

    const setLayoutError = (error: any) => {
        const errorMessage = error.response?.data?.detail || "";
        dispatch({ type: SET_LAYOUT_ERROR, payload: errorMessage });
    };

    const setLayoutData = (data: any) =>
        dispatch({ type: SET_LAYOUT_DATA, payload: data });

    const setLayoutDataWithSettings = async (
        hwVersion: string,
        specId: number,
        isOnline: boolean,
        otherData: any,
    ) => {
        try {
            const settingsData = await getSettingsWithQueryParamsAsync(
                hwVersion,
                specId,
                isOnline,
            );

            ReactGA.gtag("event", "load_config_selection", {
                selection: settingsData?.deviceSettings[0].loadStatus,
            });

            setLayoutData({ ...otherData, settings: { ...settingsData } });
            const transformDataForCollections = () => {
                const bindableListsObject: any = {};
                otherData.initialValues.bindableLists.forEach(
                    (bindableList: any) => {
                        bindableListsObject[bindableList.listName] =
                            bindableList.items.filter(
                                (item: any) => item.value !== "",
                            );
                    },
                );
                return bindableListsObject;
            };
            setListValues(transformDataForCollections());
        } catch (error) {
            setLayoutError(error);
        }
    };

    const startActionLoading = (action: string) =>
        dispatch({ type: START_ACTION_LOADING, payload: action });

    const stopActionLoading = () => dispatch({ type: STOP_ACTION_LOADING });

    const resetLayoutData = () => dispatch({ type: RESET_LAYOUT_DATA });

    const disableNavigationBetweenMenuItems = (action: boolean) => {
        dispatch({
            type: DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS,
            payload: action,
        });
    };

    const setNextMenuItemIndex = (action: number) => {
        dispatch({
            type: SET_NEXT_MENU_ITEM_INDEX,
            payload: action,
        });
    };

    const setActiveFrameIndex = (index: number | null | undefined) =>
        dispatch({ type: SET_ACTIVE_FRAME_INDEX, payload: index });

    const setActiveDeviceGuideStep = (index: number | null) =>
        dispatch({
            type: SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX,
            payload: index,
        });

    const setActiveDeviceGuideFrameIndex = (index: number | null) =>
        dispatch({ type: SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX, payload: index });

    const findBindedList = (bindedListName: string | undefined) => {
        const bindableLists = state.layoutData?.initialValues.bindableLists;
        if (bindableLists) {
            return bindableLists.find(
                (item: any) => item.listName === bindedListName,
            );
        }
    };
    const setListValue = (items: any[], name: string) =>
        dispatch({ type: SET_LIST_VALUES, payload: { [name]: items } });

    const setListValues = (items: { [key: string]: any[] }) =>
        dispatch({ type: SET_LIST_VALUES, payload: items });

    const clearSearchHistory = () => localStorage.removeItem("searchHistory");

    const setDrawerParameterId = (parameterId: number) => {
        dispatch({ type: SET_DRAWER_DATA, payload: parameterId });
    };

    const setDrawerBlockName = (blockName: string) => {
        dispatch({ type: SET_DRAWER_BLOCK_NAME, payload: blockName });
    };
    const setReloadMenuItem = (reloadMenuItem: boolean) => {
        dispatch({ type: RELOAD_MENU_ITEM, payload: reloadMenuItem });
    };

    const setDeviceGuide = (steps: any) =>
        dispatch({ type: SET_DEVICE_GUIDE, payload: steps });

    const setUploadedFileDataToLayout = (data: {
        path: string;
        fromFile: boolean;
    }) => {
        dispatch({ type: SET_UPLOADED_FILE_DATA_TO_LAYOUT, payload: data });
    };

    const setUploadFileLoadStatus = (status: string) => {
        dispatch({ type: SET_UPLOAD_FILE_LOAD_STATUS, payload: status });
    };

    const setUploadFileName = (name: string) => {
        dispatch({ type: SET_UPLOAD_FILE_NAME, payload: name });
    };

    const setOnlineLayout = (isOnline: boolean) => {
        dispatch({ type: SET_ONLINE_LAYOUT, payload: isOnline });
    };

    return (
        <LayoutContext.Provider
            value={{
                layoutLoading: state.layoutLoading,
                layoutError: state.layoutError,
                layoutData: state.layoutData,
                actionLoading: state.actionLoading,
                readingAccelometer: state.readingAccelometer,
                capturingDump: state.capturingDump,
                rebooting: state.rebooting,
                fwUpdating: state.fwUpdating,
                configResetting: state.configResetting,
                navigationDisabled: state.navigationDisabled,
                menuItemIndex: state.menuItemIndex,
                activeFrameIndex: state.activeFrameIndex,
                activeDeviceGuideComponentIndex:
                    state.activeDeviceGuideComponentIndex,
                activeDeviceGuideFrameIndex: state.activeDeviceGuideFrameIndex,
                listValues: state.listValues,
                drawerParameterId: state.drawerParameterId,
                drawerBlockName: state.drawerBlockName,
                bluetoothLayoutResetting: state.bluetoothLayoutResetting,
                reloadMenuItem: state.reloadMenuItem,
                layoutErrorMessage: state.layoutErrorMessage,
                deviceConfigureButtonPortName:
                    state.deviceConfigureButtonPortName,
                uploadFileLoadStatus: state.uploadFileLoadStatus,
                uploadFileName: state.uploadFileName,
                setLayoutError,
                setReloadMenuItem,
                setLayoutData,
                setLayoutDataWithSettings,
                startActionLoading,
                stopActionLoading,
                resetLayoutData,
                disableNavigationBetweenMenuItems,
                setNextMenuItemIndex,
                setActiveFrameIndex,
                setActiveDeviceGuideStep,
                setActiveDeviceGuideFrameIndex,
                findBindedList,
                setListValue,
                setListValues,
                clearSearchHistory,
                setDrawerParameterId,
                setDrawerBlockName,
                setDeviceConfigureButtonPortName,
                setDeviceGuide,
                deviceGuide: state.deviceGuide,
                setUploadedFileDataToLayout,
                setUploadFileLoadStatus,
                setUploadFileName,
                setOnlineLayout,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutState;
