/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import { SearchRounded } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

import DeleteCircleIcon from "../../assets/icons/DeleteCircleIcon";
import LanguageContext from "../../context/language/languageContext";
import ThemeContext from "../../context/theme/themeContext";

export interface SearchInputProps {
    onChange: (e: any) => void;
    resetValue: () => void;
    searchValue: string;
    disabled?: boolean;
    additionalStyles?: any;
}

const SearchInput = ({
    onChange,
    searchValue,
    resetValue,
    disabled,
    additionalStyles,
}: SearchInputProps) => {
    const {
        colors: {
            gray300,
            gray700,
            textDark,
            gray400,
            blue700,
            white,
            gray500,
            textDarkDisabled,
            textPlaceholder,
            gray100,
        },
        boxShadows: { focusBoxShadow },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    return (
        <TextField
            fullWidth
            variant="filled"
            placeholder={t.Search}
            value={searchValue || ""}
            onChange={onChange}
            disabled={disabled}
            InputProps={{
                spellCheck: "false",
                disableUnderline: true,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRounded
                            css={css({
                                fill: disabled
                                    ? textPlaceholder
                                    : "currentcolor",
                            })}
                        />
                    </InputAdornment>
                ),
                endAdornment: searchValue ? (
                    <InputAdornment position="end">
                        <DeleteCircleIcon
                            css={css({ color: gray500, cursor: "pointer" })}
                            onClick={resetValue}
                        />
                    </InputAdornment>
                ) : (
                    ""
                ),
                sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    color: textDark,
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    padding: "6px 12px",
                    borderRadius: "6px",
                    fontSize: "14px",
                    border: `1px solid ${gray300}`,
                    fontFamily: "Open Sans",
                    "&:hover": {
                        borderColor: disabled ? gray300 : gray400,
                        backgroundColor: white,
                    },
                    "&.Mui-focused": {
                        borderColor: blue700,
                        borderWidth: "1px",
                        boxShadow: focusBoxShadow,
                        backgroundColor: white,
                    },

                    "&.Mui-disabled": {
                        color: textDarkDisabled,
                        WebkitTextFillColor: textDarkDisabled,
                        backgroundColor: gray100,
                    },

                    "& .MuiFilledInput-input": {
                        padding: "0",
                        "&::placeholder": {
                            color: disabled ? textPlaceholder : gray700,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            fontWeight: 600,
                            opacity: 1,
                            WebkitTextFillColor: disabled
                                ? textDarkDisabled
                                : gray700,
                        },
                    },

                    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                        {
                            marginTop: "0",
                        },

                    "& .MuiInputAdornment-root": {
                        color: gray700,
                        height: "unset",
                    },
                    ...additionalStyles,
                },
            }}
        />
    );
};

export default SearchInput;
