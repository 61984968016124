import {
    RESET_BLUETOOTH_VIEW,
    SET_ADD_DEVICE_MANUALLY_MODAL,
    SET_ADD_DEVICE_MODAL,
    SET_BLUETOOTH_DEVICES,
    SET_BLUETOOTH_SCENARIO_MODAL,
    SET_EDIT_DEVICE_MODAL,
    SET_TABLE_SEARCH_VALUE,
} from "./bluetoothViewActions";

export interface InitialState {
    searchValue: string;
    isAddDeviceModalOpen: boolean;
    isEditDeviceModalOpen: boolean;
    bluetoothTotalCount: number;
    isAddDeviceManuallyModalOpen: boolean;
    bluetoothScenarioModalInfo: {
        isOpen: boolean;
        data?: any;
        isEdit?: boolean;
        isAddManually?: boolean;
    };
    bluetoothDevices: any;
}

type Action =
    | { type: typeof SET_TABLE_SEARCH_VALUE; payload: any }
    | { type: typeof SET_ADD_DEVICE_MODAL; payload: boolean }
    | {
          type: typeof SET_BLUETOOTH_SCENARIO_MODAL;
          payload: {
              isOpen: boolean;
              data?: any;
              isEdit?: boolean;
              isAddManually?: boolean;
          };
      }
    | { type: typeof SET_ADD_DEVICE_MANUALLY_MODAL; payload: boolean }
    | { type: typeof SET_EDIT_DEVICE_MODAL; payload: boolean }
    | { type: typeof RESET_BLUETOOTH_VIEW }
    | {
          type: typeof SET_BLUETOOTH_DEVICES;
          payload: any;
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_BLUETOOTH_DEVICES: {
            return {
                ...state,
                bluetoothDevices: action.payload,
                bluetoothTotalCount: action.payload.length,
            };
        }
        case SET_TABLE_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: action.payload,
            };
        }
        case SET_ADD_DEVICE_MODAL: {
            return {
                ...state,
                isAddDeviceModalOpen: action.payload,
            };
        }
        case SET_BLUETOOTH_SCENARIO_MODAL: {
            return {
                ...state,
                bluetoothScenarioModalInfo: {
                    isOpen: action.payload.isOpen,
                    data: action.payload.data,
                    isEdit: action.payload.isEdit,
                    isAddManually: action.payload.isAddManually,
                },
            };
        }
        case SET_ADD_DEVICE_MANUALLY_MODAL: {
            return {
                ...state,
                isAddDeviceManuallyModalOpen: action.payload,
            };
        }
        case SET_EDIT_DEVICE_MODAL: {
            return {
                ...state,
                isEditDeviceModalOpen: action.payload,
            };
        }
        case RESET_BLUETOOTH_VIEW: {
            return {
                ...state,
                searchValue: "",
                isAddDeviceModalOpen: false,
                isEditDeviceModalOpen: false,
                bluetoothTotalCount: 0,
                isAddDeviceManuallyModalOpen: false,
                bluetoothScenarioModalInfo: {
                    isOpen: false,
                    data: null,
                    isEdit: false,
                    isAddManually: false,
                },
                bluetoothDevices: null,
            };
        }
        default:
            return state;
    }
};
