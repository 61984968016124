/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { jsx } from "@emotion/react";

import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import { Component } from "../../../../../generatedTypes";
import NewTextBox from "../../../../MuiComponents/reusableInputs/NewTextBox";

export interface TextBoxProps {
    data: Component;
    onChange?: (e: { id: number; value: string }, error?: any) => void;
    onError?: (error: string | null) => void;
    multiline?: boolean;
}

const TextBox = ({ data, onChange, onError, multiline }: TextBoxProps) => {
    const { parameterId, parameterValue } = data;
    const { findParameterValueById } = useContext(MenuItemContext);

    return (
        <NewTextBox
            componentValue={
                findParameterValueById(parameterId) || parameterValue
            }
            data={data}
            onChange={onChange}
            onError={onError}
            multiline={multiline}
        />
    );
};

export default TextBox;
