import { createContext } from "react";

import { BleDevice } from "../../generatedTypes";

import { InitialState } from "./bluetoothViewReducer";

interface BluetoothViewContextState extends InitialState {
    setTableSearchValue: (data: string) => void;
    setAddDeviceModalOpen: (data: boolean) => void;
    setAddDeviceManuallyModalOpen: (data: boolean) => void;
    setBluetoothScenarioModalInfo: (data: {
        isOpen: boolean;
        data?: BleDevice | null;
        isEdit?: boolean;
        isAddManually?: boolean;
    }) => void;
    resetBluetoothView: () => void;
    setBluetoothDevices: (data: any) => void;
}

const BluetoothViewContext = createContext({} as BluetoothViewContextState);

export default BluetoothViewContext;
