/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, MouseEvent, useContext, useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { ReactComponent as OpenModalIcon } from "../../../assets/icons/OpenModalIcon.svg";
import { EDITOR_MENU_ITEM } from "../../../constants/routes";
import LanguageContext from "../../../context/language/languageContext";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import LayoutContext from "../../../context/layout/layoutContext";
import useApi from "../../../utils/useApi";
import useTct from "../../../utils/useTct";
import DynamicContentModal from "../../MuiComponents/Modals/DynamicContentModal";
import Breadcrumbs from "../../MuiComponents/Breadcrumbs";
import Container from "../../Container";
import TextLink from "../../MuiComponents/TextLink";
import Button from "../../MuiComponents/Button";
import UserLayout from "../UserLayout";
import FrameItemViewSkeleton from "./FrameItemViewSkeleton";
import FrameItemsMenu from "./FrameItemsMenu";
import Frame from "../MenuItemView/Frame";

export type FrameItemViewParams = {
    menuItem: string;
    frameItemIndex: string;
};

const FrameItemView = () => {
    const {
        activeFrame,
        fieldValidations,
        setActiveFrame,
        setFieldValidations,
    } = useContext(MenuItemContext);
    const { layoutData, actionLoading, stopActionLoading } =
        useContext(LayoutContext);
    const { selectedLanguage, t } = useContext(LanguageContext);

    const configurationId = layoutData?.id;

    const { frameItemIndex, menuItem } = useParams<FrameItemViewParams>();

    const history = useHistory();
    const { cancelSource, isCanceled } = useApi();
    const { getFrameAsync } = useTct();

    const [loading, setLoading] = useState(true);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        const getFrameItemData = async () => {
            try {
                if (fieldValidations.length > 0) {
                    setFieldValidations([], true);
                }

                setLoading(true);

                const data = await getFrameAsync(
                    configurationId ?? 0,
                    Number(menuItem),
                    Number(frameItemIndex)
                );
                if (isSubscribed) {
                    setActiveFrame(data);
                    actionLoading && stopActionLoading();
                    setLoading(false);
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    setActiveFrame(null);
                    actionLoading && stopActionLoading();
                    setLoading(false);
                }
            }
        };

        configurationId && getFrameItemData();

        return () => {
            setActiveFrame(null);
            isSubscribed = false;
            cancelSource.cancel("Api is being cancelled");
        };

        // eslint-disable-next-line
    }, [selectedLanguage, frameItemIndex, configurationId, menuItem]);

    const openItemsMenu = (e: MouseEvent<HTMLDivElement>) =>
        setMenuAnchorEl(e.currentTarget);

    const closeItemsMenu = () => setMenuAnchorEl(null);

    const goToHub = () => {
        const generatedPath = generatePath(EDITOR_MENU_ITEM, {
            menuItem,
        });

        history.push(generatedPath);
    };

    const renderContent = () => {
        if (actionLoading || loading) {
            return <FrameItemViewSkeleton />;
        }

        if (!activeFrame) {
            return <div>Frame item error page</div>;
        }

        return (
            <Container
                css={css({
                    marginLeft: "24px",
                    marginRight: "24px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    maxWidth: activeFrame.ioContainerProperties
                        ? "unset"
                        : "1156px",
                    width: "100%",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                    })}
                >
                    <div>
                        <TextLink
                            idForTesting="backToHub"
                            size="normal"
                            onClick={goToHub}
                            startIcon
                        >
                            {t.BackTo} {activeFrame.menuItemName}
                        </TextLink>

                        <div
                            css={css({
                                margin: "8px 0 16px 18px",
                            })}
                        >
                            <Breadcrumbs>
                                {activeFrame.menuItemName}
                            </Breadcrumbs>

                            <Breadcrumbs
                                isSeparator
                                isActive
                                isIconRight
                                onClick={openItemsMenu}
                            >
                                {activeFrame.name}
                            </Breadcrumbs>
                        </div>

                        <FrameItemsMenu
                            anchorEl={menuAnchorEl}
                            closeMenu={closeItemsMenu}
                            activeFrame={activeFrame}
                        />
                    </div>
                    {activeFrame.modal && (
                        <Button
                            variant="iconLeft"
                            size="small"
                            color="white"
                            icon={<OpenModalIcon />}
                            css={css({
                                marginBottom: "20px",
                                padding: "0px 16px",
                                boxShadow:
                                    "0px 1px 4px 0px rgba(15, 23, 42, 0.12)",
                            })}
                            onClick={() => setIsModalOpen(true)}
                            idForTesting="searchButton"
                        >
                            {activeFrame.linkToModalName}
                        </Button>
                    )}
                </div>

                <Frame data={activeFrame} />
            </Container>
        );
    };

    return (
        <Fragment>
            <UserLayout hasOverFlow={activeFrame?.ioContainerProperties}>
                {renderContent()}
            </UserLayout>
            {isModalOpen && (
                <DynamicContentModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    modalData={activeFrame?.modal}
                />
            )}
        </Fragment>
    );
};

export default FrameItemView;
