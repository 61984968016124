import React, { SyntheticEvent } from "react";

import { NameValueStringsPair } from "../../../constants/constants";
import Autocomplete from "../Autocomplete";
import Checkbox from "../Checkbox";

interface FilterDropdownProps {
    currentValue: NameValueStringsPair[];
    placeholder: string;
    options: NameValueStringsPair[];
    isDisabled: boolean;
    checkbox?: boolean;
    customCSS?: {};
    handleChange: (
        event: SyntheticEvent<Element, Event>,
        newValues: any[],
    ) => void;
}

const FilterDropdown = ({
    currentValue,
    placeholder,
    options,
    isDisabled,
    checkbox,
    customCSS,
    handleChange,
}: FilterDropdownProps) => {
    return (
        <Autocomplete
            multiple
            includeInputInList
            disableClearable
            disableCloseOnSelect
            textFieldParams={{
                placeholder,
            }}
            value={currentValue}
            size="small"
            options={options}
            onChange={(e, newFilterValue) => handleChange(e, newFilterValue)}
            isOptionEqualToValue={(option, val) => option.value === val.value}
            getOptionLabel={(option) => option.name}
            disabled={isDisabled}
            renderOption={(props, option, { selected }) => (
                <li
                    {...props}
                    key={option.value}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        opacity: 1,
                    }}
                >
                    {option.name}

                    {checkbox && <Checkbox checked={selected} />}
                </li>
            )}
            renderTags={() => null}
            customStyle={customCSS}
        />
    );
};

export default FilterDropdown;
