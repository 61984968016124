/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import InfoIcon from "../../../../../../../../assets/icons/InfoIcon";
import { ReactComponent as IdentificationIcon } from "../../../../../../../../assets/icons/bluetooth/Identification.svg";
import ThemeContext from "../../../../../../../../context/theme/themeContext";
import { BleScenario } from "../../../../../../../../generatedTypes";
import Tooltip from "../../../../../../../MuiComponents/Tooltip";

export interface ScenarioCardProps {
    data: BleScenario;
    onClick?: () => void;
    isActive?: boolean;
}

const ScenarioCard: React.FunctionComponent<ScenarioCardProps> = ({
    data,
    onClick,
    isActive,
}) => {
    const { name, localizedName, tooltipText, description } = data;

    const {
        colors: { gray50, gray200, blue700, textDark, blue100 },
    } = useContext(ThemeContext);

    const renderTooltip = () => {
        return (
            <Tooltip title={tooltipText} small placement="top">
                <span
                    css={css({
                        alignItems: "center",
                        display: "flex",
                        "& > svg": {
                            fontSize: "16px",
                            color: blue700,
                        },
                    })}
                >
                    <InfoIcon />
                </span>
            </Tooltip>
        );
    };

    const icons: { [key: string]: any } = {
        ["Identification"]: <IdentificationIcon />,
    };

    return (
        <div
            css={css({
                padding: "16px",
                borderRadius: "8px",
                border: `1px solid ${gray200}`,
                gap: "8px",
                display: "flex",
                flexDirection: "column",
                color: textDark,
                maxWidth: "400px",
                width: "100%",
                cursor: "pointer",
                backgroundColor: isActive ? blue100 : "white",
                "&:hover": {
                    background: gray50,
                },
            })}
            onClick={onClick}
        >
            <div
                css={css({
                    paddingLeft: "12px",
                })}
            >
                {name && icons[name]}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                })}
            >
                <div
                    css={css({
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px", // 142.857%
                        letterSpacing: "0.1px",
                    })}
                >
                    {localizedName}
                </div>
                <div
                    css={css({
                        backgroundColor: "white",
                        borderRadius: "6px",
                        padding: "4px",
                    })}
                >
                    {" "}
                    {renderTooltip()}
                </div>
            </div>
            <div
                css={css({
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px", // 142.857%
                    letterSpacing: "0.1px",
                })}
            >
                {description}
            </div>
        </div>
    );
};

export default ScenarioCard;
