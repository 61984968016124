import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs0: true;
        xs: true;
        sm: true;
        md480: true;
        md: true;
        md920: true;
        md680: true;
        lg: true;
        xl: true;
    }
}

const useMuiStyles = () => {
    const { colors } = useContext(ThemeContext);

    return {
        typography: {
            fontFamily: '"Open Sans", sans-serif',
        },
        palette: {
            primary: {
                main: colors.blue700,
            },
            ...colors,
        },
        breakpoints: {
            values: {
                xs0: 0,
                xs: 360,
                md480: 480,
                sm: 600,
                md680: 680,
                md: 840,
                md920: 920,
                lg: 1024,
                xl: 1440,
            },
        },
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        "&.MuiSelect-icon": {
                            right: "12px",
                            color: colors.gray500,
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        "&.MuiTab-root": {
                            "&.Mui-selected": {
                                color: colors.textDark,
                            },
                        },
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableFocusRipple: true,
                },
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        fontWeight: "600",
                        borderRadius: "6px",
                        boxShadow: "none",
                        minWidth: "unset",
                        lineHeight: "20px",

                        "&.Mui-focusVisible": {
                            boxShadow: "none",
                        },

                        "&:hover": {
                            boxShadow: "none",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        border: "none",
                        borderRadius: "6px",
                        fontSize: "14px",

                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.gray400,
                        },

                        "&:hover.Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.red800,
                        },

                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.blue700,
                            borderWidth: "1px",
                            boxShadow: `-2px -2px 0px ${colors.blue200}, -2px 2px 0px ${colors.blue200}, 2px -2px 0px ${colors.blue200}, 2px 2px 0px ${colors.blue200}`,
                        },

                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: colors.red700,
                                boxShadow: `-2px -2px 0px ${colors.red100}, -2px 2px 0px ${colors.red100}, 2px -2px 0px ${colors.red100}, 2px 2px 0px ${colors.red100}`,
                            },

                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.red700,
                        },

                        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.gray300,
                        },
                    },
                    input: {
                        "&.MuiSelect-select": {
                            minHeight: "unset",
                        },
                    },
                    notchedOutline: {
                        borderColor: colors.gray300,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        color: colors.textDark,
                    },
                    select: {
                        fontWeight: "600",
                        lineHeight: "24px",

                        "&.MuiInputBase-input": {
                            "&.MuiInputBase-input": {
                                paddingRight: "48px",
                            },
                        },

                        "&.Mui-disabled": {
                            color: colors.textDarkDisabled,
                            WebkitTextFillColor: colors.textDarkDisabled,
                        },

                        "& svg": {
                            marginRight: "8px",
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        margin: "6px 0 0",
                        display: "flex",
                        alignItems: "center",
                        color: colors.gray700,
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        letterSpacing: "0.1px",

                        "&.Mui-error": {
                            color: colors.gray700,
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow:
                            "0px 0px 14px -6px rgba(24, 39, 75, 0.18), 0px 10px 32px -4px rgba(24, 39, 75, 0.14)",
                        borderRadius: "6px",
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        "&::-webkit-scrollbar": {
                            width: "18px",
                        },

                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: colors.blue200,
                            borderRadius: "100px",
                            border: `5px solid ${colors.white}`,
                            minHeight: "24px",
                        },

                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: colors.blue400,
                        },
                    },
                    list: {
                        padding: "0",
                        maxHeight: "192px",
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        padding: "12px 16px",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        color: colors.textDark,
                        letterSpacing: "0.1px",
                        whiteSpace: "unset",
                        wordBreak: "break-word",

                        "& svg": {
                            marginRight: "8px",
                        },

                        "&:hover": {
                            backgroundColor: colors.gray100,
                        },

                        "&.Mui-selected, &.Mui-selected:hover": {
                            backgroundColor: colors.blue200,
                        },

                        "@media (min-width: 600px)": {
                            minHeight: "48px",
                        },
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: "12px",
                        margin: "24px",
                        width: "calc(100% - 48px)",
                    },
                    paperFullScreen: {
                        borderRadius: "unset",
                        margin: "0",
                        width: "100%",
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: "24px 16px 12px 16px",
                        color: colors.textDark,
                        fontSize: "24px",
                        fontWeight: "600",
                        lineHeight: "32px",
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: "16px",
                        color: colors.textDark,
                        borderTopColor: colors.gray300,
                        borderBottomColor: colors.gray300,
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "12px 16px 16px 16px",
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: colors.gray300,
                        padding: "0",

                        "&:hover": {
                            color: colors.gray400,
                        },

                        "&.Mui-checked:hover": {
                            color: colors.blue800,
                        },

                        "&.Mui-disabled": {
                            color: colors.gray300,
                        },
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectLabel: {
                        color: colors.textIconDark,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    },
                    displayedRows: {
                        color: colors.textIconDark,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    },
                    toolbar: {
                        "&.MuiToolbar-root": {
                            minHeight: "unset",
                        },
                    },
                },
            },
            MuiSkeleton: {
                defaultProps: {
                    variant: "rectangular",
                },
                styleOverrides: {
                    root: {
                        borderRadius: "6px",
                        background: `linear-gradient(
                            90deg, 
                            #DFE6EE 0%, 
                            #F0F4F9 100%
                        )`,
                    },
                },
            },
            // MuiBackdrop: {
            //     styleOverrides: {
            //         root: {
            //             "&.MuiDialog-backdrop": {
            //                 backgroundColor: colors.inkNormal,
            //                 opacity: "0.5 !important",
            //             },
            //         },
            //     },
            // },
        },
    };
};

export default useMuiStyles;
