import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import LanguageContext from "../../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import useDebounce from "../../../../../../../utils/useDebounce";
import InputField from "../../../../../../MuiComponents/InputField";
import useFieldValidation from "../../../../shared/useFieldValidation";
import { TimeInputProps } from "../types";

import useTimeInputFunctions from "./useTimeInputFunctions";

const TimeInput = ({
    data,
    isFirstCell,
    previousCell,
    rowValues,
}: TimeInputProps) => {
    const {
        parameterId,
        parameterValue,
        validate,
        validationMessage,
        textMinLength,
        textMaxLength,
    } = data;

    const {
        colors: { gray100, white, red800 },
    } = useContext(ThemeContext);

    const {
        updateParameters,
        findDisabledParameterById,
        findParameterValueById,
    } = useContext(MenuItemContext);

    const { t } = useContext(LanguageContext);

    const [value, setValue] = useState(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [focusValue, setFocusValue] = useState(parameterValue);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [requestError, setRequestError] = useState(false);

    const hasBeenMounted = useRef<boolean>(false);

    const debouncedUpdateMultipleParams = useDebounce(updateParameters, 500);

    const paramValFromContext = findParameterValueById(parameterId);

    const {
        checkIfValuesAreIdentical,
        checkIfValueIsValid,
        removeErrorIfValidTime,
        formatTimeFromInput,
        getShiftedData,
        handleSortingOnBlur,
    } = useTimeInputFunctions(
        textMinLength,
        textMaxLength,
        parameterId,
        rowValues,
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        const formattedValue = formatTimeFromInput(newValue);

        if (
            formattedValue.length === 5 &&
            formattedValue !== paramValFromContext
        ) {
            handleValueChange(formattedValue);
        }

        if (formattedValue.length <= 5) {
            removeErrorIfValidTime(formattedValue, setError);
            setValue(formattedValue);
        }
    };

    const { validateValue, validationMessageValue } = useFieldValidation(
        value,
        { parameterId, validate, validationMessage },
        paramValFromContext,
        hasBeenMounted,
        handleChange,
    );

    useEffect(() => {
        const prevCellValueFromContext = findParameterValueById(
            previousCell.parameterId,
        );

        if (!prevCellValueFromContext) {
            setError(null);
            setValue("");
            setFocusValue("");
        }
    }, [findParameterValueById(previousCell.parameterId)]);

    useEffect(() => {
        if (paramValFromContext.length > 0) {
            checkIfValueIsValid(
                paramValFromContext,
                validate,
                validationMessage,
                setError,
            );
        }

        setValue(paramValFromContext);

        setPreviousValue(paramValFromContext);
    }, [paramValFromContext, validate]);

    const onFailedRequest = () => {
        setRequestError(true);
        setError(null);
        setValue(previousValue);
    };

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (inputValue.length > 0) {
            const errorValue = checkIfValueIsValid(
                inputValue,
                validateValue,
                validationMessageValue,
                setError,
            );

            if (errorValue) {
                debouncedUpdateMultipleParams.cancel();
                return;
            }
        }

        if (inputValue.length === 0 && previousValue !== "") {
            const shiftedData = getShiftedData(inputValue, handleValueChange);

            return (
                shiftedData.length > 0 &&
                debouncedUpdateMultipleParams(shiftedData)
            );
        }

        const isSameValue = focusValue === inputValue;

        if (inputValue.length === 5 && !isSameValue) {
            const cellValues = rowValues.slice(1, 7);

            const sortedData = handleSortingOnBlur();

            setFocusValue(inputValue);

            const valuesAreIdentical = checkIfValuesAreIdentical(
                cellValues,
                sortedData,
            );

            if (!valuesAreIdentical) {
                return debouncedUpdateMultipleParams(sortedData);
            }
        }
    };

    const handleValueChange = async (newValue: string) => {
        const valueIsEmpty = newValue.length === 0;

        const errorValue = valueIsEmpty
            ? null
            : checkIfValueIsValid(
                  newValue,
                  validateValue,
                  validationMessageValue,
                  setError,
              );

        setValue(newValue);

        if (errorValue) {
            setLoading(false);
            return debouncedUpdateMultipleParams.cancel();
        }

        const payload = [{ id: parameterId, value: newValue }];

        await debouncedUpdateMultipleParams(
            payload,
            () => onSuccessfulRequest(newValue),
            onFailedRequest,
        );

        setLoading(false);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocusValue(e.target.value);
    };

    const isTextBoxEnabled =
        isFirstCell || !findDisabledParameterById(previousCell.parameterId);

    const placeholder = isTextBoxEnabled ? `+ ${t.AddTime}` : "";

    return (
        <InputField
            stacked
            id={String(parameterId)}
            fullWidth
            size="small"
            placeholder={placeholder}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            disabled={!isTextBoxEnabled}
            requesting={loading}
            requestFailed={requestError}
            error={error}
            borderRadius="0"
            otherInputSx={{
                padding: "8px 16px",
                fontSize: "14px",
                backgroundColor: !isTextBoxEnabled ? gray100 : white,

                "& .MuiOutlinedInput-notchedOutline": {
                    border: error ? `1px solid ${red800}` : 0,
                },
            }}
            additionalInputWrapperStyles={{
                "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                        boxShadow: "none",
                    },

                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        boxShadow: "none",
                    },
                },
            }}
        />
    );
};

export default TimeInput;
