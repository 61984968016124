/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { css, jsx } from "@emotion/react";

import {
    ExpandMore as ExpandMoreIcon,
    InfoOutlined,
    Key,
    Lock,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import Popover from "@mui/material/Popover";

import { ReactComponent as ComputerInfo } from "../../../../assets/icons/ComputerInfo.svg";
import InterfaceIcon from "../../../../assets/icons/InterfaceIcon";
import { ReactComponent as SelectFirmwareIcon } from "../../../../assets/icons/SelectFirmware.svg";
import { ReactComponent as TerminalIcon } from "../../../../assets/icons/Terminal.svg";
import { START_UP } from "../../../../constants/routes";
import ConfigurationContext from "../../../../context/configuration/configurationContext";
import LanguageContext from "../../../../context/language/languageContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import ThemeContext from "../../../../context/theme/themeContext";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import useApi from "../../../../utils/useApi";
import useMediaQueries from "../../../../utils/useMediaQueries";
import useUrlQueryParams from "../../../../utils/useUrlQueryParams";
import Button from "../../../MuiComponents/Button";

import ActivateKeyModal from "./ActivateKeyModal";
import GeneralSettingsModal from "./GeneralSettingsModal";
import InterfaceSettingsModal from "./InterfaceSettingsModal";
import LanguageSettingsModal from "./LanguageSettingsModal";
import VersionInfoModal from "./VersionInfoModal";
import SystemInformationModal from "./VersionInfoModal/SystemInformationModal";

const SettingsModal = () => {
    const {
        colors: { gray100, textDark },
    } = useContext(ThemeContext);

    const {
        anchorEl,
        isSettingsModalOpen,
        closeSettingsModal,
        openGeneralSettingsModal,
        openInterfaceSettingsModal,
        openLanguageSettingsModal,
        openVersionInfoModal,
        openPrivacyPolicyModal,
        openActivateKeyModal,
        openTerminal,
        openSystemInformationModal,
    } = useContext(SettingsContext);

    const { selectedLanguage, languages, t } = useContext(LanguageContext);
    const { isDesktopApplication, enableTerminal } =
        useContext(UserInfoContext);
    const { experimentalConfigurations, setIsExperimentalModalOpen } =
        useContext(ConfigurationContext);

    const { toMd } = useMediaQueries();

    const { pathname } = useUrlQueryParams();

    const selectedLanguageObj = languages.find(
        (item: any) => item.shortName === selectedLanguage,
    );

    const selectedLanguageDescription = selectedLanguageObj
        ? selectedLanguageObj.description
        : selectedLanguage;

    const onStartupRoute = pathname === START_UP;

    const { getData } = useApi();

    const onOpenPrivacyPolicy = () => {
        openPrivacyPolicyModal();
        if (isDesktopApplication) {
            getData(`${selectedLanguage}/desktopHost/openprivacypolicywindow`);
        } else {
            window.open(
                "https://teltonika-iot-group.com/about-us/policies-certificates/privacy-policy",
                "_blank",
                "noopener",
            );
        }
    };

    const settingsItems: any[] = [
        {
            icon: <SettingsIcon />,
            label: t.ConfigurationSettings,
            onClick: () => openGeneralSettingsModal(),
            isHidden: !isDesktopApplication,
        },
        {
            icon: <InterfaceIcon />,
            label: t.InterfaceSettings,
            onClick: () => openInterfaceSettingsModal(),
            isHidden: onStartupRoute,
        },
        {
            icon: <LanguageIcon />,
            label: selectedLanguageDescription,
            onClick: () => openLanguageSettingsModal(),
            isHidden: false,
        },
        !isDesktopApplication && {
            label: t.SystemInformation,
            icon: <ComputerInfo />,
            onClick: () => openSystemInformationModal(),
        },
        {
            icon: <InfoOutlined />,
            label: t.VersionInfo,
            onClick: () => openVersionInfoModal(),
            isHidden: !isDesktopApplication,
        },
        {
            icon: <Lock />,
            label: t.PrivacyPolicy,
            onClick: onOpenPrivacyPolicy,
            isHidden: false,
        },
        enableTerminal && {
            icon: <TerminalIcon />,
            label: t.Terminal,
            onClick: () => openTerminal(),
            isHidden: !isDesktopApplication,
        },
        {
            icon: <Key />,
            label: t.UserKeys,
            onClick: () => openActivateKeyModal(),
            isHidden: !isDesktopApplication,
        },
        {
            icon: <SelectFirmwareIcon />,
            label: t.SelectFirmware,
            onClick: () => setIsExperimentalModalOpen(true, true),
            isHidden: !experimentalConfigurations?.length || !onStartupRoute,
        },
    ].filter(Boolean);

    return (
        <Fragment>
            <Popover
                open={isSettingsModalOpen}
                anchorEl={anchorEl}
                onClose={closeSettingsModal}
                anchorReference={toMd ? "anchorPosition" : "anchorEl"}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                marginThreshold={toMd ? 0 : 16}
                sx={{
                    "& .MuiPaper-root": {
                        maxHeight: toMd ? "unset" : "626px",
                        height: toMd ? "100%" : "unset",
                        maxWidth: toMd ? "unset" : "448px",
                        width: toMd ? "100%" : "calc(100% - 284px)",
                        marginTop: toMd ? "0" : "10px",
                        overflowY: toMd ? "auto" : "hidden",
                    },
                }}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px 30px 16px 24px",

                        "& > h2": {
                            color: textDark,
                            fontSize: "20px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            alignSelf: "flex-end",
                        },
                    })}
                >
                    <h2>{t.Settings}</h2>

                    <Button
                        variant="iconOnly"
                        size="small"
                        color="white"
                        icon={<CloseIcon onClick={closeSettingsModal} />}
                        idForTesting="closeSettingsModal"
                    />
                </div>
                <div>
                    {settingsItems.map((item, index) => {
                        if (item.isHidden) {
                            return null;
                        }
                        return (
                            <div
                                css={css({
                                    display: "flex",
                                    padding: "14px 34px 14px 26px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    ":hover": {
                                        backgroundColor: gray100,
                                        cursor: "pointer",
                                    },
                                })}
                                onClick={item.onClick}
                                key={index}
                                data-test={"settingsItem" + index}
                            >
                                <div
                                    css={css({
                                        display: "flex",
                                        alignItems: "center",
                                    })}
                                >
                                    {item.icon}
                                    <div css={css({ marginLeft: "12px" })}>
                                        {item.label}
                                    </div>
                                </div>
                                <div
                                    css={css({
                                        transform: "rotate(-90deg)",
                                    })}
                                >
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Popover>
            {isDesktopApplication && <GeneralSettingsModal />}
            {!onStartupRoute && <InterfaceSettingsModal />}
            <LanguageSettingsModal />
            {isDesktopApplication && <ActivateKeyModal />}
            {isDesktopApplication && <VersionInfoModal />}
            {!isDesktopApplication && <ActivateKeyModal />}
            {!isDesktopApplication && <SystemInformationModal />}
        </Fragment>
    );
};

export default SettingsModal;
