import { useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import useApi from "../../../../../utils/useApi";
import DeviceAuthorizationContext from "../../../../../context/deviceAuthorization/deviceAuthorizationContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import { useHistory } from "react-router-dom";
import { START_UP } from "../../../../../constants/routes";

const useEnterPasswordModal = (shouldRedirectToStartupOnCancel: boolean, isEditorRoute: boolean) => {

    const { selectedLanguage } = useContext(LanguageContext);
    const { setDeviceConfigureButtonPortName } = useContext(LayoutContext);

    const {
        setPasswordModalOpen,
        port,
        openExistingUnlockDeviceModal,
    } = useContext(DeviceAuthorizationContext);
    const { disconnectFromDevice } = useApi();
    const history = useHistory();

    const handleClose = async () => {
        await disconnectFromDevice(selectedLanguage, true);
        setDeviceConfigureButtonPortName(port);
        setPasswordModalOpen(false);
        shouldRedirectToStartupOnCancel && history.replace(START_UP);
    };

    const onCloseNewPassword = async () => {
        await disconnectFromDevice(selectedLanguage, true);
        setDeviceConfigureButtonPortName(port);
        shouldRedirectToStartupOnCancel && history.replace(START_UP);
    };


    const handleDataPostUnauthorizedErrorWhenRequiresUnlock = async (authorizationInfo: any) => {

        if (isEditorRoute) {
            history.replace(START_UP);
        } else {
            openExistingUnlockDeviceModal(
                authorizationInfo.unlockCode
            );
        }
        await handleClose();
    };

    return {
        handleClose,
        onCloseNewPassword,
        handleDataPostUnauthorizedErrorWhenRequiresUnlock,
    };
};


export default useEnterPasswordModal;