/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import ThemeContext from "../../../context/theme/themeContext";
import InputField from "../InputField";

export interface OneWireInputProps {
    data: {
        id: string;
        ref: any;
        blockLabel: string;
        iconRight: any;
        placeholder: string;
        value: string;
        disabled: boolean;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        error: string;
    };
}

const OneWireInput: React.FunctionComponent<OneWireInputProps> = ({ data }) => {
    const {
        id,
        ref,
        blockLabel,
        iconRight,
        placeholder,
        value,
        disabled,
        onChange,
        error,
    } = data;

    const {
        colors: { gray50, gray300 },
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                padding: "16px",
                backgroundColor: gray50,
                border: `1px solid ${gray300}`,
                borderRadius: "8px",
            })}
        >
            <div
                css={css({
                    marginBottom: "16px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                })}
            >
                {blockLabel}
            </div>
            <InputField
                id={id}
                ref={ref}
                fullWidth
                size="medium"
                label={"Add manually"}
                iconRight={iconRight}
                placeholder={placeholder}
                type={"text"}
                value={value}
                disabled={disabled}
                onChange={onChange}
                error={error}
            />
        </div>
    );
};

export default OneWireInput;
