/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from "@emotion/react";

import Select from "../../../../../../MuiComponents/Select";

export interface FilterSelectProps {
    onChange: (value: any) => void;
    value: any;
    name: string;
    isDisabled?: boolean;
}

const FilterSelect = ({
    onChange,
    value,
    name,
    isDisabled,
}: FilterSelectProps) => {
    return (
        <Select
            multiple
            value={value}
            onChange={onChange}
            placeholder={name}
            disabled={isDisabled}
        >
            <div></div>
        </Select>
    );
};

export default FilterSelect;
