/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import { SelectChangeEvent } from "@mui/material";

import { CollectionsWithRefetchBluetoothTableName } from "../../../../constants/constants";
import BluetoothViewContext from "../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import ManualGeofenceContext from "../../../../context/manualGeofence/manualGeofenceContext";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import { Component, SearchPath } from "../../../../generatedTypes";
import { getIdTooltipText } from "../../../../utils/helpers";
import useDebounce from "../../../../utils/useDebounce";
import useNavigation from "../../../../utils/useNavigation";
import Select from "../../Select";
import Tag from "../../Tag";
import Tooltip from "../../Tooltip";
import TooltipLarge from "../../TooltipLarge";

import CollectionMenuItem from "./CollectionMenuItem";

export interface CollectionProps {
    data: Partial<Component>;
    onChange?: (event: { id: number; value: string }) => void;
    hasNoDependencies?: boolean;
    withoutLabelAndId?: boolean;
    defaultValue?: string | number;
    componentValue: string;
}

const Collection = ({
    data,
    hasNoDependencies,
    withoutLabelAndId,
    defaultValue,
    onChange,
    componentValue,
}: CollectionProps) => {
    const {
        label,
        parameterValue,
        collectionConfig,
        tooltip = "",
        disabledTooltip,
        sourceBindToList,
        elementType,
        condition,
    } = data;

    const parameterId = data?.parameterId ?? 0;
    const avlId = data?.avlId ?? 0;
    const isDisabled = data?.isDisabled || false;

    const { findBindedList, listValues, setDrawerParameterId } =
        useContext(LayoutContext);
    const {
        updateParameter,
        findDisabledCollectionItemsById,
        findDisabledParameterById,
        setDisabledParameters,
    } = useContext(MenuItemContext);

    const { shapeParameter, setAvailableShapes, setShapeParameter } =
        useContext(ManualGeofenceContext);

    const { t } = useContext(LanguageContext);

    const { isParameterIdsHidden } = useContext(SettingsContext);

    const { handleSearchNavigation } = useNavigation();

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const [value, setValue] = useState(componentValue);
    const [previousValue, setPreviousValue] = useState(componentValue);

    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);

    const elementRef = useRef(null);
    const firstRender = useRef(true);

    const hasListValues = listValues?.[sourceBindToList ?? ""];

    const [listItems, setListItems] = useState(
        hasListValues
            ? [
                  { value: t.NotSet, index: 0 },
                  ...listValues[sourceBindToList ?? ""],
              ]
            : undefined,
    );

    const isCollectionDisabled = (): boolean => {
        if (findDisabledParameterById(parameterId) !== undefined) {
            return findDisabledParameterById(parameterId);
        }
        if (findBindedList(sourceBindToList) && !listItems?.length) {
            return true;
        }
        return isDisabled;
    };

    const hasAnEmptyValue = (value: string) =>
        collectionConfig?.collectionItems.some(
            (item) => item.isInvisible && item.value === value,
        )
            ? ""
            : value;

    useEffect(() => {
        /* For performance reasons, we are skipping the first render, as it is not necessary 
         to update the listItems state, only update when list values changes */

        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (listValues[sourceBindToList || ""]) {
            setListItems([
                { value: t.NotSet, index: 0 },
                ...listValues[sourceBindToList ?? ""],
            ]);
        }
        // eslint-disable-next-line
    }, [listValues[sourceBindToList ?? ""]]);

    useEffect(() => {
        if (!hasNoDependencies) {
            setValue(hasAnEmptyValue(componentValue));
            setPreviousValue(hasAnEmptyValue(componentValue));
        }
        // eslint-disable-next-line
    }, [componentValue]);

    useEffect(() => {
        if (elementType === "GeoShapeType") {
            setShapeParameter(Number(parameterValue));
        }
    }, []);

    useEffect(() => {
        if (elementType === "GeoShapeType") {
            //set available shapes for map. Pro devices has polygons, rectangle and circle
            setAvailableShapes(
                data.collectionConfig?.collectionItems.map(
                    (item) => Number(item.value) + 1,
                ),
            );
            debouncedChangeHandler(
                parameterId,
                Number(shapeParameter),
                label,
                elementRef,
            );
        }

        return () => {
            setAvailableShapes([]);
        };
    }, [shapeParameter]);

    const dependenciesToDisable =
        data.dependentParameterIds &&
        data.dependentParameterIds.length > 0 &&
        data.dependentParameterIds.map((dependant: number) => {
            return {
                parameterId: dependant,
                isDisabled: true,
            };
        });
    const disableDependencies = () => {
        dependenciesToDisable && setDisabledParameters(dependenciesToDisable);
    };

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const handleChange = (e: SelectChangeEvent<any>) => {
        const newValue = e.target.value;
        setValue(newValue);

        if (onChange) {
            onChange({ id: parameterId, value: newValue });
        } else {
            setLoading(true);
            disableDependencies();
            debouncedChangeHandler(
                parameterId,
                newValue,
                label,
                elementRef,
                () => onSuccessfulRequest(newValue),
                onFailedRequest,
                () => setLoading(false),
                true,
                onCanceledRequest,
            );
        }
    };

    if (condition === "Hidden") {
        return null;
    }

    return (
        <TooltipLarge
            title={
                isCollectionDisabled() && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            buttonText={
                disabledTooltip?.btnTitle ? disabledTooltip.btnTitle : ""
            }
            onBtnClick={() =>
                handleSearchNavigation(
                    disabledTooltip as { searchPath: SearchPath },
                )
            }
        >
            <span>
                <Select
                    id={String(parameterId)}
                    ref={elementRef}
                    value={defaultValue ?? value}
                    label={withoutLabelAndId ? "" : label}
                    onInfoIconClick={() => setDrawerParameterId(parameterId)}
                    iconRight={
                        parameterId &&
                        !withoutLabelAndId &&
                        !isParameterIdsHidden ? (
                            <Tooltip
                                title={getIdTooltipText(parameterId, avlId)}
                                small
                                placement="top"
                            >
                                <span>
                                    <Tag size="tiny" color="white" title="ID" />
                                </span>
                            </Tooltip>
                        ) : null
                    }
                    requesting={loading}
                    requestFailed={requestError}
                    info={tooltip}
                    disabled={isCollectionDisabled()}
                    renderValue={(val) => {
                        if (listItems?.length) {
                            return listItems?.find(
                                (item) => item.index === Number(val),
                            )?.value;
                        } else {
                            return collectionConfig?.collectionItems.find(
                                (collectionItem) =>
                                    collectionItem.value === val,
                            )?.text;
                        }
                    }}
                    onChange={handleChange}
                    wrapperStyle={css({
                        scrollMargin: "16px",

                        "& .MuiInputBase-input > span": {
                            padding: "0 !important",
                        },
                    })}
                    medium
                >
                    {listItems?.map((item) => {
                        if (item.value) {
                            return (
                                <CollectionMenuItem
                                    key={item.index}
                                    value={item.index}
                                    disabled={false}
                                >
                                    {item.value}
                                </CollectionMenuItem>
                            );
                        }
                    })}
                    {collectionConfig?.collectionItems.map((item) => {
                        const hasDisabledItems =
                            findDisabledCollectionItemsById(parameterId);

                        const isMenuItemDisabled =
                            hasDisabledItems?.[item.value];

                        const itemDisabledTooltip = item.disabledTooltip;
                        if (item.isInvisible) {
                            return null;
                        }
                        return (
                            <CollectionMenuItem
                                key={item.value}
                                value={item.value}
                                disabled={isMenuItemDisabled}
                                tooltipTitle={
                                    isMenuItemDisabled && itemDisabledTooltip
                                        ? itemDisabledTooltip.title
                                        : ""
                                }
                                tooltipButtonText={
                                    itemDisabledTooltip
                                        ? itemDisabledTooltip.btnTitle
                                        : ""
                                }
                                tooltipOnBtnClick={() =>
                                    handleSearchNavigation(
                                        itemDisabledTooltip as {
                                            searchPath: SearchPath;
                                        },
                                    )
                                }
                            >
                                {item.text}
                            </CollectionMenuItem>
                        );
                    })}
                </Select>
            </span>
        </TooltipLarge>
    );
};

export default Collection;
