/** @jsxRuntime classic */

/** @jsx jsx */
import { RefObject, useContext, useEffect } from "react";

import { css, jsx } from "@emotion/react";

import { ulid } from "ulid";

import {
    PortMessageType,
    terminalMessageLength,
} from "../../../constants/constants";
import ThemeContext from "../../../context/theme/themeContext";
import { TerminalPortType } from "../../../utils/types";

interface ActivePortMessagesProps {
    activeTab: any;
    filteredPortMessages: any[];
    terminalMessageRef: RefObject<HTMLDivElement>;
    messagesEndRef: RefObject<HTMLDivElement>;
    handleScrollToBottom: (messagesEnd: HTMLDivElement) => void;
}

const ActivePortMessages = ({
    activeTab,
    filteredPortMessages,
    terminalMessageRef,
    messagesEndRef,
    handleScrollToBottom,
}: ActivePortMessagesProps) => {
    const {
        colors: { gray100, textPlaceholder, textDark },
    } = useContext(ThemeContext);

    const activePortMessages = [...filteredPortMessages].slice(
        -terminalMessageLength,
    );

    const isDevicePort = activeTab?.port?.type === TerminalPortType.Device;

    useEffect(() => {
        const terminalMessages = terminalMessageRef.current;
        const messagesEnd = messagesEndRef.current;
        if (terminalMessages && messagesEnd) {
            const isAtBottom =
                terminalMessages.scrollTop + terminalMessages.clientHeight >=
                terminalMessages.scrollHeight - 300;

            if (isAtBottom && !isDevicePort) {
                handleScrollToBottom(messagesEnd);
            }
        }
    }, [activePortMessages]);

    const checkIfCommandMessageType = (msgType: PortMessageType) =>
        msgType == PortMessageType.Command;

    return (
        <div
            css={css({
                overflow: "auto",
                flex: 1,
                marginTop: "12px",
                "&::-webkit-scrollbar-thumb": {
                    height: "50px",
                    border: `5px solid ${gray100}`,
                },
                minHeight: 0,
            })}
            ref={terminalMessageRef}
        >
            {activePortMessages.map((item: any) => {
                return (
                    <div
                        id="animError"
                        css={css({
                            display: "flex",
                            gap: "5px",
                            marginBottom: "5px",
                            fontFamily: !checkIfCommandMessageType(item.type)
                                ? "Consolas, monospace"
                                : "unset",
                            whiteSpace: "break-spaces",
                        })}
                        key={isDevicePort ? `${item?.msgId}` : ulid()}
                    >
                        {!isDevicePort && (
                            <div
                                css={css({
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                    color: textDark,
                                    minWidth: "max-content",
                                })}
                            >
                                {item.tctTime}:
                            </div>
                        )}
                        <div
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                color: checkIfCommandMessageType(item.type)
                                    ? textPlaceholder
                                    : textDark,
                                fontStyle: checkIfCommandMessageType(item.type)
                                    ? "italic"
                                    : "normal",
                            })}
                        >
                            {item.message}
                        </div>
                    </div>
                );
            })}

            <div css={css({ height: "20px" })} ref={messagesEndRef} />
        </div>
    );
};

export default ActivePortMessages;
