/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useReducer } from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import AlertContext from "../../../../../../../context/alert/alertContext";
import useApi from "../../../../../../../utils/useApi";
import enableDisablePinReducer from "../context/pinReducer";
import DeviceLocked from "../DeviceLocked";
import CodeInput from "../CodeInput";
import Button from "../../../../../../MuiComponents/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import {
    HANDLE_CURRENT_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "../context/pinActions";
import { SimPinModalTypes } from "../index";
import { AlertStatus } from "../../../../../../../constants/constants";
import { chooseValue } from "../../../../../../../utils/helpers";

interface EnableDisablePinProps {
    simId: number;
    close: () => void;
    type: SimPinModalTypes;
}

const EnableDisablePin = ({ simId, close, type }: EnableDisablePinProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData } = useApi();

    const initialState = {
        isLoading: false,
        isDisabled: true,
        currentPin: null,
        errorMsg: null,
        retriesLeft: null,
    };

    const [state, dispatch] = useReducer(enableDisablePinReducer, initialState);

    const { isLoading, isDisabled, currentPin, errorMsg, retriesLeft } = state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handlePayload = (value: string | null) =>
        dispatch({ type: HANDLE_CURRENT_PIN, payload: value });

    const sendPin = async () => {
        try {
            setLoading(true);

            const resource = chooseValue(type === SimPinModalTypes.EnablePin, "enablesimpin", "disablesimpin");

            const { data } = await postData(
                `${selectedLanguage}/command/${resource}`,
                {
                    simId: simId,
                    pin: currentPin,
                }
            );

            if (data.success) {
                const message = chooseValue(type === SimPinModalTypes.EnablePin, t.PinSuccessfullyEnabled, t.PinSuccessfullyDisabled);

                setAlert(AlertStatus.Success, message);
                close();
            } else {
                dispatch({
                    type: WRONG_CURRENT_PIN,
                    payload: data.retriesLeft,
                });
            }
        } catch (error) {
            const message = chooseValue(type === SimPinModalTypes.EnablePin, t.ErrorOccurredEnablingPin, t.ErrorOccurredDisablingPin);

            setAlert(AlertStatus.Critical, message);
            setLoading(false);
        }
    };

    const renderTitle = () => chooseValue(type === SimPinModalTypes.EnablePin, t.EnablePinCode, t.DisablePinCode);

    const renderButtonText = () => {
        const buttonTitle = chooseValue(type === SimPinModalTypes.EnablePin, t.Enable, t.Disable);

        return isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            buttonTitle
        );
    };

    const deviceLocked = () => retriesLeft && retriesLeft + 1 <= 1;

    return deviceLocked() ? (
        <DeviceLocked type={type} close={close} />
    ) : (
        <Fragment>
            <DialogTitle>{renderTitle()}</DialogTitle>

            <DialogContent>
                <CodeInput
                    error={errorMsg}
                    retriesLeft={retriesLeft}
                    explanatoryText={t.EnterPinCode}
                    step={1}
                    setPayloadValue={handlePayload}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    "&.MuiDialogActions-root>:not(:first-of-type)": {
                        marginLeft: "12px",
                    },
                }}
            >
                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    idForTesting="cancelButton"
                >
                    {t.Cancel}
                </Button>

                <Button
                    variant="textOnly"
                    size="normal"
                    color="primary"
                    fullWidth
                    disabled={isDisabled}
                    onClick={sendPin}
                    css={css({
                        pointerEvents: chooseValue(isLoading, "none", "auto"),
                    })}
                    idForTesting="submitButton"
                >
                    {renderButtonText()}
                </Button>
            </DialogActions>
        </Fragment>
    );
};

export default EnableDisablePin;
