/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { SerializedStyles, css, jsx } from "@emotion/react";

import ThemeContext from "../../../context/theme/themeContext";
import Box from "../../Box";
import Divider from "../Divider";

export interface CardProps {
    children: React.ReactNode;
    title?: string;
    description?: string;
    action?: React.ReactNode;
    divider?: boolean;
    prefix?: string;
    isSection?: boolean;
    boxCSS?: SerializedStyles;
}
const Card = ({
    children,
    title,
    description,
    action,
    divider,
    prefix,
    isSection,
    boxCSS,
}: CardProps) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    return (
        <Box css={boxCSS}>
            <div
                css={css({
                    padding: "24px 24px 0 24px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "spaceBetween",
                        alignItems: "center",

                        h4: {
                            display: "flex",
                            alignItems: "center",
                            color: textDark,
                            fontSize: isSection ? "16px" : "20px",
                            fontWeight: 600,
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                        },
                    })}
                >
                    {title && (
                        <h4>
                            {prefix && (
                                <span
                                    css={css({
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "8px",
                                    })}
                                >
                                    {prefix}
                                </span>
                            )}

                            {title}
                        </h4>
                    )}

                    {action ? action : ""}
                </div>

                {description && (
                    <p
                        css={css({
                            color: textDark,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            marginTop: "12px",
                            marginLeft: prefix ? "32px" : "0",
                        })}
                    >
                        {description}
                    </p>
                )}
            </div>

            {divider && (
                <Divider
                    additionalCss={{
                        marginTop: "16px",
                    }}
                    hasLighterGray
                />
            )}

            <div
                css={css({
                    padding: "16px 24px 24px",
                })}
            >
                {children}
            </div>
        </Box>
    );
};

export default Card;
