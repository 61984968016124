/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect, useState } from "react";

import { css, jsx } from "@emotion/react";

import LayoutContext from "../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import UserInfoContext from "../../../../../context/userInfo/userInfoContext";
import { ParamFail } from "../../../../../generatedTypes";
import {
    ConfigurationPasswordState,
    Problem,
} from "../../../../../utils/types";
import useApi from "../../../../../utils/useApi";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import useTct from "../../../../../utils/useTct";
import FailedParamModal, { ProblemWithParams } from "../FailedParamModal";

import MobileSaveButton from "./MobileSaveButton";
import SaveToDevice from "./SaveToDevice";
import SaveToFotaFile from "./SaveToFotaFile";
import SaveToLocalFile, { SaveToFileProps } from "./SaveToLocalFile";

const SaveButtons = () => {
    const { isDesktopApplication } = useContext(UserInfoContext);
    const { layoutData } = useContext(LayoutContext);
    const { hasParameterErrors, errors } = useContext(MenuItemContext);

    const { toSm } = useMediaQueries();
    const { showErrorMsg } = useApi();
    const { getModifiedParameters } = useTct();

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    const [savingToDevice, setSavingToDevice] = useState(false);
    const [savingToFile, setSavingToFile] = useState(false);

    const [isSaveToFileModalOpen, setSaveToFileModalOpen] = useState(false);
    const [isSaveToDeviceModalOpen, setSaveToDeviceModalOpen] = useState(false);
    const [isMobileSaveModalOpen, setMobileSaveModalOpen] = useState(false);

    const [problem, setProblem] = useState<ProblemWithParams | null>(null);
    const [isFailedParamModalOpen, setFailedParamModal] = useState(false);

    const [modifiedParameters, setModifiedParameters] = useState({
        parameters: [],
        recoveryConfigurationPasswordState: ConfigurationPasswordState.Same,
        configurationPasswordState: ConfigurationPasswordState.Same,
    });

    useEffect(() => {
        if (isSaveToFileModalOpen || isSaveToDeviceModalOpen)
            getModifiedParameters(Number(layoutData?.id)).then((data) =>
                setModifiedParameters(data),
            );

        // eslint-disable-next-line
    }, [isSaveToFileModalOpen, isSaveToDeviceModalOpen]);

    useEffect(() => {
        setIsSaveButtonDisabled(hasParameterErrors());
        // eslint-disable-next-line
    }, [errors]);

    const showParamErrorsIfAny = (error: any) => {
        const problem =
            error && error.response && (error.response.data as Problem);
        if (problem && problem.detail && problem.detail.indexOf("[") === 0) {
            try {
                const paramFails = JSON.parse(problem.detail) as ParamFail[];
                if (paramFails?.length) {
                    const ex = {
                        message: problem.title,
                        parameters: paramFails,
                    } as ProblemWithParams;
                    setProblem(ex);
                    setFailedParamModal(true);
                }
            } catch {}
        } else {
            showErrorMsg(error);
        }
    };

    const onFailedParameterDialogClose = () => {
        setFailedParamModal(false);
        setProblem(null as any);

        setSaveToDeviceModalOpen(false);
        setSaveToFileModalOpen(false);
        setMobileSaveModalOpen(false);
        setSavingToDevice(false);
    };

    const renderSaveToFileBtn = () => {
        const saveToFileProps = {
            isMobileSaveModalOpen,
            setMobileSaveModal: setMobileSaveModalOpen,
            isDisabled: isSaveButtonDisabled,
            isModalOpen: isSaveToFileModalOpen,
            setModalOpen: setSaveToFileModalOpen,
            modifiedParameterResponse: modifiedParameters,
            isSaving: savingToFile,
            setIsSaving: setSavingToFile,
        } as SaveToFileProps;

        return isDesktopApplication ? (
            <SaveToLocalFile {...saveToFileProps} />
        ) : (
            <SaveToFotaFile {...saveToFileProps} />
        );
    };

    const renderSaveButtons = () => {
        if (!layoutData?.isOnline) {
            return renderSaveToFileBtn();
        }

        return (
            <Fragment>
                <div
                    css={css(
                        toSm
                            ? { display: "none" }
                            : {
                                  display: "flex",
                                  gap: "8px",
                              },
                    )}
                >
                    {renderSaveToFileBtn()}
                    <SaveToDevice
                        isMobileSaveModalOpen={isMobileSaveModalOpen}
                        setMobileSaveModal={setMobileSaveModalOpen}
                        isDisabled={isSaveButtonDisabled}
                        isModalOpen={isSaveToDeviceModalOpen}
                        setModalOpen={setSaveToDeviceModalOpen}
                        modifiedParameterResponse={modifiedParameters}
                        isSaving={savingToDevice}
                        setIsSaving={setSavingToDevice}
                        renderParameterErrors={showParamErrorsIfAny}
                    />
                </div>
                {toSm && (
                    <MobileSaveButton
                        isDisabled={isSaveButtonDisabled}
                        isSaving={savingToFile || savingToDevice}
                        isModalOpen={isMobileSaveModalOpen}
                        setModalOpen={setMobileSaveModalOpen}
                        openSaveToDeviceModal={() =>
                            setSaveToDeviceModalOpen(true)
                        }
                        openSaveToFileModal={() => setSaveToFileModalOpen(true)}
                    />
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            {renderSaveButtons()}
            <FailedParamModal
                isOpen={isFailedParamModalOpen}
                modifiedParameters={modifiedParameters}
                problem={problem}
                onFailedParameterDialogClose={onFailedParameterDialogClose}
            />
        </Fragment>
    );
};

export default SaveButtons;
