import React, { useMemo, useReducer } from "react";

import {
    RESET_BLUETOOTH_VIEW,
    SET_ADD_DEVICE_MANUALLY_MODAL,
    SET_ADD_DEVICE_MODAL,
    SET_BLUETOOTH_DEVICES,
    SET_BLUETOOTH_SCENARIO_MODAL,
    SET_TABLE_SEARCH_VALUE,
} from "./bluetoothViewActions";
import BluetoothViewContext from "./bluetoothViewContext";
import BluetoothViewReducer from "./bluetoothViewReducer";

interface BluetoothViewStateProps {
    children: React.ReactNode;
}

const BluetoothViewState = ({ children }: BluetoothViewStateProps) => {
    const initialState = {
        searchValue: "",
        isAddDeviceModalOpen: false,
        isEditDeviceModalOpen: false,
        bluetoothTotalCount: 0,
        isAddDeviceManuallyModalOpen: false,
        bluetoothScenarioModalInfo: {
            isOpen: false,
            data: null,
            isEdit: false,
        },
        bluetoothDevices: null,
    };

    const [state, dispatch] = useReducer(BluetoothViewReducer, initialState);

    const resetBluetoothView = () => {
        dispatch({
            type: RESET_BLUETOOTH_VIEW,
        });
    };

    const setTableSearchValue = (data: any) => {
        dispatch({
            type: SET_TABLE_SEARCH_VALUE,
            payload: data,
        });
    };

    const setAddDeviceModalOpen = (data: boolean) => {
        dispatch({
            type: SET_ADD_DEVICE_MODAL,
            payload: data,
        });
    };

    const setBluetoothScenarioModalInfo = (data: {
        isOpen: boolean;
        data?: any;
    }) => {
        dispatch({
            type: SET_BLUETOOTH_SCENARIO_MODAL,
            payload: data,
        });
    };

    const setAddDeviceManuallyModalOpen = (data: boolean) => {
        dispatch({
            type: SET_ADD_DEVICE_MANUALLY_MODAL,
            payload: data,
        });
    };

    const setBluetoothDevices = (data: any) => {
        dispatch({
            type: SET_BLUETOOTH_DEVICES,
            payload: data,
        });
    };

    const contextValue = useMemo(() => {
        return {
            searchValue: state.searchValue,
            isAddDeviceModalOpen: state.isAddDeviceModalOpen,
            isEditDeviceModalOpen: state.isEditDeviceModalOpen,
            bluetoothTotalCount: state.bluetoothTotalCount,
            isAddDeviceManuallyModalOpen: state.isAddDeviceManuallyModalOpen,
            bluetoothScenarioModalInfo: state.bluetoothScenarioModalInfo,
            bluetoothDevices: state.bluetoothDevices,
            setTableSearchValue,
            setAddDeviceModalOpen,
            resetBluetoothView,
            setAddDeviceManuallyModalOpen,
            setBluetoothScenarioModalInfo,
            setBluetoothDevices,
        };
    }, [state]);

    return (
        <BluetoothViewContext.Provider value={contextValue}>
            {children}
        </BluetoothViewContext.Provider>
    );
};

export default BluetoothViewState;
