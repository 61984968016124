import { useContext } from "react";

import moment from "moment";

import { TIME_FORMATS } from "../../../../../../../constants/constants";
import LanguageContext from "../../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { Component } from "../../../../../../../generatedTypes";
import { validateTextLengthAndRegex } from "../../../../../../../utils/validations";
import { UpdatePayloadObjProps } from "../types";

const useTimeInputFunctions = (
    textMinLength: number,
    textMaxLength: number,
    parameterId: number,
    rowValues: any[],
) => {
    const { t } = useContext(LanguageContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const checkIfValueIsValid = (
        paramValue: string,
        validateVal: string | undefined,
        validationMessageVal: string | undefined,
        setError: (value: React.SetStateAction<string | null>) => void,
    ) => {
        const errorValue = validateTextLengthAndRegex(
            paramValue,
            textMinLength,
            textMaxLength,
            validateVal ?? "",
            validationMessageVal ?? "",
            t,
        );

        setError(errorValue);

        return errorValue;
    };

    const formatTimeFromInput = (value: string) => {
        const valueWithNumbersOnly = value.replace(/\D/g, "");

        return valueWithNumbersOnly.length > 2
            ? `${valueWithNumbersOnly.slice(0, 2)}:${valueWithNumbersOnly.slice(2)}`
            : valueWithNumbersOnly;
    };

    const findCurrentColumnIndex = () =>
        rowValues.find((item) => item.parameterId === parameterId).colIndex;

    const shiftValuesToPreviousId = (items: UpdatePayloadObjProps[]) => {
        const shiftedItems = items.map((item) => ({ ...item }));

        for (let i = 0; i < shiftedItems.length - 1; i++) {
            shiftedItems[i].value = shiftedItems[i + 1].value;
        }

        shiftedItems[shiftedItems.length - 1].value = "";

        return shiftedItems;
    };

    const getSortedData = (currentValues: UpdatePayloadObjProps[]) => {
        const checkIfPreviousCellTimeIsEarlier = (
            previousTime: string,
            currentTime: string,
        ) =>
            moment(previousTime, TIME_FORMATS.HOURS_MINUTES).isBefore(
                moment(currentTime, TIME_FORMATS.HOURS_MINUTES),
            );

        const sortedValues = currentValues
            .map((e) => e.value)
            .filter(Boolean)
            .sort((a, b) => (checkIfPreviousCellTimeIsEarlier(a, b) ? -1 : 1));

        return currentValues.map((entry, i) => ({
            ...entry,
            value: sortedValues[i] ?? "",
        }));
    };

    const getShiftedData = (
        formattedValue: string,
        handleValueChange: (newValue: string) => void,
    ) => {
        const currentColIndex = findCurrentColumnIndex();

        const getNextValues = () => {
            return rowValues.slice(currentColIndex + 1, 7).map((cell) => {
                return {
                    id: cell.parameterId,
                    value: findParameterValueById(cell.parameterId),
                };
            });
        };

        const nextCellIsEmpty =
            rowValues[currentColIndex + 1]?.parameterValue === "" ||
            currentColIndex === 6;

        if (nextCellIsEmpty) {
            handleValueChange(formattedValue);
            return [];
        }

        const allNextValues = getNextValues();

        const updatePayload = [
            { id: parameterId, value: "" },
            ...allNextValues,
        ];

        const shiftedValues = shiftValuesToPreviousId(updatePayload);

        const finalArr = getSortedData(shiftedValues);

        return finalArr;
    };

    const handleSortingOnBlur = () => {
        const getAllCellValues = () => {
            return rowValues.slice(1, 7).map((cell) => {
                return {
                    id: cell.parameterId,
                    value: findParameterValueById(cell.parameterId),
                };
            });
        };

        const allCellValues = getAllCellValues();
        const sortedArr = getSortedData(allCellValues);

        return sortedArr;
    };

    const checkIfValuesAreIdentical = (
        cellValues: Component[],
        sortedData: UpdatePayloadObjProps[],
    ): boolean => {
        return cellValues.every((item1) => {
            const matchingItem = sortedData.find(
                (item2) => item2.id === item1.parameterId,
            );
            return matchingItem?.value === item1.parameterValue;
        });
    };

    const removeErrorIfValidTime = (
        timeValue: string,
        setError: (value: React.SetStateAction<string | null>) => void,
    ) => {
        const isValidTimeString = moment(
            timeValue,
            TIME_FORMATS.HOURS_MINUTES,
            true,
        ).isValid();

        if (timeValue === "" || isValidTimeString) {
            setError(null);
        }
    };

    return {
        checkIfValuesAreIdentical,
        checkIfValueIsValid,
        removeErrorIfValidTime,
        formatTimeFromInput,
        getShiftedData,
        handleSortingOnBlur,
    };
};

export default useTimeInputFunctions;
