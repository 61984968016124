import React, { useContext, useEffect, useState } from "react";

import BluetoothViewContext from "../../../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import {
    BleDeviceType,
    BleDeviceTypeEntry,
} from "../../../../../../generatedTypes";
import useTct from "../../../../../../utils/useTct";
import FormModal from "../../../../../MuiComponents/Modals/FormModal";
import NewCollection from "../../../../../MuiComponents/reusableInputs/NewCollection";

export interface AddDeviceManuallyModalProps {}

const AddDeviceManuallyModal: React.FunctionComponent<
    AddDeviceManuallyModalProps
> = () => {
    const {
        isAddDeviceManuallyModalOpen,
        setAddDeviceManuallyModalOpen,
        setBluetoothScenarioModalInfo,
    } = useContext(BluetoothViewContext);

    const { t } = useContext(LanguageContext);

    const [deviceTypes, setDeviceTypes] = useState<BleDeviceTypeEntry[]>([]);
    const [selectedDeviceType, setSelectedDeviceType] = useState<string>("");
    const { getDeviceTypesForBluetoothModalAsync } = useTct();

    useEffect(() => {
        getDeviceTypes();
    }, []);

    const handleAddDeviceManually = () => {
        setBluetoothScenarioModalInfo({
            isOpen: true,
            data: {
                slotId: -1,
                macAddress: "",
                deviceType: selectedDeviceType as BleDeviceType,
                scenarioType: "" as any,
            },
            isAddManually: true,
        });

        setAddDeviceManuallyModalOpen(false);
    };

    const handleCloseDeviceManuallyModal = () => {
        setAddDeviceManuallyModalOpen(false);
    };

    const handleSelectDeviceType = (value: string) => {
        setSelectedDeviceType(value);
    };
    const getDeviceTypes = async () => {
        const data = await getDeviceTypesForBluetoothModalAsync();
        setDeviceTypes(data);
    };

    const collectionData = {
        label: t.DeviceType,
        collectionConfig: {
            collectionItems: deviceTypes.map((deviceType) => ({
                value: deviceType.entryName,
                text: deviceType.entryLocalizedName,
            })),
        } as any,
    };

    return (
        <FormModal
            isOpen={isAddDeviceManuallyModalOpen}
            onClose={handleCloseDeviceManuallyModal}
            secondaryButtonText={t.Cancel}
            primaryButtonText={t.Next}
            onSubmit={handleAddDeviceManually}
            title={t.AddDeviceManually}
            description={t.SelectTheDeviceYouWantToAdd}
            isPrimaryButtonDisabled={!selectedDeviceType}
        >
            <NewCollection
                data={collectionData}
                componentValue={selectedDeviceType}
                onChange={(event) => handleSelectDeviceType(event.value)}
            />
        </FormModal>
    );
};

export default AddDeviceManuallyModal;
