/** @jsxRuntime classic */

/** @jsx jsx */
import React, { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { ReactComponent as EmptyStateIcon } from "../../../../../../assets/BluetoothEmptyStateIcon.svg";
import { ReactComponent as AddIcon } from "../../../../../../assets/icons/Add.svg";
import { ReactComponent as EmptyBluetoothStateIcon } from "../../../../../../assets/icons/bluetooth/EmptyBluetoothState.svg";
import { ReactComponent as OfflineIcon } from "../../../../../../assets/icons/bluetooth/Offline.svg";
import { ReactComponent as PauseIcon } from "../../../../../../assets/icons/bluetooth/PauseLog.svg";
import { ReactComponent as RefreshIcon } from "../../../../../../assets/icons/bluetooth/Refresh.svg";
import { ReactComponent as StartArrowIcon } from "../../../../../../assets/icons/bluetooth/StartArrow.svg";
import {
    TOTAL_BLUETOOTH_DEVICE_COUNT,
    TableTypes,
} from "../../../../../../constants/constants";
import BluetoothViewContext from "../../../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import usePolling from "../../../../../../utils/usePolling";
import useTct from "../../../../../../utils/useTct";
import BannerView from "../../../../../MuiComponents/BannerView";
import Button from "../../../../../MuiComponents/Button";
import DialogModal from "../../../../../MuiComponents/Modals/DialogModal";
import SearchInput from "../../../../../MuiComponents/SearchInput";
import Table from "../../../../../MuiComponents/Table";
import Tooltip from "../../../../../MuiComponents/Tooltip";

import FilterSelect from "./components/FilterSelect";

export interface AddDeviceModalProps {}

const AddDeviceModal: React.FunctionComponent<AddDeviceModalProps> = () => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray100, gray300, white, textDarkDisabled },
    } = useContext(ThemeContext);

    const {
        isAddDeviceModalOpen,
        setAddDeviceModalOpen,
        setBluetoothScenarioModalInfo,
        bluetoothDevices,
        setAddDeviceManuallyModalOpen,
    } = useContext(BluetoothViewContext);

    const { layoutData } = useContext(LayoutContext);

    const [searchValue, setSearchValue] = useState("");
    const [isScanning, setIsScanning] = useState(true);
    const { getBluetoothDevicesScanAsync } = useTct();
    const [data, setData] = useState<any>([]);

    usePolling(
        async () => {
            const newData = await getBluetoothDevicesScanAsync();
            setData((prevData: any) => {
                const dataMap = new Map(
                    prevData.map((item: any) => [item.macAddress, item]),
                );
                newData.forEach((item: any) => {
                    dataMap.set(item.macAddress, item);
                });
                return Array.from(dataMap.values());
            });
        },
        5000,
        isScanning && layoutData?.isOnline && isAddDeviceModalOpen,
    );

    const handleRefresh = () => {
        setData([]);
    };

    const renderEmptyState = () => {
        const headerStyles = css({
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px", // 125%
            letterSpacing: "0.15px",
            marginBottom: "8px",
        });
        const subtitleStyles = css({
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px", // 142.857%
            letterSpacing: "0.1px",
            marginBottom: "8px",
        });
        const renderBlock = (
            icon: any,
            header: string,
            subtitle: string,
            buttonText?: string,
        ) => (
            <Fragment>
                {icon}
                <div css={headerStyles}>{header}</div>
                <div css={subtitleStyles}>{subtitle}</div>
                {buttonText && (
                    <Button
                        variant="textOnly"
                        size="small"
                        color="primary"
                        onClick={handleAddDeviceManually}
                        idForTesting="addManuallyButton"
                    >
                        {t.AddDeviceManually}
                    </Button>
                )}
            </Fragment>
        );
        if (!layoutData?.isOnline) {
            return renderBlock(
                <OfflineIcon />,
                t.DeviceNotConnectedToComputer,
                t.AddDeviceManuallyToTheList,
                t.AddManually,
            );
        }
        if (isScanning && !searchValue) {
            return renderBlock(
                <EmptyBluetoothStateIcon />,
                t.LookingForDevices,
                t.NewDevicesWillAppearHere,
            );
        }
        return renderBlock(
            <EmptyStateIcon />,
            t.NoDevicesFound,
            t.TryDifferentKeywords,
        );
    };

    const handleAddDeviceManually = () => {
        setAddDeviceModalOpen(false);
        setAddDeviceManuallyModalOpen(true);
    };

    const handleOnActionColumnAdd = (data: any) => {
        setBluetoothScenarioModalInfo({ isOpen: true, data: data });
        setAddDeviceModalOpen(false);
    };

    const hasTotalDeviceCount =
        bluetoothDevices.length >= TOTAL_BLUETOOTH_DEVICE_COUNT;

    return (
        <DialogModal
            open={isAddDeviceModalOpen}
            close={() => setAddDeviceModalOpen(false)}
            onSecondaryBtnClick={() => setAddDeviceModalOpen(false)}
            secondaryButtonText={t.Close}
            title={t.AddBluetoothDeviceModalTitle}
            description={t.AddBluetoothDeviceModalDescription}
            secondaryButtonColor="secondary"
            sx={{
                maxWidth: "872px",
                padding: "16px 24px 24px 24px",
            }}
        >
            <div
                css={{
                    marginTop: "16px",
                    display: "block",
                }}
            />
            {hasTotalDeviceCount && (
                <BannerView
                    data={{
                        bannerType: "Info",
                        description: t.AddBluetoothDeviceBanner,
                    }}
                />
            )}
            <div
                css={{
                    marginTop: "16px",
                    display: "block",
                }}
            />
            <div
                css={css({
                    borderRadius: "8px",
                    border: `1px solid ${gray100}`,
                })}
            >
                <div
                    css={css({
                        padding: "16px 24px",
                        display: "flex",
                        gap: "16px",
                    })}
                >
                    <div
                        css={css({
                            width: "100%",
                            display: "flex",
                        })}
                    >
                        <div css={css({ maxWidth: "268px", width: "100%" })}>
                            <SearchInput
                                onChange={(e) => setSearchValue(e.target.value)}
                                searchValue={searchValue}
                                resetValue={() => setSearchValue("")}
                                additionalStyles={{
                                    height: "36px",
                                }}
                                disabled={!layoutData?.isOnline}
                            />
                        </div>
                        <div
                            css={css({
                                width: "1px",
                                height: "36px",
                                margin: "0 16px",
                                backgroundColor: gray300,
                            })}
                        />

                        <div css={css({ maxWidth: "268px", width: "100%" })}>
                            <FilterSelect
                                onChange={() => console.log("test")}
                                value={[]}
                                name="RSSI"
                                isDisabled={!layoutData?.isOnline}
                            />
                        </div>
                    </div>
                    <div
                        css={css({
                            display: "flex",
                            gap: "16px",
                        })}
                    >
                        <Tooltip
                            title={
                                !layoutData?.isOnline
                                    ? ""
                                    : t.BluetoothAddDeviceModalScan
                            }
                            small
                            placement="top"
                        >
                            <Button
                                variant="iconOnly"
                                size="small"
                                color="secondary"
                                idForTesting="startScanButton"
                                icon={
                                    isScanning && layoutData?.isOnline ? (
                                        <PauseIcon />
                                    ) : (
                                        <StartArrowIcon />
                                    )
                                }
                                onClick={() => setIsScanning(!isScanning)}
                                disabled={!layoutData?.isOnline}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                !layoutData?.isOnline
                                    ? ""
                                    : t.BluetoothAddDeviceModalReset
                            }
                            small
                            placement="top"
                        >
                            <Button
                                variant="iconOnly"
                                size="small"
                                color="secondary"
                                idForTesting="refreshButton"
                                icon={<RefreshIcon />}
                                onClick={handleRefresh}
                                disabled={!layoutData?.isOnline}
                            />
                        </Tooltip>
                    </div>
                    <Tooltip
                        title={
                            hasTotalDeviceCount
                                ? t.TotalDeviceCountExceeded
                                : ""
                        }
                        small
                        placement="top"
                    >
                        <div
                            css={css({
                                whiteSpace: "nowrap",
                            })}
                        >
                            <Button
                                fullWidth
                                variant="iconLeft"
                                size="small"
                                color="primary"
                                onClick={handleAddDeviceManually}
                                idForTesting={`alert-primary-button`}
                                css={css({
                                    maxWidth: "147px",
                                })}
                                icon={
                                    <AddIcon
                                        css={css({
                                            path: {
                                                fill: hasTotalDeviceCount
                                                    ? textDarkDisabled
                                                    : white,
                                            },
                                        })}
                                    />
                                }
                                disabled={hasTotalDeviceCount}
                            >
                                {t.AddManually}
                            </Button>
                        </div>
                    </Tooltip>
                </div>
                <div>
                    <Table
                        data={
                            data?.map((item: any, index: number) => ({
                                ...item,
                                id: index,
                                hasTotalDeviceCount,
                            })) || []
                        }
                        searchValue={searchValue}
                        tableType={TableTypes.BluetoothScan}
                        onDeleteClick={() => {}}
                        emptyState={renderEmptyState()}
                        onActionColumnAdd={handleOnActionColumnAdd}
                        hasStickyCell={false}
                        hasNoSelectRows
                    />
                </div>
            </div>
        </DialogModal>
    );
};

export default AddDeviceModal;
