/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import { FEText } from "../../../../generatedTypes";
import parse from "html-react-parser";

interface FeTextProps {
    text: FEText;
}

const FeText = ({ text }: FeTextProps) => {
    const {
        colors: { gray100, textDark },
    } = useContext(ThemeContext);

    const parseTextStyle = (textStyle: string) => {
        switch (textStyle) {
            case "header1": {
                return {
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: textDark,
                };
            }
            case "normal": {
                return {
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: textDark,
                };
            }
            case "code": {
                return {
                    background: gray100,
                    borderRadius: "8px",
                    padding: "10px",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: textDark,
                };
            }
        }
    };

    return <span css={css(parseTextStyle(text.textStyle))}>{parse(text.text)}</span>;
};

export default FeText;
