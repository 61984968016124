/** @jsxRuntime classic */

/** @jsx jsx */
import { SyntheticEvent, useContext } from "react";

import { jsx } from "@emotion/react";

import {
    IoTableColumns,
    NameValueStringsPair,
    operandColumns,
} from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import LanguageContext from "../../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../../../../utils/types";
import FilterDropdown from "../../../../../../../MuiComponents/FilterDropdown";

const OperandSelect = () => {
    const { setActiveOperand, activeOperand, activeColumns } =
        useContext(InputOutputContext);

    const { layoutData } = useContext(LayoutContext);

    const { t } = useContext(LanguageContext);

    const handleChange = (
        event: SyntheticEvent<any>,
        newValues: NameValueStringsPair[],
    ) => {
        event.preventDefault();

        const newActiveOperands = newValues.map(
            (newValue: NameValueStringsPair) => {
                return newValue.value;
            },
        );

        setActiveOperand(newActiveOperands);
    };

    const isDisabled = !activeColumns.some(
        (item) => item === IoTableColumns.operand,
    );

    const isFtcDeviceFamily =
        !!layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    const operandOptions = operandColumns(isFtcDeviceFamily);

    const getValues = () => {
        return activeOperand.map((operand: string) => {
            return operandOptions.find(
                (operandOption) => operandOption.value === operand,
            );
        });
    };

    return (
        <FilterDropdown
            checkbox
            currentValue={getValues()}
            placeholder={t.Operand}
            options={operandOptions}
            isDisabled={isDisabled}
            handleChange={handleChange}
        />
    );
};

export default OperandSelect;
