import { replacePlaceholders } from "./helpers";

export const validateNumberLength = (
    val: string,
    min: number | undefined,
    max: number | undefined,
    t: { [key: string]: string },
) => {
    if (val === "") {
        return t.ValueIsNotANumber;
    }

    if ((!min && min !== 0) || (!max && max !== 0)) {
        return null;
    }

    const valueAsNumber = Number(val);

    if (
        valueAsNumber >= min &&
        valueAsNumber <= max &&
        val === valueAsNumber.toString()
    ) {
        return null;
    }

    return replacePlaceholders(t.InputSupport, min, max);
};

export const validateTextLength = (
    val: string,
    textMinLength: number | undefined,
    textMaxLength: number | undefined,
    t: { [key: string]: string },
) => {
    if (
        (!textMinLength && textMinLength !== 0) ||
        (!textMaxLength && textMaxLength !== 0)
    ) {
        return null;
    }
    if (val.length >= textMinLength && val.length <= textMaxLength) {
        return null;
    }
    return replacePlaceholders(
        t.InputSupportsLength,
        textMinLength,
        textMaxLength,
    );
};

export const validateRegex = (
    value: string,
    regex: string,
    validationMessage: string,
    t: { [key: string]: string },
) => {
    if (regex === null) {
        return null;
    }
    const regexp = new RegExp(regex);

    if (typeof value === "string" && !regexp.test(value)) {
        return validationMessage || t.InvalidInput;
    }
    return null;
};

export const validateTextLengthAndRegex = (
    value: string,
    textMinLength: number,
    textMaxLength: number,
    regex: string,
    validationMessage: string,
    t: { [key: string]: string },
) =>
    validateRegex(value, regex, validationMessage, t) ||
    validateTextLength(value, textMinLength, textMaxLength, t);

export const validateNumberLengthAndRegex = (
    value: string,
    min: number,
    max: number,
    regex: string,
    validationMessage: string,
    t: { [key: string]: string },
) =>
    validateRegex(value, regex, validationMessage, t) ||
    validateNumberLength(value, min, max, t);
