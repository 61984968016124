import { AxiosResponse } from "axios";

import { ListItemInterface } from "../components/Editor/MenuItemView/Segment/Component/List/List";
import { polygonMaxVerticles } from "../constants/constants";
import { ErrorsObj } from "../context/list/listReducer";
import { FirmwareBundleUpdateStep } from "../generatedTypes";

import { ErrorDetails } from "./types";

export const convertMinToSeconds = (minutes: number) => minutes * 60;

export const getUniqueListBy = (arr: any[], key: string) => {
    return [...(new Map(arr.map((item) => [item[key], item])).values() as any)];
};

export const findInArrayByIndex = (array: any[], index: number): any =>
    array.find((item) => item.index === index);

export const convertIntToTimeString = (timeInt: number) => {
    const hours = Math.floor(timeInt / 60);
    const minutes = timeInt % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
    }`;
};

export const convertTimeFromStringToInt = (timeString: string) => {
    const formatTimeStringToMinutes =
        Number(timeString.split(":")[0]) * 60 +
        Number(timeString.split(":")[1]);

    return formatTimeStringToMinutes;
};

export const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop);

export const noop = () => {
    // This is intentional
};

export const getIdTooltipText = (parameterId: number, avlId: number): string =>
    avlId === 0 ? `ID: ${parameterId}` : `ID: ${parameterId}, AVL ID:${avlId}`;

export const convertPathToTwoBackslashes = (path: string) => {
    if (!path) {
        return path;
    }
    return path.replace(/\\/g, "\\\\");
};

export const convertUnixTimeToDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
};

export const convertBytesToKilobytes = (bytes: number) => {
    return Math.round(bytes / 1024) + " kB";
};

export const findSubStringInString = (string: string, subString: string) => {
    let index = string.indexOf(subString);
    if (index === -1) {
        return false;
    }
    return true;
};

export const preventENotation = (e: React.KeyboardEvent<HTMLInputElement>) =>
    ["e", "E", "+"].includes(e.key) && e.preventDefault();

export const getSumOfObjectValues = (obj: any, index: number) => {
    let sum = 0;
    for (let i = 0; i < index; i++) {
        sum += obj[i];
    }
    return sum;
};

export const addOrRemoveStringFromArray = (arr: string[], str: string) => {
    if (arr.includes(str)) {
        return arr.filter((item) => item !== str);
    } else {
        return [...arr, str];
    }
};

export const getFileNameFromPath = (path: string) => {
    if (!path) return "";
    const split = path.split(/.*[/|\\]/);
    return split.length === 1 ? split[0] : split[1];
};
export const formatArrayIntoOneArrayWithComponents = (array: any[]) => {
    const oldArray = [...array];
    let newArray: any[] = [];

    oldArray.forEach((item) => {
        if (item.hasOwnProperty("components")) {
            let tempObject: { [key: string]: any } = {};
            item.components.forEach((component: any) => {
                tempObject[component.localizationKey] = component;
            });
            newArray.push(tempObject);
        } else {
            newArray.push(item);
        }
    });
    return newArray;
};

export const returnProvidedNumberOfItemsOfArray = (
    array: any[],
    numberOfItems: number,
) => {
    const newArray = [...array];
    return newArray.slice(0, numberOfItems);
};

export const formatCoordinatesIntoMultiArrayBasedOnType = (
    obj: { x: string; y: string }[],
    type: number,
) => {
    const result: any = [];
    const newArray = [...obj];
    if (type === 1) {
        result.push([Number(newArray[0].y), Number(newArray[0].x)]);
    } else if (type === 2) {
        result.push(
            [Number(newArray[0].y), Number(newArray[0].x)],
            [Number(newArray[1].y), Number(newArray[1].x)],
        );
    } else {
        newArray.forEach((item) => {
            Number(item.y) !== 0 &&
                Number(item.x) !== 0 &&
                result.push([Number(item.y), Number(item.x)]);
        });
    }
    return result?.length ? result : [[0, 0]];
};

export const roundToSixDecimals = (num: number) => {
    return Math.round(1000000 * num) / 1000000;
};

export const getParameterIdsAndValuesFromLayout = (
    object: any,
    uniqueTag?: string,
) => {
    let result: any = [];
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            if (key === "parameterId") {
                result.push({
                    id: object[key],
                    value: object["parameterValue"],
                    oldValue: object["parameterValue"],
                    error: "",
                    ...(uniqueTag && { uniqueTag }),
                });
            } else if (typeof object[key] === "object") {
                result = result.concat(
                    getParameterIdsAndValuesFromLayout(object[key], uniqueTag),
                );
            }
        }
    }
    return result.filter((item: any) => item.value !== undefined);
};

export const convertAxiosErrorToErrorDetails = (
    err: AxiosResponse<{ message: string }>,
): ErrorDetails => {
    const error = err as any;
    let messageTitle;
    let messageDescription;

    if (error.response) {
        messageTitle =
            error.response.data.title ||
            error.response.data ||
            error.response.statusText;
        messageDescription = error.response.data.detail || messageTitle;
    } else {
        if (error.request) {
            if (error.request.statusText) {
                messageTitle = error.request.statusText;
            } else {
                messageTitle = error.message;
            }
        } else {
            messageTitle = error.message;
        }
        messageDescription = messageTitle;
    }

    return { title: messageTitle, description: messageDescription };
};

export const bitsToNumber = (bits: any[]) => {
    let result = 0;
    for (let i = 0; i < 8; i++) {
        result += bits[i] * Math.pow(2, 7 - i);
    }
    return result;
};

export const numberToBits = (number: number) => {
    let bits = [];

    // convert the number to binary
    while (number > 0) {
        bits.unshift(number % 2);
        number = Math.floor(number / 2);
    }

    // pad the array with zeroes if necessary
    while (bits.length < 8) {
        bits.unshift(0);
    }
    return bits;
};

export const decimalCount = (num: number | undefined) => {
    // Convert to String
    if (!num) {
        return 0;
    }
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options as any);
};

export const findMissingGeozoneIndex = (geozones: any[]): number => {
    const indices = new Set<number>();

    for (const geozone of geozones) {
        indices.add(geozone.index);
    }

    for (let i = 0; i < geozones.length; i++) {
        if (!indices.has(i)) {
            return i;
        }
    }
    // If no missing index found, return the next index
    return geozones.length;
};

export const addOrReplaceObjectInArray = (array: any[], newObj: any): any[] => {
    const index = array.findIndex((item) => item.id === newObj.id);

    if (index === -1) {
        // If the object with the same ID doesn't exist, add the new object to the array
        return [...array, newObj];
    } else {
        // If the object with the same ID exists, replace it with the new object
        const newArray = [...array];
        newArray[index] = newObj;
        return newArray;
    }
};

export const findBiggestId = (objects: any[]): number | null => {
    if (objects.length === 0) {
        return null;
    }

    let biggestId = objects[0].id;
    for (const obj of objects) {
        if (obj.id > biggestId) {
            biggestId = obj.id;
        }
    }

    return biggestId;
};

export const formatCoordinatesBasedOnGeozoneShape = (geozone: any) => {
    const latitude =
        geozone.type === 1
            ? [
                  roundToSixDecimals(geozone.coordinates[0][0]),
                  ...Array(polygonMaxVerticles).fill(0),
              ]
            : [
                  ...geozone.coordinates.map((item: number[]) =>
                      roundToSixDecimals(item[0]),
                  ),
                  ...Array(polygonMaxVerticles).fill(0),
              ];

    const longitude =
        geozone.type === 1
            ? [
                  roundToSixDecimals(geozone.coordinates[0][1]),
                  ...Array(polygonMaxVerticles).fill(0),
              ]
            : [
                  ...geozone.coordinates.map((item: number[]) =>
                      roundToSixDecimals(item[1]),
                  ),
                  ...Array(polygonMaxVerticles).fill(0),
              ];

    return {
        latitude,
        longitude,
    };
};

export const replacePlaceholders = (
    str: string,
    arg1: number | string,
    arg2?: number | string,
    arg3?: number | string,
): string => {
    return str
        .replace("{0}", String(arg1))
        .replace("{1}", String(arg2))
        .replace("{2}", String(arg3));
};

export const getParametersFromLayout = async (
    allData: any[],
): Promise<Record<string, any>> => {
    const parameters: Record<string, any> = {};

    const handleListComponent = (listConfig: any): boolean => {
        if (!listConfig) return false;
        return listConfig.items.some(
            ({ value }: { value: string }) => value.trim().length > 0,
        );
    };

    const addParameter = (
        componentType: string,
        parameterId: number,
        parameterValue: any,
        listConfig?: any,
    ): void => {
        if (componentType === "List" && listConfig) {
            parameters[listConfig.name] = handleListComponent(listConfig);
        } else if (parameterId !== 0) {
            parameters[parameterId] = parameterValue;
        }
    };

    const handleComponent = (component: any): void => {
        addParameter(
            component.componentType,
            component.parameterId,
            component.parameterValue,
            component.listConfig,
        );
    };

    const handleSwitch = (switchId: number, switchValue: any): void => {
        if (switchValue) {
            addParameter("Switch", switchId, switchValue);
        }
    };

    const processComponentArray = (components: any[]): void => {
        components.forEach(handleComponent);
    };

    const processCustomScenarioTriggers = (triggers: any[]): void => {
        triggers.forEach((trigger) => {
            Object.values(trigger).forEach((item: any) => {
                if (Array.isArray(item)) {
                    item.forEach((subItem) => handleComponent(subItem));
                } else if (item?.parameterId) {
                    handleComponent(item);
                }
            });
        });
    };

    const handleRecordsScheduleModal = (tableColumns: any) => {
        return tableColumns.forEach((column: any) => {
            if (column.localizationKey === "Day") {
                column.components.forEach((component: any) => {
                    handleSwitch(component.switchId, component.switchValue);
                });
            } else {
                processComponentArray(column.components);
            }
        });
    };

    const processSegment = (segment: any): void => {
        const {
            component,
            groupBox,
            ecoCalculator,
            ioItem,
            passwordBox,
            canItem,
            customScenario,
            modal,
        } = segment;

        if (component) {
            handleComponent(component);
        } else if (groupBox) {
            handleSwitch(groupBox.switchId, groupBox.switchValue);
            processComponentArray(groupBox.components);
        } else if (ecoCalculator?.components) {
            processComponentArray(ecoCalculator.components);
        } else if (modal?.components) {
            if (modal.localizationKey === "Records schedule" && modal.table) {
                return handleRecordsScheduleModal(modal.table.columns);
            }

            processComponentArray(modal.components);
        } else if (ioItem || canItem) {
            const items = ioItem || canItem;
            Object.values(items)
                .filter((item: any) => item?.parameterId)
                .forEach(handleComponent);
        } else if (customScenario) {
            processCustomScenarioTriggers(customScenario.triggers);
        } else if (passwordBox?.component) {
            handleComponent(passwordBox.component);
        }
    };

    const processBlocks = (blocks: any[]): void => {
        blocks.forEach((block) => {
            handleSwitch(block.switchId, block.switchValue);
            block.segments.forEach(processSegment);
        });
    };

    allData.forEach((data) => {
        handleSwitch(data.switchId, data.switchValue);
        if (data.modal) {
            processBlocks(data.modal.blocks);
        }
        processBlocks(data.blocks);
    });

    return parameters;
};

export const createListErrors = (
    availableInputs: ListItemInterface[],
    maxLength: number,
    t: any,
) => {
    const errorsObj: ErrorsObj = availableInputs.reduce((previous, current) => {
        const errorValue =
            current.value.length > maxLength
                ? replacePlaceholders(t.ValidateMaxLength, maxLength)
                : null;

        return { ...previous, [current.index]: errorValue };
    }, {});

    return errorsObj;
};

export const isNullOrUndefined = (obj: any): boolean =>
    obj === undefined || obj === null;

export const findAdjacentItem = (array: string[], id: string | number) => {
    const index = array.findIndex((item) => item === id);
    if (index === -1) return undefined; // id not found in array

    if (index > 0) {
        // If there is a previous item, return it
        return array[index - 1];
    } else if (index < array.length - 1) {
        // If there is no previous item but there is a next item, return the next item
        return array[index + 1];
    }

    // If there is neither a previous item nor a next item, return undefined
    return undefined;
};

export const encodeQueryParams = (url: string) => {
    if (!url) {
        return url;
    }

    const amp = "&";
    const quot = "?";
    const eq = "=";

    const urlSegments = url.split(quot);
    const origin = urlSegments[0] + (urlSegments.length > 1 ? quot : "");
    let result = [];

    for (const param of urlSegments[urlSegments.length - 1]
        .split(amp)
        .map((pair) => pair.split(eq))) {
        result.push({
            key: param[0],
            value: encodeURIComponent(param.slice(1).join(eq)),
        });
    }

    return (
        origin +
        result.map((x) => x.value && `${x.key}${eq}${x.value}`).join(amp)
    );
};

export const extractEXIMFile = (path: string) => {
    if (path.length > 1000) {
        return "";
    }
    if (path?.toLowerCase().endsWith(".e.xim")) {
        const fileName = path.split(/[/\\]/).pop();
        return fileName ? fileName : "";
    }
    return "";
};

export const sortFirmwareAndDtb = (
    items: FirmwareBundleUpdateStep[],
): {
    dtb: FirmwareBundleUpdateStep[];
    firmware: FirmwareBundleUpdateStep[];
} => {
    const result: {
        dtb: FirmwareBundleUpdateStep[];
        firmware: FirmwareBundleUpdateStep[];
    } = {
        firmware: [],
        dtb: [],
    };

    items.forEach((item) => {
        if (item.fileType === "Firmware") {
            result.firmware.push(item);
        } else if (item.fileType === "Dtb") {
            result.dtb.push(item);
        }
    });

    return result;
};

export const getRandomNumber = (max: number = 2147483647) => {
    let array: any = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    let randomInteger = array % (max + 1);
    return randomInteger;
};

export const formatBundleUpdateSteps = (updates: any[]): any[] => {
    return updates.map(
        ({ details, progress, progressBarName, ...rest }) => rest,
    );
};

export const toFirstLowerCase = (str: string): string => {
    if (!str) {
        return "";
    }
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const chooseValue = (
    condition: any,
    valueForTrue: any,
    valueForFalse: any = null,
) => (condition ? valueForTrue : valueForFalse);

export const getDefaultValuesFromIoItem = (data: any) => {
    const newArray: { id: string; value: string }[] = [];
    if (data) {
        Object.values(data).forEach((item: any) => {
            if (item?.parameterId) {
                newArray.push({
                    id: item.parameterId,
                    value: item.default,
                });
            } else if (Array.isArray(item)) {
                item.forEach((subItem) => {
                    if (subItem?.parameterId) {
                        newArray.push({
                            id: subItem.parameterId,
                            value: subItem.default,
                        });
                    }
                });
            } else {
                return;
            }
        });
    }
    return newArray;
};

export const firstLetterToLowercase = (str: string | undefined): string => {
    if (!str) return "";
    return str.charAt(0).toLowerCase() + str.slice(1);
};
