/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { css, jsx } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";

import AlertWarningIcon from "../../assets/icons/AlertWarningIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import ThemeContext from "../../context/theme/themeContext";

import Tooltip from "./Tooltip";

export interface LabelProps {
    title: string;
    required?: boolean;
    info?: string;
    rightTitle?: string | null;
    requesting?: boolean;
    requestFailed?: boolean;
    onInfoIconClick?: () => void;
    withoutTooltipPadding?: boolean;
}
const Label = ({
    title,
    required,
    info,
    rightTitle,
    requesting,
    requestFailed,
    onInfoIconClick,
    withoutTooltipPadding,
}: LabelProps) => {
    const {
        colors: { blue700, gray700, gray200 },
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "4px",
                ".label": {
                    color: gray700,
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                },
                svg: {
                    fontSize: "16px",
                },
            })}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                })}
            >
                <span className="label">{title}</span>

                {required && <Fragment>&#42;</Fragment>}

                {info && (
                    <Tooltip title={info} placement="top">
                        <span
                            css={css({
                                display: "flex",
                                alignItems: "center",
                                padding: withoutTooltipPadding ? "0px" : "4px",
                                marginLeft: "4px",
                                borderRadius: "6px",
                                cursor: "pointer",
                                ":hover": {
                                    backgroundColor: gray200,
                                    transform: "scale(1.2)",
                                },
                            })}
                        >
                            <InfoIcon
                                color="primary"
                                onClick={onInfoIconClick}
                            />
                        </span>
                    </Tooltip>
                )}
            </div>

            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                })}
            >
                {(requesting || requestFailed) && (
                    <div
                        css={css({
                            width: "16px",
                            height: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "4px",
                        })}
                    >
                        {requesting ? (
                            <CircularProgress
                                size={13}
                                css={css({ color: blue700 })}
                            />
                        ) : (
                            <Tooltip
                                title="Changes that were made recently could not be saved due to an error."
                                placement="top"
                            >
                                <span
                                    css={css`
                                        display: inline-flex;
                                    `}
                                >
                                    <AlertWarningIcon />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                )}

                {rightTitle && (
                    <div css={css({ marginLeft: "4px" })}>
                        <span className="label">{rightTitle}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Label;
