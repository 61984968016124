/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useRef } from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import DeviceLocked from "../DeviceLocked";
import CodeInput from "../CodeInput";
import Button from "../../../../../../MuiComponents/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import { SimPinModalTypes } from "../index";
import { chooseValue } from "../../../../../../../utils/helpers";
import useChangePin from "./useChangePin";

interface ChangePinProps {
    simId: number;
    close: () => void;
}

const ChangePin = ({ simId, close }: ChangePinProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const submitBtnRef = useRef<HTMLButtonElement>(null);

    const {
        handlePin,
        handleSubmitBtn,
        deviceLocked,
        isLoading,
        isDisabled,
        step,
        explanatoryText,
        submitButtonText,
        errorMsg,
        retriesLeft,
    } = useChangePin({ simId, close });

    const renderButtonText = () =>
        isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            submitButtonText
        );

    return deviceLocked() ? (
        <DeviceLocked type={SimPinModalTypes.ChangePin} close={close} />
    ) : (
        <Fragment>
            <DialogTitle>{t.ChangePinCode}</DialogTitle>

            <DialogContent>
                <CodeInput
                    error={errorMsg}
                    retriesLeft={retriesLeft}
                    explanatoryText={explanatoryText}
                    step={step}
                    setPayloadValue={handlePin}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    "&.MuiDialogActions-root>:not(:first-of-type)": {
                        marginLeft: "12px",
                    },
                }}
            >
                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    idForTesting="cancelBtn"
                >
                    {t.Cancel}
                </Button>

                <Button
                    ref={submitBtnRef}
                    variant="textOnly"
                    size="normal"
                    color="primary"
                    fullWidth
                    disabled={isDisabled}
                    onClick={handleSubmitBtn}
                    css={css({
                        pointerEvents: chooseValue(isLoading, "none", "auto"),
                    })}
                    idForTesting="submitBtn"
                >
                    {renderButtonText()}
                </Button>
            </DialogActions>
        </Fragment>
    );
};

export default ChangePin;
