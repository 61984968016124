import React, { useContext } from "react";

import { Global, css } from "@emotion/react";

import LanguageContext from "../../context/language/languageContext";
import ThemeContext from "../../context/theme/themeContext";

const GlobalStyles = () => {
    const { colors } = useContext(ThemeContext);

    const { loading } = useContext(LanguageContext);

    return (
        <Global
            styles={css({
                "html, body": {
                    overscrollBehavior: "none",
                },
                html: {
                    height: "100%",
                },

                "&::-webkit-scrollbar": {
                    width: "18px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: colors.blue200,
                    borderRadius: "100px",
                    border: `5px solid ${colors.white}`,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: colors.blue400,
                },

                body: {
                    minHeight: "100vh",
                    margin: 0,
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "14px",
                    background: colors.gray50,
                    opacity: loading ? 0.4 : 1,
                    pointerEvents: loading ? "none" : "auto",
                },

                "h1, h2, h3, h4, h5, h6, p": {
                    margin: 0,
                },

                "#root": {
                    minHeight: "100vh",
                },
                "#root, .remaining-height": {
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                },
                ".sentry-error-embed-wrapper .powered-by": {
                    display: "none",
                },
                ".leaflet-draw-actions": {
                    display: "none !important",
                },

                ".react-tel-input": {
                    ".invalid-number-message": {
                        display: "none",
                    },
                    ".invalid-number": {
                        borderColor: `${colors.red800} !important`,
                        backgroundColor: "unset !important",
                    },
                    ".country-list": {
                        maxHeight: "110px !important",
                        "&::-webkit-scrollbar": {
                            width: "18px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: colors.blue200,
                            borderRadius: "100px",
                            border: `5px solid ${colors.white}`,
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: colors.blue400,
                        },
                    },
                },
            })}
        />
    );
};

export default GlobalStyles;
