/** @jsxRuntime classic */

/** @jsx jsx */
import { Row, UseTableRowProps } from "react-table";

import { css, jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import DescriptionWithSwitch from "../components/DescriptionWithSwitch";
import TableCollection from "../components/TableCollection";
import TableInput from "../components/TableInput";

export const getIoTableColumns = (
    name: string,
    currentValues: any,
    gray100: any,
) => [
    {
        id: IoTableColumns.name,
        accessor: IoTableColumns.name,
        Header: name,
        minWidth: 470,
        filter: (allRows: Row<any>[], _: any[], filterValue: string) => {
            return allRows.filter((row: any) => {
                if (row.state?.cellState?.disabled !== undefined) {
                    const disabledValue = row.state?.cellState?.disabled
                        ? "0"
                        : "1";
                    return disabledValue === filterValue;
                }
                return row.original.parameterValue?.switchValue === filterValue;
            });
        },
        Cell: ({ row }: any) => {
            return (
                <DescriptionWithSwitch
                    row={row}
                    onChange={(e) =>
                        row.setState((state: any) => {
                            const disabledValue = !e ? "0" : "1";
                            return {
                                cellState: {
                                    ...state.cellState,
                                    disabled: !e,
                                    priorityItem: disabledValue,
                                },
                            };
                        })
                    }
                />
            );
        },
    },
    {
        id: IoTableColumns.currentValue,
        accessor: IoTableColumns.currentValue,
        Header: IoTableColumnTitles.currentValue,
        filter: (allRows: Row<any>[]) => {
            return allRows.filter(
                (row) =>
                    !!currentValues[row.original?.parameterValue?.id]?.trim()
                        .length,
            );
        },
        Cell: ({ row, ...other }: any) => {
            const rowValue =
                other.state.currentValues[row.original?.parameterValue?.id];
            const maxShownStringLength = 14;

            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        fontSize: "14px",
                        backgroundColor: gray100,
                        height: "100%",
                        width: "100%",
                    })}
                >
                    {other.state.currentValues && !!rowValue?.trim().length ? (
                        <Tooltip
                            title={
                                rowValue.length > maxShownStringLength
                                    ? rowValue
                                    : ""
                            }
                            small
                            placement="top"
                        >
                            <span
                                css={css({
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                })}
                            >
                                {rowValue}
                            </span>
                        </Tooltip>
                    ) : (
                        <span
                            css={css({
                                paddingLeft: "16px",
                                paddingRight: "16px",
                            })}
                        >
                            N/A
                        </span>
                    )}
                </div>
            );
        },
    },

    {
        id: IoTableColumns.priority,
        accessor: IoTableColumns.priority,
        Header: IoTableColumnTitles.priority,
        filter: (
            allRows: UseTableRowProps<any>[],
            _: any,
            filterValue: any,
        ) => {
            return allRows.filter((row: any) => {
                if (row.state?.cellState?.priorityItem !== undefined) {
                    return filterValue.some(
                        (value: string) =>
                            value === row.state?.cellState?.priorityItem,
                    );
                }
                return filterValue.some(
                    (value: string) =>
                        value === row.original.priority.parameterValue,
                );
            });
        },
        Cell: ({ row }: any) => {
            return row.original.priority ? (
                <TableCollection
                    data={row.original.priority}
                    switchId={row.original.parameterValue.id}
                    key={row.original.parameterValue.id}
                    onOptionClick={(e) =>
                        row.setState((state: any) => {
                            return {
                                cellState: {
                                    ...state?.cellState,
                                    priorityItem: e,
                                },
                            };
                        })
                    }
                />
            ) : null;
        },
    },
    {
        id: IoTableColumns.operand,
        accessor: IoTableColumns.operand,
        Header: IoTableColumnTitles.operand,
        filter: (
            allRows: UseTableRowProps<any>[],
            _: any[],
            filterValue: any,
        ) => {
            return allRows.filter((row: any) => {
                if (row.state?.cellState?.operandType !== undefined) {
                    return filterValue.some(
                        (value: string) =>
                            value === row.state?.cellState?.operandType,
                    );
                }
                return filterValue.some(
                    (value: string) =>
                        value === row.original.operand.parameterValue,
                );
            });
        },
        Cell: ({ row }: any) => {
            return row.original.operand ? (
                <TableCollection
                    data={row.original.operand}
                    switchId={row.original.parameterValue.id}
                    key={row.original.parameterValue.id}
                    onOptionClick={(e) =>
                        row.setState((state: any) => {
                            return {
                                cellState: {
                                    ...state?.cellState,
                                    operandType: e,
                                },
                            };
                        })
                    }
                />
            ) : null;
        },
    },

    {
        id: IoTableColumns.lowLevel,
        accessor: IoTableColumns.lowLevel,
        Header: IoTableColumnTitles.lowLevel,
        Cell: ({ row }: any) => {
            return row.original.lowLevel ? (
                <TableInput
                    switchId={row.original.parameterValue.id}
                    data={row.original.lowLevel}
                    key={row.original.parameterValue.id}
                />
            ) : null;
        },
    },
    {
        id: IoTableColumns.highLevel,
        accessor: IoTableColumns.highLevel,
        Header: IoTableColumnTitles.highLevel,
        Cell: ({ row }: any) => {
            return row.original.highLevel ? (
                <TableInput
                    switchId={row.original.parameterValue.id}
                    data={row.original.highLevel}
                    key={row.original.parameterValue.id}
                />
            ) : null;
        },
    },
    {
        id: IoTableColumns.eventOnly,
        accessor: IoTableColumns.eventOnly,
        Header: IoTableColumnTitles.eventOnly,
        Cell: ({ row }: any) => {
            return row.original.eventOnly ? (
                <TableCollection
                    data={row.original.eventOnly}
                    switchId={row.original.parameterValue.id}
                    key={row.original.parameterValue.id}
                    onOptionClick={(e) =>
                        row.setState((state: any) => {
                            return {
                                cellState: {
                                    ...state?.cellState,
                                    eventOnly: e,
                                },
                            };
                        })
                    }
                />
            ) : null;
        },
    },
    {
        id: IoTableColumns.avgConst,
        accessor: IoTableColumns.avgConst,
        Header: IoTableColumnTitles.avgConst,
        Cell: ({ row }: any) => {
            return row.original.averagingConstant ? (
                <TableInput
                    switchId={row.original.parameterValue.id}
                    data={row.original.averagingConstant}
                    key={row.original.parameterValue.id}
                />
            ) : null;
        },
    },
    {
        id: IoTableColumns.sendSmsTo,
        accessor: IoTableColumns.sendSmsTo,
        Header: IoTableColumnTitles.sendSmsTo,
        isVisible: false,
        filter: (allRows: UseTableRowProps<any>[]) =>
            allRows.filter((row) => row.original.phone.parameterValue !== "0"),
        Cell: ({ row }: any) =>
            row.original.phone ? (
                <TableCollection
                    data={row.original.phone}
                    switchId={row.original.parameterValue.id}
                    key={row.original.parameterValue.id}
                />
            ) : null,
    },
    {
        id: IoTableColumns.smsText,
        accessor: IoTableColumns.smsText,
        Header: IoTableColumnTitles.smsText,
        Cell: ({ row }: any) => {
            return row.original.text ? (
                <TableInput
                    switchId={row.original.parameterValue.id}
                    data={row.original.text}
                    inputType="text"
                    key={row.original.parameterValue.id}
                />
            ) : null;
        },
    },
];

export const getIoFilters = (
    activeFilters: any[],
    activePriority: any[],
    activeOperand: any[],
    activeStatus: string,
) => {
    const includesActiveFilter = (filter: string) =>
        activeFilters.includes(filter) ? true : undefined;
    return [
        {
            id: IoTableColumns.currentValue,
            value: includesActiveFilter(IoTableColumns.currentValue),
        },
        {
            id: IoTableColumns.sendSmsTo,
            value: includesActiveFilter(IoTableColumns.sendSmsTo),
        },
        {
            id: IoTableColumns.priority,
            value: activePriority.length ? activePriority : undefined,
        },
        {
            id: IoTableColumns.operand,
            value: activeOperand.length ? activeOperand : undefined,
        },
        {
            id: IoTableColumns.name,
            value: activeStatus !== "2" ? activeStatus : undefined,
        },
    ];
};
