/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import parse from "html-react-parser";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { Component } from "../../../../../../../generatedTypes";

import TableCollection from "./TableCollection";
import TableInput from "./TableInput";
import TableSwitchInput, { TableSwitchDefaultValue } from "./TableSwitchInput";

export interface MobileTableCardProps {
    row: {
        name: string;
        description: string;
        parameterValue: { id: number; switchValue: string };
        eventOnly: Component;
        highLevel: Component;
        lowLevel: Component;
        operand: Component;
        phone: Component;
        priority: Component;
        text: Component;
        averagingConstant: Component;
        canSrc: Component;
        canType: Component;
        canId: Component;
        dataMask: Component;
    };
    currentValue: string;
}

const MobileTableCard = ({ row, currentValue }: MobileTableCardProps) => {
    const { activeColumns } = useContext(InputOutputContext);
    const {
        colors: {
            gray700,
            blue100,
            gray100,
            white,
            textDarkSecondary,
            textDarkDisabled,
            red700,
            blue700,
        },
        boxShadows: { blueBoxShadow, ioTableCardBoxShadow },
    } = useContext(ThemeContext);
    const { findParameterValueById } = useContext(MenuItemContext);
    const { layoutData } = useContext(LayoutContext);

    const [focusedRow, setIsFocusedRow] = useState("");
    const [rowsWithErrors, setRowsWithErrors] = useState<string[]>([]);

    const isHiddenRow = (colName: string) =>
        activeColumns.some((activeCol) => activeCol === colName) ? true : null;

    const handleFocus = (isOpen: boolean, colName: string) => {
        setIsFocusedRow(isOpen ? colName : "");
    };

    const handleError = (error: string, colName: string) => {
        setRowsWithErrors((prev) => {
            if (error) {
                if (!prev.includes(colName)) {
                    return [...prev, colName];
                }
                return prev;
            } else {
                return prev.filter((rowName) => rowName !== colName);
            }
        });
    };

    const isInputDisabled =
        findParameterValueById(row.parameterValue.id) ===
        TableSwitchDefaultValue.OFF;

    const hasCurrentValue = layoutData?.isOnline;

    const renderCellBorder = (colName: string) => {
        if (isInputDisabled) {
            return "none";
        }
        if (rowsWithErrors.some((rowName) => rowName === colName)) {
            return `1px solid ${red700}`;
        }
        if (focusedRow === colName) {
            return `1px solid ${blue700}`;
        }
        return "none";
    };

    const renderCellTitle = (title: string) => (
        <div
            css={css({
                fontSize: "10px",
                color: isInputDisabled ? textDarkDisabled : gray700,
                letterSpacing: "1px",
            })}
        >
            {title}
        </div>
    );

    return (
        <div
            css={css({
                marginBottom: "8px",
                filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                fontWeight: 600,
                color: isInputDisabled ? textDarkDisabled : textDarkSecondary,
                marginLeft: "1px",
                marginRight: "1px",
            })}
        >
            <div
                css={css({
                    backgroundColor: blue100,
                    padding: "12px 16px",
                    boxShadow: blueBoxShadow,
                })}
            >
                {row.name}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    justifyContent: "space-between",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div>
                    <div
                        css={css({
                            marginBottom: "6px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {row.name}
                    </div>
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                            color: textDarkSecondary,
                        })}
                    >
                        {parse(row?.description || "")}
                    </div>
                </div>
                <div>
                    <TableSwitchInput
                        id={row.parameterValue.id}
                        label={String(row.parameterValue.id)}
                    />
                </div>
            </div>
            {hasCurrentValue && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: gray100,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.currentValue)}
                    <div css={css({ fontSize: "14px" })}>
                        {currentValue || "N/A"}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.priority) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.priority),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.priority)}
                    <div css={css({ fontSize: "14px" })}>
                        <TableCollection
                            data={row.priority}
                            switchId={row.parameterValue.id}
                            onFocus={(isOpen: boolean) =>
                                handleFocus(isOpen, IoTableColumns.priority)
                            }
                            anchorPositionHorizontal={"left"}
                            slotProps={{
                                paper: {
                                    style: {
                                        marginLeft: "8px",
                                        marginTop: "13px",
                                    },
                                },
                            }}
                            hasNoInputFocus
                            hasNoInputDisabledBackground
                        />
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.operand) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.operand),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.operand)}
                    <div css={css({ fontSize: "14px" })}>
                        <TableCollection
                            data={row.operand}
                            switchId={row.parameterValue.id}
                            onFocus={(isOpen: boolean) =>
                                handleFocus(isOpen, IoTableColumns.operand)
                            }
                            anchorPositionHorizontal={"left"}
                            slotProps={{
                                paper: {
                                    style: {
                                        marginLeft: "8px",
                                        marginTop: "13px",
                                    },
                                },
                            }}
                            hasNoInputFocus
                            hasNoInputDisabledBackground
                        />
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.lowLevel) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.lowLevel),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.lowLevel)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.lowLevel ? (
                            <TableInput
                                data={row.lowLevel}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.lowLevel,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.lowLevel)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.highLevel) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.highLevel),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.highLevel)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.highLevel ? (
                            <TableInput
                                data={row.highLevel}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.highLevel,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.highLevel)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.eventOnly) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.eventOnly),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.eventOnly)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.eventOnly ? (
                            <TableCollection
                                data={row.eventOnly}
                                switchId={row.parameterValue.id}
                                onFocus={(isOpen: boolean) =>
                                    handleFocus(
                                        isOpen,
                                        IoTableColumns.eventOnly,
                                    )
                                }
                                anchorPositionHorizontal={"left"}
                                slotProps={{
                                    paper: {
                                        style: {
                                            marginLeft: "8px",
                                            marginTop: "13px",
                                        },
                                    },
                                }}
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.avgConst) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.avgConst),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.avgConst)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.averagingConstant ? (
                            <TableInput
                                data={row.averagingConstant}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.avgConst,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.avgConst)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.sendSmsTo) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.sendSmsTo),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.sendSmsTo)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.phone ? (
                            <TableInput
                                data={row.phone}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.sendSmsTo,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.sendSmsTo)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileTableCard;
