import React, { Fragment, useEffect, useState } from "react";

import BlazorApi from "./components/Blazor/BlazorApi";
import Router from "./components/Router";
import AccessoriesState from "./context/accessories/AccessoriesState";
import AlertState from "./context/alert/AlertState";
import AppTypeState from "./context/appType/AppTypeState";
import AuthenticationState from "./context/authentication/authenticationState";
import BluetoothState from "./context/bluetoothView/bluetoothViewState";
import TerminalState from "./context/components/terminal/terminalState";
import ConfigurationState from "./context/configuration/ConfigurationState";
import DeviceStatusState from "./context/deviceStatus/DeviceStatusState";
import ErrorState from "./context/error/errorState";
import FeaturesState from "./context/features/FeaturesState";
import LanguageState from "./context/language/LanguageState";
import LayoutState from "./context/layout/LayoutState";
import ManualGeofenceState from "./context/manualGeofence/ManualGeofenceState";
import MenuItemState from "./context/menuItem/MenuItemState";
import SettingsState from "./context/settings/SettingsState";
import ThemeState from "./context/theme/ThemeState";
import UserInfoState from "./context/userInfo/UserInfoState";
import WebSocketsState from "./context/webSockets/webSocketsState";

type ProviderComponent = React.FC<{ children: React.JSX.Element }>;

// Create a combineProviders helper with proper typing
const combineProviders = (providers: ProviderComponent[]) => {
    return ({ children }: { children: React.JSX.Element }) => {
        return providers.reduceRight((acc, Provider) => {
            return <Provider>{acc}</Provider>;
        }, children);
    };
};

// Create array of providers
const providers = [
    ThemeState,
    ErrorState,
    AuthenticationState,
    AlertState,
    AppTypeState,
    LanguageState,
    UserInfoState,
    ConfigurationState,
    LayoutState,
    SettingsState,
    MenuItemState,
    DeviceStatusState,
    FeaturesState,
    AccessoriesState,
    BluetoothState,
    ManualGeofenceState,
    WebSocketsState,
    TerminalState,
];

// Create combined provider component
const AppProviders = combineProviders(providers);

const App = () => {
    const isWebEditor = process.env.REACT_APP_WEB_EDITOR === "true";
    const isDevEnvironment = process.env.NODE_ENV === "development";
    const useLoader = process.env.REACT_APP_USE_LOADER === "true";
    const [appReady, setAppReady] = useState(!useLoader);

    useEffect(() => {
        if (!appReady) {
            const interval = setInterval(() => {
                if ((window as any).tctLoader.isLoaded) {
                    clearInterval(interval);
                    setAppReady(true);
                }
            }, 100);
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
        };

        if (isWebEditor && !isDevEnvironment) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isWebEditor]);

    return (
        <Fragment>
            {isWebEditor && <BlazorApi />}
            {appReady && (
                <AppProviders>
                    <Router />
                </AppProviders>
            )}
        </Fragment>
    );
};

export default App;
