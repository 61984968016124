/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import Container from "../Container";
import Button from "../MuiComponents/Button";
import { CircularProgress } from "@mui/material";
import DiscoveredDevices from "../StartUp/Online/DiscoveredDevices";
import { DiscoveredDeviceData } from "../../utils/types";

const UsbAndBluetoothView: React.FunctionComponent = () => {
    const [oldPort, setPort] = useState<SerialPort | null>(null);
    const [receivedData, setReceivedData] = useState<string>("");
    const [reader, setReader] = useState<ReadableStreamDefaultReader | null>(
        null
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [availablePorts, setAvailablePorts] = useState<SerialPort[]>([]);
    const getPorts = async () => {
        const allowedPorts = await navigator.serial.getPorts();
        setAvailablePorts(allowedPorts);
        (window as any).serialCommunicator.setKnownPorts(allowedPorts);
    };
    useEffect(() => {
        getPorts();
    }, []);

    const readFromPort = async (port: SerialPort, number: number) => {
        const newReader = port.readable.getReader(); // Create a new reader
        let receivedBytes = [];
        const cancellation = setTimeout(() => {
            newReader.cancel("timeout");
            console.log("readFromPort timeout")
        }, 500);
        try {
            console.log("starting reading from port", number);
            while (true) {
                const { value, done } = await newReader.read();
                clearTimeout(cancellation);
                console.log("a read is done from port", number);

                if (done) {
                    // Reader has been canceled.
                    console.log("readFromPort reading done")
                    break;
                }
                receivedBytes.push(...(value as Uint8Array));
                console.log("readFromPort receivedBytes", receivedBytes);
                console.log("readFromPort received", new TextDecoder().decode(new Uint8Array(receivedBytes)));
                if (receivedBytes.length >= 1) {
                    const lastByte = receivedBytes[receivedBytes.length - 1];
                    if (lastByte == 13) { // 13 is the ASCII code for carriage return (\r)
                        break;
                    }
                }
            }
            return new TextDecoder().decode(new Uint8Array(receivedBytes));
        } catch (error) {
            console.error(`readFromPort Error reading data: ${error}`);
        } finally {
            newReader.releaseLock();
        }
    }

    const getDiscoveredDeviceFromInfo = (deviceInfo: string | undefined) => {
        if (!deviceInfo) {
            return;
        }
        const infoLines = deviceInfo.split(/([\n;\r])/g);
        const fwRegex = /(?:cfg_info:)?0:(.*)/;
        const hwVersionRegex = /(?:cfg_info:)?2:(.*)/;
        const imeiRegex = /(?:cfg_info:)?3:(.*)/;
        const discoveredDevice: DiscoveredDeviceData = {
            isAuthorized: false,
            connectionType: Math.round(Math.random()), // NOSONAR S2245: It is safe to use Math.random() here
            firmware: infoLines.filter(line => line.match(fwRegex))[0].match(fwRegex)![1],
            fmType: infoLines.filter(line => line.match(hwVersionRegex))[0].match(hwVersionRegex)![1],
            imei: infoLines.filter(line => line.match(imeiRegex))[0].match(imeiRegex)![1],
            hwversion: infoLines.filter(line => line.match(hwVersionRegex))[0].match(hwVersionRegex)![1],
            isConfigurable: true,
            portName: "",
            specId: 1,
            technologies: [],
        };

        return discoveredDevice;
    }
    useEffect(() => {
        const sendInfoCommand = async (port: SerialPort, number: number) => {
            if (!port || !port.writable) {
                console.error("sendInfoCommand Port  is not open");
                return;
            }

            const writer = port.writable.getWriter();
            const infoCommand = new TextEncoder().encode(
                ":cfg_info:?\rTMS :cfg_info:?\n"
            );
            await writer.write(infoCommand);
            writer.releaseLock();

            const infoResponse = await readFromPort(port, number);

            return getDiscoveredDeviceFromInfo(infoResponse);
        }

        const getInfo = async (port: SerialPort, number: number) => {
            getInfo(port, number);
            try {
                console.log("getInfo port", number);
                // Check if the port is already open
                if (port && port.readable && port.writable) {
                    console.log("getInfo Port is already open");
                    // Close the port

                    await port.close();
                    console.log("getInfo closed port")
                }

                // Open the port
                await port.open({ baudRate: 8600 });

                const writer = port.writable.getWriter();
                console.log("trying to disable log")
                const turnOffDebugCommand = new TextEncoder().encode(".log:0\r"); //turn off debugging for FMB devices
                await writer.write(turnOffDebugCommand);

                console.log("reading disable log response")
                await readFromPort(port, number);

                setError("");
                writer.releaseLock();

                return await sendInfoCommand(port, number);
            } catch (error) {
                console.log("getInfo error", error);
                setError("getInfo Failed to request device. Try again");
                console.error("getInfo No port selected by the user.");
            }
        }

        const loopOverPorts = async () => {
            let number = 1;
            const devices: DiscoveredDeviceData[] = [];
            for (const port of availablePorts) {
                //const discoveredDevice = await getInfo(port, number)
                const portInfo = port.getInfo();
console.log(portInfo)
                const portName = `${portInfo.usbVendorId}-${portInfo.usbProductId}`;
                const discoveredDevice = await (window as any).deviceCommands.recognizeDevice(portName);
                discoveredDevice && devices.push(discoveredDevice);
                discoveredDevice && setDiscoveredDevices(devices);
                number++;
            }
            setDiscoveredDevices(devices);
        }
        loopOverPorts();

    }, [availablePorts]);

    const [discoveredDevices, setDiscoveredDevices] = useState<DiscoveredDeviceData[]>([]);

    const selectDevice = async () => {
        try {
            const port = await navigator.serial.requestPort();
            setIsLoading(true);
            // Check if the port is already open
            if (port && port.readable && port.writable) {
                console.log("Port is already open");
                // Close the port

                await port.close();
            }

            // Open the port
            await port.open({ baudRate: 8600 });
            setPort(port);
            const writer = port.writable.getWriter();
            const turnOffDebugCommand = new TextEncoder().encode(".log:0\r"); //turn off debugging for FMB devices
            await writer.write(turnOffDebugCommand);
            setIsLoading(false);
            setError("");
            writer.releaseLock();
            getPorts();
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            setError("Failed to request device. Try again");
            console.error("No port selected by the user.");
        }
    };

    const sendInfoCommand = async () => {
        if (!oldPort || !oldPort.writable) {
            console.error("Port is not open");
            return;
        }

        if (reader) {
            await reader.cancel().catch(console.error);
            reader.releaseLock(); // Release the lock after canceling
            setReader(null); // Reset the reader in state
        }

        const writer = oldPort.writable.getWriter();
        const infoCommand = new TextEncoder().encode(
            ":cfg_info:?\rTMS :cfg_info:?\n"
        );
        await writer.write(infoCommand);
        writer.releaseLock();

        const newReader = oldPort.readable.getReader(); // Create a new reader
        setReader(newReader); // Store the new reader in state
        setReceivedData("");
        try {
            while (true) {
                const { value, done } = await newReader.read();

                if (done) {
                    // Reader has been canceled.
                    break;
                }
                // setReceivedData(new TextDecoder().decode(value))
                setReceivedData((prev) =>
                    prev.concat(new TextDecoder().decode(value))
                );
            }
        } catch (error) {
            console.error(`Error reading data: ${error}`);
        } finally {
            newReader.releaseLock();
        }
    };

    const renderDescription = () => {
        return (
            <Fragment>
                <div>
                    <h3>USB and Bluetooth View</h3>
                    <p>
                        This view is used to connect to a device port using USB
                        or Bluetooth.
                    </p>
                </div>
                <div>
                    <h4>Instructions</h4>
                    <p>
                        Click on the <strong>Select</strong> button to select a
                        device. Once the device is selected, you can click on
                        the <strong>Info</strong> button to send an info command
                        to the device.
                    </p>
                </div>
                <div>
                    <h4>Received Data</h4>
                    <p>{receivedData}</p>
                </div>
                <div>
                    <h4>Selected Port</h4>
                    <p>{oldPort ? "Port active" : "No port selected"}</p>
                </div>
                {error && (
                    <div>
                        <h4>Error</h4>
                        <p>{error}</p>
                    </div>
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Container
                className="remaining-height"
                css={css`
                    padding: 24px;

                    @media (max-width: 599px) {
                        padding: 24px 16px;
                    }
                `}
            >
                <div
                    css={css({
                        display: "flex",
                        gap: "15px",
                    })}
                >
                    <Button
                        size="small"
                        color="primary"
                        onClick={selectDevice}
                        idForTesting="deviceSetUpButton"
                        disabled={isLoading}
                        variant={"iconLeft"}
                        icon={
                            isLoading ? (
                                <CircularProgress
                                    size={23}
                                    css={css`
                                        color: white;
                                    `}
                                />
                            ) : null
                        }
                    >
                        Select
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={sendInfoCommand}
                        idForTesting="deviceSetUpButton"
                        disabled={isLoading}
                        variant={"textOnly"}
                    >
                        Info
                    </Button>
                </div>
                <div
                    css={css({
                        marginTop: "24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    })}
                >
                    {renderDescription()}
                </div>
            </Container>
            {<DiscoveredDevices devices={discoveredDevices}/>}
        </Fragment>
    );
};

export default UsbAndBluetoothView;
