import { useContext, useReducer } from "react";
import LanguageContext from "../../../../../../../context/language/languageContext";
import AlertContext from "../../../../../../../context/alert/alertContext";
import useApi from "../../../../../../../utils/useApi";
import changePinReducer from "./changePinReducer";
import {
    GO_TO_NEXT_VIEW,
    HANDLE_CURRENT_PIN,
    HANDLE_NEW_PIN,
    HANDLE_NEW_REPEATED_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "./changePinActions";
import { AlertStatus } from "../../../../../../../constants/constants";

interface ChangePinProps {
    simId: number;
    close: () => void;
}

interface OnSuccess {
    isSuccess: boolean;
    retriesLeft: number;
}

const useChangePin = ({ simId, close }: ChangePinProps) => {
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData, showErrorMsg } = useApi();

    const initialState = {
        isLoading: false,
        isDisabled: true,
        step: 1,
        currentPin: null,
        newPin: null,
        newRepeatedPin: null,
        explanatoryText: t.EnterCurrentCode,
        submitButtonText: t.Continue,
        errorMsg: null,
        retriesLeft: null,
    };

    const [state, dispatch] = useReducer(changePinReducer, initialState);

    const {
        isLoading,
        isDisabled,
        step,
        currentPin,
        newRepeatedPin,
        explanatoryText,
        submitButtonText,
        errorMsg,
        retriesLeft,
    } = state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handlePin = (value: string | null) => {
        switch (step) {
            case 1:
                dispatch({ type: HANDLE_CURRENT_PIN, payload: value });
                break;
            case 2:
                dispatch({ type: HANDLE_NEW_PIN, payload: value });
                break;
            default:
                dispatch({ type: HANDLE_NEW_REPEATED_PIN, payload: value });
        }
    };

    const onSuccessCheckCurrentPin = (response: OnSuccess) => {
        if (response.isSuccess) {
            dispatch({ type: GO_TO_NEXT_VIEW });
        } else {
            dispatch({
                type: WRONG_CURRENT_PIN,
                payload: response.retriesLeft,
            });
        }
    };

    const checkCurrentPin = () => currentPin && sendPayload(currentPin, currentPin, onSuccessCheckCurrentPin);

    const setAlertOnBothPinSent = (success: boolean) => {
        if (success) {
            setAlert(AlertStatus.Success, t.PinSuccessfullyChanged);
            close();
        } else {
            setAlert(AlertStatus.Critical, t.ErrorOccurredChangingPin);
        }
    };

    const sendPayloadOnBothPinSent = (onSuccess: (response: OnSuccess) => void) => {
        if (currentPin && newRepeatedPin) {
            sendPayload(currentPin, newRepeatedPin, onSuccess);
        }
    };

    const sendBothPins = () => {
        const onSuccess = (response: OnSuccess) => setAlertOnBothPinSent(response.isSuccess);
        sendPayloadOnBothPinSent(onSuccess);
    };

    const handleSubmitBtn = () => {
        switch (step) {
            case 1:
                checkCurrentPin();
                break;
            case 2:
                dispatch({ type: GO_TO_NEXT_VIEW });
                break;
            default:
                sendBothPins();
        }
    };

    const sendPayload = async (
        currentCode: string,
        newCode: string,
        successPayload: (response: OnSuccess) => void
    ) => {
        try {
            setLoading(true);

            const { data } = await postData(
                `${selectedLanguage}/command/changesimpin`,
                { simId: simId, currentPin: currentCode, newPin: newCode }
            );

            successPayload &&
                successPayload({
                    isSuccess: data.success,
                    retriesLeft: data.retriesLeft,
                });
        } catch (error) {
            showErrorMsg(error as any);
        }

        setLoading(false);
    };

    const deviceLocked = () => retriesLeft && retriesLeft + 1 <= 1;

    return {
        handlePin,
        onSuccessCheckCurrentPin,
        checkCurrentPin,
        setAlertOnBothPinSent,
        sendPayloadOnBothPinSent,
        setLoading,
        sendBothPins,
        handleSubmitBtn,
        sendPayload,
        deviceLocked,
        isLoading,
        isDisabled,
        step,
        explanatoryText,
        submitButtonText,
        errorMsg,
        retriesLeft,
    };
}

export default useChangePin;