import { css } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import { useAtom } from "jotai";
import { DeviceStatusAtom } from "../../../../context/webSockets/webSocketsState";
import { AlertData } from "../../../../utils/types";
import { DeviceStatusWindow } from "../../../../generatedTypes";
import { chooseValue } from "../../../../utils/helpers";

const useOnlineView = () => {

    const { colors: { textDark, gray700 } } = useContext(ThemeContext);

    const { layoutData } = useContext(LayoutContext);

    const showStatuses = chooseValue(layoutData?.isConfigurationSupported, true, false);
    const visibilitySettings = chooseValue(layoutData?.deviceStatusWindow, layoutData?.deviceStatusWindow, {} as DeviceStatusWindow);

    const [deviceStatus] = useAtom(DeviceStatusAtom);

    const getFilterAlerts = (): AlertData[] => {
        let filteredAlerts: AlertData[] = [];
        if (layoutData?.alerts && deviceStatus?.alertVisibilities) {
            filteredAlerts = layoutData.alerts
                .map((alert) => {
                    const alertCopy = structuredClone(alert);
                    const alertVisibility =
                        deviceStatus.alertVisibilities[alertCopy.key];
                    if (alertVisibility) {
                        alertCopy.visible = alertVisibility;
                    }
                    return alertCopy;
                })
                .filter((alert) => alert.visible);
        }

        return filteredAlerts;
    }

    const hasSimStates = () => !!deviceStatus &&
        deviceStatus.simSecurity &&
        deviceStatus.simSecurity.simStates &&
        !!deviceStatus.simSecurity.simStates.length;

    const showGnssInfo = () => showStatuses &&
        (deviceStatus?.gnssLocationInformation || deviceStatus?.gnssStatusInformation);

    const showDoutInfo = () => showStatuses &&
        visibilitySettings.isDoutSupported &&
        (chooseValue(deviceStatus?.doutInformation?.length, deviceStatus?.doutInformation?.length, 0) > 0);

    const showMaintenanceInfo = () => visibilitySettings.isCaptureLogSupported ||
        visibilitySettings.isDownloadLogDumpSupported ||
        visibilitySettings.accelerometerSupport;

    const keyBaseStyles = css({
        color: gray700,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
    });

    const valueBaseStyles = css({
        color: textDark,
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
        wordBreak: "break-all",
    });

    return {
        getFilterAlerts,
        hasSimStates,
        showGnssInfo,
        showDoutInfo,
        showMaintenanceInfo,
        keyBaseStyles,
        valueBaseStyles
    };
};

export default useOnlineView;