import React, { useContext } from "react";

import { ReactComponent as BluetoothMarkIcon } from "../../../../../assets/icons/bluetooth/BluetoothMark.svg";
import BluetoothModalsState from "../../../../../context/bluetoothModals/bluetoothModalsState";
import BluetoothViewContext from "../../../../../context/bluetoothView/bluetoothViewContext";
import { noop } from "../../../../../utils/helpers";
import Button from "../../../../MuiComponents/Button";

import AddDeviceManuallyModal from "./modals/AddDeviceManuallyModal";
import AddDeviceModal from "./modals/AddDeviceModal";
import ScenarioModal from "./modals/ScenarioModal";

export type LinkBox = {
    link: string;
    name: string;
    style: string;
    childElements: any[];
    searchPath: any;
};
export interface LinkBoxesProps {
    data: LinkBox;
}

const LinkBox = ({ data }: LinkBoxesProps) => {
    const {
        setAddDeviceModalOpen,
        bluetoothScenarioModalInfo,
        isAddDeviceManuallyModalOpen,
        isAddDeviceModalOpen,
    } = useContext(BluetoothViewContext);
    const handleButtonClick = (data: LinkBox) => {
        if (data.link === "Modal") {
            setAddDeviceModalOpen(true);
        } else {
            noop();
        }
    };

    const renderComponent = (data: LinkBox) => {
        if (data.style === "ButtonPrimary") {
            return (
                <Button
                    fullWidth
                    variant="iconLeft"
                    size="small"
                    color="primary"
                    onClick={() => handleButtonClick(data)}
                    idForTesting={data.name}
                    icon={<BluetoothMarkIcon />}
                >
                    {data.name}
                </Button>
            );
        }
    };
    return (
        <>
            <div>{renderComponent(data)}</div>
            {isAddDeviceModalOpen && <AddDeviceModal />}
            {isAddDeviceManuallyModalOpen && <AddDeviceManuallyModal />}
            {bluetoothScenarioModalInfo.isOpen && <ScenarioModal />}
        </>
    );
};
const LinkBoxes: React.FunctionComponent<LinkBoxesProps> = ({ data }) => {
    return (
        <BluetoothModalsState>
            <LinkBox data={data} />
        </BluetoothModalsState>
    );
};

export default LinkBoxes;
