/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { HeaderGroup } from "react-table";

import { css, jsx } from "@emotion/react";

import parse from "html-react-parser";

import InfoIcon from "../../../../../../../assets/icons/InfoIcon";
import SettingsContext from "../../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { ColumnLocalizedBase } from "../../../../../../../generatedTypes";
import Tooltip from "../../../../../../MuiComponents/Tooltip";

interface IoTableHeaderProps {
    headerGroups: HeaderGroup<any>[];
    headerRef: React.RefObject<HTMLDivElement>;
    ioColumnLocalization: ColumnLocalizedBase[];
}

const getColumnTooltip = (
    column: any,
    ioColumnLocalization: ColumnLocalizedBase[],
    blue700: string,
): React.ReactElement => {
    const removePrefix = (str: string): string => {
        if (column.isCanTable) {
            return str.replace("CanColumn_", "");
        }
        return str.replace("IoColumn_", "");
    };

    const tooltip = ioColumnLocalization.find(
        (item) =>
            removePrefix(item.localizationKey ?? "") === column.id &&
            removePrefix(item.localizationKey ?? "") !== "name" &&
            removePrefix(item.localizationKey ?? "") !== "currentValue",
    )?.tooltip;

    if (!tooltip) {
        return <Fragment>{null}</Fragment>;
    }

    return (
        <Tooltip title={<div>{parse(tooltip)}</div>} small placement="top">
            <span
                css={css({
                    alignItems: "center",
                    marginLeft: "5px",
                    display: "flex",
                    "& > svg": {
                        fontSize: "16px",
                        color: blue700,
                    },
                })}
            >
                <InfoIcon />
            </span>
        </Tooltip>
    );
};

export const IoTableHeader: React.FC<IoTableHeaderProps> = ({
    headerGroups,
    headerRef,
    ioColumnLocalization,
}) => {
    const {
        colors: { textDark, blue100, blue200, blue700 },
        boxShadows: { blueBoxShadow },
    } = useContext(ThemeContext);

    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    return (
        <div
            css={css({
                backgroundColor: blue100,
                boxShadow: blueBoxShadow,
                borderBottom: `1px solid ${blue200}`,
                borderTop: `1px solid ${blue200}`,
                display: "flex",
            })}
        >
            {headerGroups.map((headerGroup: HeaderGroup<any>) => (
                <div
                    {...headerGroup.getHeaderGroupProps()}
                    style={{
                        display: "flex",
                        width: "100%",
                        overflowX: "hidden",
                    }}
                    ref={headerRef}
                >
                    {headerGroup.headers.map((column, index) => (
                        <div
                            {...column.getHeaderProps()}
                            style={{
                                ...column.getHeaderProps().style,
                                color: textDark,
                                fontWeight: 600,
                                fontSize: "14px",
                                borderBottom: "unset",
                                position: index === 0 ? "sticky" : "unset",
                                left: index === 0 ? 0 : "unset",
                                backgroundColor: blue100,
                                zIndex: index === 0 ? 1 : 0,
                                display: "flex",
                                padding: "16px",
                            }}
                        >
                            {column.render("Header")}
                            {!isExplanatoryTextsHidden &&
                                getColumnTooltip(
                                    column,
                                    ioColumnLocalization,
                                    blue700,
                                )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default IoTableHeader;
