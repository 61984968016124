/** @jsxRuntime classic */

/** @jsx jsx */
import { ReactNode, useContext, useEffect, useRef, useState } from "react";

import { SerializedStyles, css, jsx } from "@emotion/react";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { MenuItem, Popover } from "@mui/material";

import LanguageContext from "../../../../../context/language/languageContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import { Component } from "../../../../../generatedTypes";
import { getIdTooltipText, noop } from "../../../../../utils/helpers";
import Tag from "../../../../MuiComponents/Tag";
import Tooltip from "../../../../MuiComponents/Tooltip";
import TooltipLarge from "../../../../MuiComponents/TooltipLarge";

export interface TableCollectionProps {
    data: Component;
    switchId?: string;
    onOptionClick?: (value: any) => void;
}
interface CollectionMenuItemProps {
    tooltipTitle?: string;
    tooltipButtonText?: string;
    tooltipOnBtnClick?: () => void;
    disabled: boolean;
    children: ReactNode;
    value: any;
    onClick?: any;
    css?: SerializedStyles;
}

const TableCollection = ({ data, onOptionClick }: TableCollectionProps) => {
    const {
        label,
        parameterId,
        parameterValue,
        sourceBindToList,
        disabledTooltip,
    } = data;
    const { findBindedList, listValues } = useContext(LayoutContext);
    const { parameters, updateParameter } = useContext(MenuItemContext);
    const {
        colors: { gray100, textDarkDisabled, blue700, red700 },
        boxShadows: { paperBoxShadow },
    } = useContext(ThemeContext);
    const { isParameterIdsHidden } = useContext(SettingsContext);
    const { t } = useContext(LanguageContext);

    const [isOpen, setIsOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null,
    );
    const [value, setValue] = useState<string>(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [requestError, setRequestError] = useState(false);
    const [listItems, setListItems] = useState(
        listValues[sourceBindToList || ""],
    );

    const elementRef = useRef(null);

    const paramValFromContext = parameters[parameterId];

    useEffect(() => {
        if (listValues[sourceBindToList || ""]) {
            setListItems([
                { value: t.NotSet, index: 0 },
                ...listValues[sourceBindToList || ""],
            ]);
        }
        // eslint-disable-next-line
    }, [listValues[sourceBindToList || ""]]);

    useEffect(() => {
        if (paramValFromContext) {
            setValue(paramValFromContext);
            setPreviousValue(paramValFromContext);
        }
        // eslint-disable-next-line
    }, [paramValFromContext]);

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const isCollectionDisabled = (): boolean => {
        if (
            findBindedList(sourceBindToList) &&
            !listValues[sourceBindToList || ""]?.length
        ) {
            return true;
        }
        return false;
    };

    const toggling = (e: any) => {
        setIsOpen(!isOpen);
        setMenuAnchorEl(e.currentTarget);
    };

    const onOptionClicked = (value: any) => {
        onOptionClick && onOptionClick(value);
        setValue(value);
        updateParameter(
            parameterId,
            value,
            label || "",
            elementRef,
            () => onSuccessfulRequest(value),
            onFailedRequest,
            () => noop,
            true,
            onCanceledRequest,
        );
        setIsOpen(false);
    };

    const renderBorderColor = () => {
        if (requestError) {
            return `1px solid ${red700}`;
        }
        if (isOpen) {
            return `1px solid ${blue700}`;
        }
        return "unset";
    };

    const CollectionMenuItem = ({
        tooltipTitle,
        tooltipButtonText,
        tooltipOnBtnClick,
        disabled,
        children,
        value,
        css,
        ...props
    }: CollectionMenuItemProps) => {
        return disabled ? (
            <TooltipLarge
                noMargin
                placement="top-start"
                title={tooltipTitle || ""}
                buttonText={tooltipButtonText || ""}
                onBtnClick={tooltipOnBtnClick || noop}
            >
                <span>
                    <MenuItem
                        disabled={disabled}
                        {...props}
                        onClick={() => onOptionClicked(value)}
                        component="div"
                    >
                        {children}
                    </MenuItem>
                </span>
            </TooltipLarge>
        ) : (
            <MenuItem {...props} onClick={() => onOptionClicked(value)}>
                {children}
            </MenuItem>
        );
    };

    const renderSelectValue = () => {
        return (
            listItems?.find((item: any) => item.index === Number(value))
                ?.value || "Select"
        );
    };

    return (
        <Tooltip
            title={
                isCollectionDisabled()
                    ? disabledTooltip?.title || "No SMS event number added"
                    : ""
            }
            small
            placement="top"
        >
            <span
                css={css({
                    display: "block",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                })}
            >
                <div
                    css={css({
                        pointerEvents: isCollectionDisabled() ? "none" : "auto",
                        backgroundColor: isCollectionDisabled()
                            ? gray100
                            : "unset",
                        color: isCollectionDisabled()
                            ? textDarkDisabled
                            : "unset",
                        width: "100%",
                        cursor: "pointer",
                        height: "100%",
                        fontWeight: 600,
                        fontSize: "14px",
                        display: "flex",
                    })}
                    data-test={String(parameterId)}
                >
                    <div
                        css={css({
                            padding: "14px 16px",
                            minWidth: "28px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxSizing: "border-box",
                            border: renderBorderColor(),
                        })}
                        onClick={toggling}
                    >
                        {renderSelectValue()}
                        <ExpandMoreIcon
                            css={css({
                                transform: isOpen ? "rotate(-180deg)" : "unset",
                            })}
                        />
                    </div>
                    <Popover
                        open={isOpen}
                        anchorEl={menuAnchorEl}
                        onClose={() => setIsOpen(false)}
                        anchorPosition={{ top: 0, left: 0 }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        PaperProps={{
                            style: {
                                maxWidth: "380px",
                                width: "100%",
                                boxShadow: paperBoxShadow,
                            },
                        }}
                    >
                        {listItems?.map((item: any) => {
                            if (item.value) {
                                return (
                                    <CollectionMenuItem
                                        key={item.index}
                                        value={item.index}
                                        disabled={false}
                                        onClick={() =>
                                            onOptionClicked(item.value)
                                        }
                                    >
                                        {item.value}
                                    </CollectionMenuItem>
                                );
                            }
                        })}
                    </Popover>
                </div>
                {parameterId && !isParameterIdsHidden ? (
                    <Tooltip
                        title={getIdTooltipText(parameterId, 0)}
                        small
                        placement="top"
                    >
                        <span
                            css={css({
                                position: "absolute",
                                right: "48px",
                                top: "22px",
                            })}
                        >
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                ) : null}
            </span>
        </Tooltip>
    );
};

export default TableCollection;
