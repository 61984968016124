/** @jsxRuntime classic */

/** @jsx jsx */
import React, { useContext } from "react";

import { css, jsx } from "@emotion/react";

import BluetoothViewContext from "../../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../../context/language/languageContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import LoadingBubbles from "../../../../MuiComponents/LoadingBubbles";

export interface BluetoothViewToolbarProps {}

const BluetoothViewToolbar: React.FunctionComponent<
    BluetoothViewToolbarProps
> = () => {
    const { bluetoothTotalCount } = useContext(BluetoothViewContext);
    const { layoutData } = useContext(LayoutContext);
    const { t } = useContext(LanguageContext);
    const { toMd } = useMediaQueries();

    return (
        <div
            style={{
                padding: toMd ? "16px 0px" : "20px 0px",
            }}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                    })}
                >
                    <span
                        css={css({
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                        })}
                    >
                        {t.BluetoothDevices} ({bluetoothTotalCount})
                    </span>
                    {layoutData?.isOnline && <LoadingBubbles />}
                </div>
                <div css={css({ display: "flex", gap: "25px" })}>
                    {/* <SearchInput
                        onChange={(e) => setTableSearchValue(e.target.value)}
                        resetValue={() => setTableSearchValue("")}
                        searchValue={searchValue}
                        disabled={false}
                    />
                    <StatusesSelect />
                    <ColumnSelect /> */}
                </div>
            </div>
        </div>
    );
};

export default BluetoothViewToolbar;
