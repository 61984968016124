/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { InputLabel, Popover } from "@mui/material";

import { IoTableColumns } from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import LanguageContext from "../../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../../context/layout/layoutContext";
import ThemeContext from "../../../../../../../../context/theme/themeContext";
import useMediaQuries from "../../../../../../../../utils/useMediaQueries";
import Checkbox from "../../../../../../../MuiComponents/Checkbox";
import MoreFilters from "../../../../../shared/MoreFilters";

import OperandSelect from "./OperandSelect";
import PrioritySelect from "./PrioritySelect";

const MoreFiltersSelect = () => {
    const { setActiveFilter, activeFilters, resetFilter } =
        useContext(InputOutputContext);

    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { layoutData } = useContext(LayoutContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null,
    );

    const { toLg } = useMediaQuries();

    const hasRowsWithCurrentValues = activeFilters.some(
        (item) => item === IoTableColumns.currentValue,
    );

    return (
        <Fragment>
            <InputLabel
                css={css({
                    position: "unset",
                    display: "inline-flex",
                    marginLeft: toLg ? "0px" : "16px",
                })}
                onClick={(e) => setMenuAnchorEl(e.currentTarget as any)}
            >
                <MoreFilters />
            </InputLabel>

            <Popover
                onClose={() => setMenuAnchorEl(null)}
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        padding: "16px",
                        maxWidth: "211px",
                        width: "100%",
                    },
                }}
            >
                <div
                    css={css({
                        marginBottom: "16px",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        color: gray700,
                    })}
                >
                    {t.Filters}
                </div>

                <PrioritySelect />

                <OperandSelect />

                {layoutData?.isOnline && (
                    <div
                        css={css({
                            marginTop: "12px",
                        })}
                    >
                        <Checkbox
                            checked={hasRowsWithCurrentValues}
                            label={t.CurrentValuesFilter}
                            onChange={() =>
                                hasRowsWithCurrentValues
                                    ? resetFilter(IoTableColumns.currentValue)
                                    : setActiveFilter(
                                          IoTableColumns.currentValue,
                                      )
                            }
                        />
                    </div>
                )}
            </Popover>
        </Fragment>
    );
};

export default MoreFiltersSelect;
