/** @jsxRuntime classic */

/** @jsx jsx */
import React, { useContext, useEffect, useRef } from "react";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, VariableSizeList } from "react-window";

import { css, jsx } from "@emotion/react";

import {
    IoColumnStrings,
    IoTableColumns,
} from "../../../../../../constants/constants";
import InputOutputContext from "../../../../../../context/inputOutput/inputOutputContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { ColumnLocalizedBase } from "../../../../../../generatedTypes";
import useMediaQueries from "../../../../../../utils/useMediaQueries";

import { IoTableHeader } from "./components/IoTableHeader";
import MemoizedRow from "./components/MemoizedRow";
import VirtualizedMobileCard from "./components/VirtualizedMobileCard";
import { useIoTable } from "./hooks/useIoTable";
import { useIoTableEffects } from "./hooks/useIoTableEffects";

interface IoTableProps {
    name: string;
    data: any[];
    isFirstTable: boolean;
    isCanTable?: boolean;
    ioColumnLocalization: ColumnLocalizedBase[];
}

const BASE_HEIGHTS = {
    header: 52, // Header section with name
    description: 80, // Description section
    currentValue: 70, // Current value section if present
    standardRow: 76, // Standard row height for most fields
    marginBottom: 8, // Margin between cards
};

const DESKTOP_ROW_HEIGHT = 56;

const IoTable: React.FC<IoTableProps> = ({
    name,
    data,
    isFirstTable,
    isCanTable = false,
    ioColumnLocalization,
}) => {
    const { colors, boxShadows } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);
    const { activeColumns } = useContext(InputOutputContext);
    const { layoutData } = useContext(LayoutContext);

    const listRef = useRef<any>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const { tableInstance, currentValues } = useIoTable({
        name,
        data,
        isCanTable,
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        setAllFilters,
        setGlobalFilter,
    } = tableInstance;

    useIoTableEffects({
        data,
        setHiddenColumns,
        setAllFilters,
        setGlobalFilter,
        isCanTable,
    });

    const { toMd } = useMediaQueries();

    useEffect(() => {
        let ticking = false;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    if (listRef.current?._outerRef) {
                        const headerElement = headerRef.current;
                        const listElement = listRef.current._outerRef;
                        if (headerElement) {
                            headerElement.scrollLeft = listElement.scrollLeft;
                        }
                    }
                    ticking = false;
                });
                ticking = true;
            }
        };

        const outerRef = listRef.current?._outerRef;
        if (outerRef) {
            outerRef.addEventListener("scroll", handleScroll);
            return () => {
                outerRef.removeEventListener("scroll", handleScroll);
            };
        }
    }, [listRef.current, headerRef.current]);

    const getItemHeight = (
        index: number,
        { rows, activeColumns, hasCurrentValue }: any,
    ) => {
        const row = rows[index];
        let height =
            BASE_HEIGHTS.header +
            BASE_HEIGHTS.description +
            BASE_HEIGHTS.marginBottom;

        if (hasCurrentValue) {
            height += BASE_HEIGHTS.currentValue;
        }

        activeColumns.forEach((column: keyof IoColumnStrings) => {
            const hasData = Boolean(row.original[IoTableColumns[column]]);
            if (hasData) {
                height += BASE_HEIGHTS.standardRow;
            }
        });

        return height;
    };

    if (toMd) {
        return (
            <div
                css={css({
                    marginBottom: "72px",
                    height: "80vh",
                    "& > div > div": {
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        scrollbarWidth: "none", // Firefox
                        msOverflowStyle: "none", // IE and Edge
                        overflow: "scroll",
                    },
                })}
            >
                <AutoSizer>
                    {({ height, width }: any) => (
                        <VariableSizeList
                            height={height}
                            itemCount={rows.length}
                            itemSize={(index) =>
                                getItemHeight(index, {
                                    rows,
                                    activeColumns,
                                    hasCurrentValue: layoutData?.isOnline,
                                })
                            }
                            width={width}
                            itemData={{
                                rows,
                                prepareRow,
                                currentValues,
                            }}
                        >
                            {VirtualizedMobileCard}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </div>
        );
    }

    return (
        <div css={css({ maxWidth: "100%", marginBottom: "56px" })}>
            <div
                css={css({
                    margin: isFirstTable ? "unset" : "10px 0px",
                    filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                    backgroundColor: colors.white,
                })}
                {...getTableProps()}
            >
                <IoTableHeader
                    headerGroups={headerGroups}
                    headerRef={headerRef}
                    ioColumnLocalization={ioColumnLocalization}
                />

                <div {...getTableBodyProps()}>
                    <div
                        style={{
                            height: "65vh",
                            width: "100%",
                        }}
                    >
                        <AutoSizer>
                            {({ height, width }: any) => (
                                <FixedSizeList
                                    ref={listRef}
                                    height={height}
                                    itemCount={rows.length}
                                    itemSize={DESKTOP_ROW_HEIGHT}
                                    width={width}
                                    itemData={{
                                        rows,
                                        prepareRow,
                                        colors,
                                        boxShadows,
                                        isExplanatoryTextsHidden,
                                    }}
                                >
                                    {MemoizedRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IoTable;
