/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import { Stack } from "@mui/material";

import LanguageContext from "../../context/language/languageContext";
import ThemeContext from "../../context/theme/themeContext";

import Button from "./Button";
import Tag from "./Tag";

export interface FilteredByBlockProps {
    filteredTagsNameAndValue: { name: string; value: string | number }[];
    onChipDelete: (value: string | number) => void;
    onClearAll: () => void;
    additionalStyles?: any;
}

const FilteredByBlock: React.FunctionComponent<FilteredByBlockProps> = ({
    filteredTagsNameAndValue,
    onChipDelete,
    onClearAll,
    additionalStyles,
}) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "stretch",
                padding: "0 24px 8px 24px",

                "@media (max-width: 839px)": {
                    padding: "0 0 16px 0",
                },
                ...additionalStyles,
            })}
        >
            <p
                css={css({
                    color: textDark,
                    lineHeight: "20px",
                    padding: "6px 0",
                    letterSpacing: "0.1px",
                    marginRight: "16px",
                    whiteSpace: "nowrap",
                })}
            >
                {t.FilteredBy}:
            </p>

            <Stack
                direction="row"
                css={css({
                    flexWrap: "wrap",
                    margin: "-6px 16px 0 -6px",

                    "& > div": {
                        marginLeft: "6px",
                        marginTop: "6px",
                        padding: "6px 8px",
                        borderRadius: "4px",
                    },
                })}
            >
                {filteredTagsNameAndValue.map(
                    (item: { name: string; value: string | number }) => (
                        <Tag
                            key={item.value}
                            size="medium"
                            color="primary"
                            onDelete={() => onChipDelete(item.value)}
                            title={item.name}
                        />
                    ),
                )}
            </Stack>

            <Button
                variant="textOnly"
                size="small"
                color="white"
                onClick={onClearAll}
                css={css({
                    alignSelf: "flex-start",
                    whiteSpace: "nowrap",
                    marginTop: "-2px",
                })}
                idForTesting="clear-all-btn"
            >
                {t.ClearAll}
            </Button>
        </div>
    );
};

export default FilteredByBlock;
