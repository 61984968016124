/** @jsxRuntime classic */

/** @jsx jsx */
import React, { ReactNode, useContext } from "react";

import { css, jsx } from "@emotion/react";

import TooltipMui, {
    TooltipProps as MuiTooltipProps,
    tooltipClasses,
} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import parse from "html-react-parser";

import ThemeContext from "../../context/theme/themeContext";
import useMediaQuries from "../../utils/useMediaQueries";

export interface TooltipProps extends MuiTooltipProps {
    small?: boolean;
    theme?: any;
    keepListenerEnabled?: boolean;
    title: string | ReactNode;
}

const Tooltip = styled(
    ({ small, keepListenerEnabled, className, ...props }: TooltipProps) => {
        const {
            colors: { blue400 },
        } = useContext(ThemeContext);

        const { toMd } = useMediaQuries();

        const disableListeners = keepListenerEnabled
            ? !keepListenerEnabled
            : toMd;

        const renderTitle = () => {
            if (!props.title) return "";

            // If title is already a React component, render it directly
            if (React.isValidElement(props.title)) {
                return (
                    <div
                        css={css({
                            maxHeight: 360,
                            overflow: "auto",
                            paddingRight: 4,
                            "&::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "rgba(255, 255, 255, 0.6)",
                                borderRadius: "100px",
                                border: "unset",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: blue400,
                            },
                        })}
                    >
                        {props.title}
                    </div>
                );
            }

            return (
                <div
                    css={css({
                        maxHeight: 360,
                        overflow: "auto",
                        paddingRight: 4,
                        "&::-webkit-scrollbar": {
                            width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "rgba(255, 255, 255, 0.6)",
                            borderRadius: "100px",
                            border: "unset",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: blue400,
                        },
                        "& p": {
                            margin: "0 0 8px 0",
                            "&:last-child": {
                                marginBottom: 0,
                            },
                        },
                    })}
                >
                    {parse(typeof props.title === "string" ? props.title : "")}
                </div>
            );
        };

        return (
            <TooltipMui
                {...props}
                title={renderTitle()}
                arrow={!small}
                classes={{ popper: className }}
                disableFocusListener={disableListeners}
                disableHoverListener={disableListeners}
                disableTouchListener={false}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
            />
        );
    },
)(({ theme, small, placement }) => {
    const getArrowPosition = () => {
        switch (placement) {
            case "top-start":
            case "bottom-start":
                return {
                    transform: "unset !important",
                    left: "12px !important",
                    right: "unset !important",
                };
            case "top-end":
            case "bottom-end":
                return {
                    transform: "unset !important",
                    left: "unset !important",
                    right: "12px !important",
                };
            case "left-start":
            case "right-start":
                return {
                    transform: "unset !important",
                    top: "12px !important",
                    bottom: "unset !important",
                };
            case "left-end":
            case "right-end":
                return {
                    transform: "unset !important",
                    top: "unset !important",
                    bottom: "12px !important",
                };
            default:
                return {};
        }
    };

    const arrowPosition = getArrowPosition();

    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.gray700,
            fontSize: small ? "12px" : "14px",
            lineHeight: small ? "16px" : "20px",
            letterSpacing: small ? "0.4px" : "0.1px",
            borderRadius: "8px",
            padding: small ? "8px 16px" : "12px 24px",
            maxWidth: "282px",
            whiteSpace: "pre-line",
        },
        [`& .${tooltipClasses.arrow}`]: {
            width: "0",
            height: "0",
            ...arrowPosition,
        },
        [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
            marginBottom: "4px !important",
        },
        [`&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
            marginTop: "4px !important",
        },
        [`&[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
            marginRight: "4px !important",
        },
        [`&[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
            marginLeft: "4px !important",
        },
        [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: `4px solid ${theme.palette.gray700}`,
            marginBottom: "-4px",
        },
        [`&[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: `4px solid ${theme.palette.gray700}`,
            marginTop: "-4px",
        },
        [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
            borderTop: "6px solid transparent",
            borderBottom: "6px solid transparent",
            borderLeft: `4px solid ${theme.palette.gray700}`,
            marginRight: "-4px",
            width: "0",
            height: "0",
        },
        [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
            borderTop: "6px solid transparent",
            borderBottom: "6px solid transparent",
            borderRight: `4px solid ${theme.palette.gray700}`,
            marginLeft: "-4px",
            width: "0",
            height: "0",
        },
    };
});

export default Tooltip;
