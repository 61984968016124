/** @jsxRuntime classic */

/** @jsx jsx */
import { Row, UseTableRowProps } from "react-table";

import { jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import DataMask from "../components/DataMask";
import DescriptionWithSwitch from "../components/DescriptionWithSwitch";
import TableCollection from "../components/TableCollection";
import TableInput from "../components/TableInput";

export const getCanColumns = (name: string, data: any) => {
    return [
        {
            id: IoTableColumns.name,
            accessor: IoTableColumns.name,
            Header: name,
            width: 470,
            show: true,
            filter: (allRows: Row<any>[], _: any[], filterValue: string) => {
                return allRows.filter((row: any) => {
                    if (row.state?.cellState?.disabled !== undefined) {
                        const disabledValue = row.state?.cellState?.disabled
                            ? "0"
                            : "1";
                        return disabledValue === filterValue;
                    }
                    return (
                        row.original.parameterValue?.switchValue === filterValue
                    );
                });
            },
            Cell: ({ row }: any) => {
                return (
                    <DescriptionWithSwitch
                        row={row}
                        onChange={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state.cellState,
                                        disabled: !e,
                                    },
                                };
                            })
                        }
                    />
                );
            },
        },
        {
            id: IoTableColumns.priority,
            accessor: IoTableColumns.priority,
            Header: IoTableColumnTitles.priority,
            show: data?.some((item: any) => item.priority),
            Cell: ({ row }: any) => {
                return row.original.priority ? (
                    <TableCollection
                        data={row.original.priority}
                        switchId={row.original.parameterValue.id}
                        key={row.original.parameterValue.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        priorityItem: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },
        {
            id: IoTableColumns.canSrc,
            accessor: IoTableColumns.canSrc,
            Header: IoTableColumnTitles.canSrc,
            show: data?.some((item: any) => item.canSrc),
            Cell: ({ row }: any) => {
                return row.original.canSrc ? (
                    <TableCollection
                        data={row.original.canSrc}
                        switchId={row.original.parameterValue.id}
                        key={row.original.canSrc.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        canSrc: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },
        {
            id: IoTableColumns.canType,
            accessor: IoTableColumns.canType,
            Header: IoTableColumnTitles.canType,
            show: data?.some((item: any) => item.canType),
            Cell: ({ row }: any) => {
                return row.original.canType ? (
                    <TableCollection
                        data={row.original.canType}
                        switchId={row.original.parameterValue.id}
                        key={row.original.canType.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        canType: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },
        {
            id: IoTableColumns.canId,
            accessor: IoTableColumns.canId,
            Header: IoTableColumnTitles.canId,
            show: true,
            Cell: ({ row }: any) => {
                return row.original.canId ? (
                    <TableInput
                        switchId={row.original.parameterValue.id}
                        data={row.original.canId}
                        key={row.original.parameterValue.id}
                        inputType="text"
                    />
                ) : null;
            },
        },
        {
            id: IoTableColumns.dataMask,
            accessor: IoTableColumns.dataMask,
            Header: IoTableColumnTitles.dataMask,
            show: data?.some((item: any) => item.dataMask),
            minWidth: 340,
            width: "100%",
            maxWidth: 340,
            Cell: ({ row }: any) => {
                return row.original.dataMask ? (
                    <DataMask
                        data={row.original.dataMask}
                        switchId={row.original.parameterValue.id}
                    />
                ) : null;
            },
        },
        {
            id: IoTableColumns.operand,
            accessor: IoTableColumns.operand,
            Header: IoTableColumnTitles.operand,
            show: true,
            filter: (
                allRows: UseTableRowProps<any>[],
                _: any[],
                filterValue: any,
            ) => {
                return allRows.filter((row: any) => {
                    if (row.state?.cellState?.operandType !== undefined) {
                        return filterValue.some(
                            (value: string) =>
                                value === row.state?.cellState?.operandType,
                        );
                    }
                    return filterValue.some(
                        (value: string) =>
                            value === row.original.operand.parameterValue,
                    );
                });
            },
            Cell: ({ row }: any) => {
                return row.original.operand ? (
                    <TableCollection
                        data={row.original.operand}
                        switchId={row.original.parameterValue.id}
                        key={row.original.parameterValue.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        operandType: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },
    ].filter((item) => item.show);
};

export const getCanFilters = (activeOperand: any[], activeStatus: string) => [
    {
        id: IoTableColumns.operand,
        value: activeOperand.length ? activeOperand : undefined,
    },
    {
        id: IoTableColumns.name,
        value: activeStatus !== "2" ? activeStatus : undefined,
    },
];
