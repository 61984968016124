import { useContext, useEffect } from "react";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";

const useFieldValidation = (
    value: string,
    data: any,
    paramValFromContext: any,
    hasBeenMounted: React.MutableRefObject<boolean>,
    handleChange: (e: any) => void
) => {
    const { parameterId, validate, validationMessage } = data;

    const { fieldValidations } = useContext(MenuItemContext);

    const checkIfValidationIsSaved = (paramId: number | undefined) => {
        if (fieldValidations && fieldValidations.length > 0) {
            const foundObject =
                fieldValidations.find((item) => item.parameterId === paramId) ||
                undefined;

            return foundObject;
        }

        return undefined;
    };

    const validationObj = checkIfValidationIsSaved(parameterId);

    const validateValue = validationObj?.validate ?? validate;
    const validationMessageValue =
        validationObj?.translatedValidationMessage ?? validationMessage;

    useEffect(() => {
        const valueFromInput = value ? value : paramValFromContext;

        if (valueFromInput && hasBeenMounted.current) {
            handleChange({ target: { value: valueFromInput } });
        } else {
            hasBeenMounted.current = true;
        }

        // eslint-disable-next-line
    }, [validateValue]);

    return {
        validateValue,
        validationMessageValue,
    };
};

export default useFieldValidation;
