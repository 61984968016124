/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, forwardRef, useContext } from "react";

import { SerializedStyles, css, jsx } from "@emotion/react";

import ErrorIcon from "@mui/icons-material/ErrorRounded";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import ThemeContext from "../../context/theme/themeContext";
import { Modify } from "../../utils/types";

import Label from "./Label";

type Ref = HTMLDivElement;

type InputFieldSizes = "small" | "medium";

interface InputFieldProps {
    size: InputFieldSizes;
    label?: string;
    rightLabel?: string | null;
    requesting?: boolean;
    requestFailed?: boolean;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    readOnly?: boolean;
    error?: string | null;
    info?: string;
    wrapperStyle?: SerializedStyles;
    required?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
    type?: string;
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    borderRadius?: string;
    otherInputProps?: any;
    deviceGuideId?: string;
    stacked?: boolean;
    hasNoTopBorder?: boolean;
    hasNoBottomBorder?: boolean;
    onInfoIconClick?: () => void;
    additionalContent?: React.ReactNode;
    otherInputSx?: any;
    additionalInputWrapperStyles?: any;
    id?: string;
    multiline?: boolean;
    maxRows?: number;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    withoutTooltipPadding?: boolean;
}

const InputField = forwardRef<Ref, Modify<TextFieldProps, InputFieldProps>>(
    (
        {
            size,
            label,
            rightLabel,
            requesting,
            requestFailed,
            iconLeft,
            iconRight,
            readOnly,
            error,
            info,
            wrapperStyle,
            required,
            borderRadius,
            otherInputProps,
            deviceGuideId,
            stacked,
            hasNoTopBorder,
            hasNoBottomBorder,
            onInfoIconClick,
            additionalContent,
            otherInputSx,
            additionalInputWrapperStyles,
            id,
            multiline,
            maxRows,
            onBlur,
            withoutTooltipPadding,
            ...props
        },
        ref,
    ) => {
        const {
            colors: {
                white,
                gray100,
                textDark,
                textDarkDisabled,
                textPlaceholder,
                red700,
            },
        } = useContext(ThemeContext);

        return (
            <div ref={ref} css={wrapperStyle} id={deviceGuideId}>
                {label && (
                    <Label
                        title={label}
                        rightTitle={rightLabel}
                        requesting={requesting}
                        requestFailed={requestFailed}
                        required={required}
                        info={info}
                        onInfoIconClick={onInfoIconClick}
                        withoutTooltipPadding={withoutTooltipPadding}
                    />
                )}
                <div
                    css={css({
                        width: "100%",
                        display: "flex",
                        gap: "16px",
                        ...additionalInputWrapperStyles,
                    })}
                >
                    <TextField
                        {...props}
                        error={Boolean(error)}
                        onBlur={onBlur}
                        data-test={String(id)}
                        autoComplete="off"
                        helperText={
                            !stacked && error ? (
                                <Fragment>
                                    <ErrorIcon
                                        css={css`
                                            color: ${red700};
                                            font-size: 16px;
                                            margin-right: 4px;
                                        `}
                                    />
                                    {error}
                                </Fragment>
                            ) : (
                                ""
                            )
                        }
                        InputProps={{
                            ...otherInputProps,
                            spellCheck: "false",
                            readOnly,
                            ...(iconLeft && {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {iconLeft}
                                    </InputAdornment>
                                ),
                            }),
                            ...(iconRight && {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {iconRight}
                                    </InputAdornment>
                                ),
                            }),
                            sx: {
                                background: readOnly ? gray100 : white,
                                color: textDark,
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                padding: size === "small" ? "8px 12px" : "12px",
                                borderRadius: borderRadius || "6px",
                                borderTop: hasNoTopBorder ? "0px" : "inherit",
                                borderBottom: hasNoBottomBorder
                                    ? "0px"
                                    : "inherit",
                                ...otherInputSx,
                                "& .MuiTextField-root": {
                                    width: "70%",
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: "unset",
                                    padding: "0",
                                    border: 0,
                                    "&.Mui-disabled": {
                                        color: textDarkDisabled,
                                        WebkitTextFillColor: textDarkDisabled,
                                    },

                                    "&::placeholder": {
                                        color: textPlaceholder,
                                        lineHeight: "20px",
                                        opacity: "1",
                                    },
                                },
                            },
                        }}
                        multiline={multiline}
                        maxRows={maxRows}
                    />
                    {additionalContent}
                </div>
            </div>
        );
    },
);

export default InputField;
