/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { noop, preventENotation } from "../../../../../../../utils/helpers";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import useMediaQueries from "../../../../../../../utils/useMediaQueries";
import { Component } from "../../../../../../../generatedTypes";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import useDebounce from "../../../../../../../utils/useDebounce";
import {
    validateNumberLength,
    validateTextLengthAndRegex,
} from "../../../../../../../utils/validations";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import useFieldValidation from "../../../../shared/useFieldValidation";
import { TableSwitchDefaultValue } from "./TableSwitchInput";

export interface TableInputProps {
    data: Component;
    switchId?: string | number;
    inputType?: string;
    onFocus?: (isFocused: boolean) => void;
    hasNoInputFocus?: boolean;
    hasNoInputDisabledBackground?: boolean;
    onError?: (error: string) => void;
    hasNoInputErrorBorder?: boolean;
}

const TableInput = ({
    data,
    switchId,
    inputType,
    onFocus,
    hasNoInputFocus,
    hasNoInputDisabledBackground,
    onError,
    hasNoInputErrorBorder,
}: TableInputProps) => {
    const {
        label = "",
        parameterId,
        placeholder,
        parameterValue,
        min = 0,
        max = 10,
        increment,
        minId,
        maxId,
        textMinLength,
        textMaxLength,
        validate,
        validationMessage,
    } = data;

    const { t } = useContext(LanguageContext);

    const {
        colors: {
            blue700,
            textDarkDisabled,
            textDark,
            textPlaceholder,
            gray100,
            red700,
        },
        boxShadows: { focusBoxShadow },
    } = useContext(ThemeContext);

    const { updateParameter, findParameterValueById, setParameterError } =
        useContext(MenuItemContext);

    const dependsOnOtherComponentMinVal = minId
        ? Number(findParameterValueById(minId))
        : min;

    const dependsOnOtherComponentMaxVal = maxId
        ? Number(findParameterValueById(maxId))
        : max;

    const debouncedChangeHandler = useDebounce(updateParameter, 500);
    const hasBeenMounted = useRef<boolean>(false);

    const [value, setValue] = useState(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);

    const elementRef = useRef(null);

    const { fromMd } = useMediaQueries();

    const paramValFromContext = findParameterValueById(parameterId);

    const handleChange = (e: any) => {
        const newValue = e.target.value;
        const errorValue = validateInput(newValue);
        setValidationError(null);
        setValue(String(newValue));

        if (errorValue) {
            onError && onError(errorValue);
            setValidationError(errorValue);
            setLoading(false);
            debouncedChangeHandler.cancel();
            return;
        }
        onError && onError("");
        setLoading(true);

        debouncedChangeHandler(
            parameterId,
            newValue,
            label,
            elementRef,
            () => onSuccessfulRequest(newValue),
            onFailedRequest,
            () => setLoading(false),
            true,
            onCanceledRequest
        );
    };

    const { validateValue, validationMessageValue } = useFieldValidation(
        value,
        { parameterId, validate, validationMessage },
        paramValFromContext,
        hasBeenMounted,
        handleChange
    );

    const validateInput = (newValue: string) => {
        return inputType === "text"
            ? validateTextLengthAndRegex(
                  newValue,
                  textMinLength,
                  textMaxLength,
                  validateValue || "",
                  validationMessageValue || "",
                  t
              )
            : validateNumberLength(
                  newValue,
                  dependsOnOtherComponentMinVal,
                  dependsOnOtherComponentMaxVal,
                  t
              );
    };

    useEffect(() => {
        const error = validateInput(paramValFromContext);
        setValidationError(error);

        setPreviousValue(paramValFromContext);
        setValue(paramValFromContext);

        // eslint-disable-next-line
    }, [paramValFromContext]);

    useEffect(() => {
        // Revalidate values if minId or maxId value changes
        if (minId && hasBeenMounted.current && value !== previousValue) {
            handleChange({ target: { value: value } });
        } else if (maxId && hasBeenMounted.current && value !== previousValue) {
            handleChange({ target: { value: value } });
        } else {
            hasBeenMounted.current = true;
        }
    }, [findParameterValueById(maxId), findParameterValueById(minId)]);

    useEffect(() => {
        if (hasBeenMounted.current) {
            setParameterError(parameterId, validationError || requestError);
        } else {
            hasBeenMounted.current = true;
        }
        // eslint-disable-next-line
    }, [validationError, requestError]);

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const isInputDisabled =
        findParameterValueById(switchId) === TableSwitchDefaultValue.OFF;

    const renderBorderColor = () => {
        if (hasNoInputErrorBorder) {
            return "none";
        }
        if (validationError || requestError) {
            return `1px solid ${red700}`;
        }
        return "none";
    };

    const tooltipTitle =
        validationError || inputType === "text"
            ? validationError || ""
            : `Min:${dependsOnOtherComponentMinVal} Max:${dependsOnOtherComponentMaxVal}`;

    return (
        <Tooltip title={tooltipTitle} small placement="top">
            <span
                css={css({
                    display: "block",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                })}
            >
                <input
                    data-test={String(parameterId)}
                    css={css({
                        height: "99%", //for borders to be visible
                        backgroundColor: "unset",
                        boxSizing: "border-box",
                        width: "99%",
                        padding: fromMd ? "0 16px" : "0px",
                        color: isInputDisabled ? textDarkDisabled : textDark,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        border: renderBorderColor(),
                        position: "relative",
                        "&:disabled": {
                            pointerEvents: isInputDisabled ? "none" : "auto",
                            backgroundColor:
                                isInputDisabled && !hasNoInputDisabledBackground
                                    ? gray100
                                    : "unset",
                            color: isInputDisabled ? textDarkDisabled : "unset",
                        },
                        "&:focus": {
                            outline: "none",
                            border: hasNoInputFocus
                                ? "unset"
                                : `1px solid ${blue700}`,
                            boxShadow: hasNoInputFocus
                                ? "unset"
                                : focusBoxShadow,
                        },
                        "&::placeholder": {
                            color: textPlaceholder,
                            lineHeight: "20px",
                            opacity: "1",
                        },
                    })}
                    ref={elementRef}
                    type={inputType || "number"}
                    placeholder={placeholder ? placeholder : ""}
                    value={value}
                    onChange={handleChange}
                    onFocus={() => onFocus && onFocus(true)}
                    onBlur={() => onFocus && onFocus(false)}
                    onKeyDown={inputType ? noop : preventENotation}
                    disabled={isInputDisabled}
                    min={dependsOnOtherComponentMinVal}
                    max={dependsOnOtherComponentMaxVal}
                    step={increment}
                />
                {loading && (
                    <CircularProgress
                        size={13}
                        css={css({
                            color: blue700,
                            position: "absolute",
                            right: "42px",
                            bottom: "42%",
                        })}
                    />
                )}
            </span>
        </Tooltip>
    );
};

export default TableInput;
