export const FILTER_COLUMNS_BY_NAME = "FILTER_COLUMNS_BY_NAME";
export const RESET_COLUMN = "RESET_COLUMN";
export const RESET_COLUMNS = "RESET_COLUMNS";
export const SET_BLUETOOTH_DATA = "SET_BLUETOOTH_DATA";
export const SET_BLUETOOTH_MODAL_TABLE = "SET_BLUETOOTH_MODAL_TABLE";
export const SET_BLUETOOTH_MODAL_PRESET = "SET_BLUETOOTH_MODAL_PRESET";
export const SET_SUBMIT_DISABLED = "SET_SUBMIT_DISABLED";
export const SET_ACTIVE_STATUS_FILTER = "SET_ACTIVE_STATUS_FILTER";
export const SET_TABLE_SEARCH_VALUE = "SET_TABLE_SEARCH_VALUE";
export const SET_BLUETOOTH_MODAL_NAME = "SET_BLUETOOTH_MODAL_NAME";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const SET_ADD_DEVICE_MODAL = "SET_ADD_DEVICE_MODAL";
export const SET_EDIT_DEVICE_MODAL = "SET_EDIT_DEVICE_MODAL";
export const SET_RELOAD_BLUETOOTH_VIEW = "SET_RELOAD_BLUETOOTH_VIEW";
export const RESET_BLUETOOTH_VIEW = "RESET_BLUETOOTH_VIEW";
export const SET_ADD_DEVICE_MANUALLY_MODAL = "SET_ADD_DEVICE_MANUALLY_MODAL";
export const SET_BLUETOOTH_SCENARIO_MODAL = "SET_ADD_SCENARIO_MODAL";
export const SET_BLUETOOTH_DEVICES = "SET_BLUETOOTH_DEVICES";
