/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect, useRef } from "react";

import { css, jsx } from "@emotion/react";

import { AlertStatus } from "../../../../constants/constants";
import FeaturesContext from "../../../../context/features/featuresContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import ThemeContext from "../../../../context/theme/themeContext";
import { Frame as FrameData } from "../../../../generatedTypes";
import { scrollToRef } from "../../../../utils/helpers";
import useGoogleAnalytics from "../../../../utils/useGoogleAnalytics";
import useMediaQueries from "../../../../utils/useMediaQueries";
import Alert from "../../../Alert";
import Box from "../../../Box";

import AccordionFrame from "./AccordionFrame";
import DefaultFrame from "./DefaultFrame";
import IoFrame from "./IoFrame";
import SmsEventTableFrame from "./SmsEventTableFrame";

interface FrameProps {
    data: FrameData;
}

const Frame = ({ data }: FrameProps) => {
    const {
        index,
        accordionProperties,
        ioContainerProperties,
        localizationKey,
    } = data;

    const isAccordionFrame = accordionProperties;
    const isIoFrame = ioContainerProperties;
    const isSmsEventTable = data.blocks.find((item) => item.smsEventList);

    const {
        colors: { white },
        boxShadows: { boxComponentShadow },
    } = useContext(ThemeContext);

    const { layoutLoading, activeFrameIndex, setActiveFrameIndex } =
        useContext(LayoutContext);

    const { resetDefaultParameterValues } = useContext(FeaturesContext);

    const { sendSelectedFrame } = useGoogleAnalytics();

    const { toMd } = useMediaQueries();

    const frameRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (index === activeFrameIndex && !layoutLoading) {
            scrollToRef(frameRef);
            setActiveFrameIndex(null);
        }
        // eslint-disable-next-line
    }, [activeFrameIndex, layoutLoading]);

    useEffect(() => {
        if (frameRef.current) {
            frameRef?.current?.addEventListener(
                "click",
                () => sendSelectedFrame(`${data.menuItemName}->${data.name}`),
                { once: true },
            );
        }
        return () => {
            if (localizationKey === "Trip") {
                resetDefaultParameterValues();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deviceGuideSelector = `frame${String(index)}`;

    const renderContent = () => {
        if (isSmsEventTable) {
            return (
                <Box
                    ref={frameRef}
                    css={css`
                        scroll-margin: 16px;
                    `}
                >
                    <SmsEventTableFrame data={data} />
                </Box>
            );
        }
        if (isAccordionFrame) {
            return (
                <Box
                    ref={frameRef}
                    css={css`
                        scroll-margin: 16px;
                        margin-bottom: 60px;
                    `}
                >
                    <AccordionFrame data={data} />
                </Box>
            );
        }

        if (isIoFrame) {
            return (
                <Box
                    ref={frameRef}
                    css={css({
                        scrollMargin: "16px",
                        backgroundColor: toMd ? "transparent" : white,
                        boxShadow: toMd ? "none" : boxComponentShadow,
                        borderRadius: "8px",
                    })}
                >
                    <IoFrame data={data} />
                </Box>
            );
        }
        return (
            <Fragment>
                {data?.isDisabled && (
                    <Alert
                        status={AlertStatus.Info}
                        title={data?.disabledTooltip?.title || ""}
                        description={data?.disabledTooltip?.description || ""}
                    />
                )}
                <Box
                    ref={frameRef}
                    css={css`
                        scroll-margin: 16px;
                        opacity: ${data?.isDisabled ? 0.6 : 1};
                        pointer-events: ${data?.isDisabled ? "none" : "unset"};
                    `}
                    id={deviceGuideSelector}
                >
                    <DefaultFrame data={data} />
                </Box>
            </Fragment>
        );
    };
    return <Fragment>{renderContent()}</Fragment>;
};

export default Frame;
