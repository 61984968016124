/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import AlertWarningIcon from "../../../assets/icons/AlertWarningIcon";
import AppTypeContext from "../../../context/appType/appTypeContext";
import LanguageContext from "../../../context/language/languageContext";
import LayoutContext from "../../../context/layout/layoutContext";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../context/settings/settingsContext";
import {
    decimalCount,
    getIdTooltipText,
    preventENotation,
} from "../../../utils/helpers";
import useDebounce from "../../../utils/useDebounce";
import useNavigation from "../../../utils/useNavigation";
import { validateNumberLengthAndRegex } from "../../../utils/validations";
import InputField from "../InputField";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import TooltipLarge from "../TooltipLarge";

export interface NumberInputProps {
    data: any;
    onChange?: (e: { id: number; value: string; error?: string }) => void;
    withoutLabelAndId?: boolean;
    isGeoInput?: boolean;
    otherInputSx?: any;
    stacked?: boolean;
    withoutLabel?: boolean;
    componentValue: string;
    dependentMaxValue: string;
    dependentMinValue: string;
    resetErrorOnUnmount?: boolean;
}

const NumberInput = ({
    data,
    onChange,
    withoutLabelAndId,
    isGeoInput,
    otherInputSx,
    stacked,
    withoutLabel,
    componentValue,
    dependentMaxValue,
    dependentMinValue,
    resetErrorOnUnmount,
}: NumberInputProps) => {
    const {
        label = "",
        parameterId,
        avlId,
        placeholder,
        isDisabled,
        min = 0,
        max = 0,
        increment,
        tooltip = "",
        disabledTooltip,
        validate,
        validationMessage,
        index,
        minId,
        maxId,
        condition,
    } = data;

    const { isWasm } = useContext(AppTypeContext);
    const {
        updateParameter,
        findDisabledParameterById,
        setParameterError,
        setDisabledParameters,
        errors,
    } = useContext(MenuItemContext);

    const { setDrawerParameterId } = useContext(LayoutContext);
    const { t } = useContext(LanguageContext);

    const { isParameterIdsHidden, setDeviceGuideButtonDisabled } =
        useContext(SettingsContext);

    const [value, setValue] = useState(componentValue);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [previousValue, setPreviousValue] = useState(componentValue);

    const { handleSearchNavigation } = useNavigation();
    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const elementRef = useRef(null);

    const hasBeenMounted = useRef<boolean>(false);

    const isNumberInputDisabled =
        findDisabledParameterById(parameterId) !== undefined
            ? findDisabledParameterById(parameterId)
            : isDisabled;

    useEffect(() => {
        if (hasBeenMounted.current) {
            setParameterError(parameterId, error);
        } else {
            hasBeenMounted.current = true;
        }

        return () => {
            resetErrorOnUnmount && setParameterError(parameterId, null);
        };
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        isGeoInput && setError(errors[parameterId]);
        // eslint-disable-next-line
    }, [errors[parameterId]]);

    useEffect(() => {
        if (minId && hasBeenMounted.current && value !== previousValue) {
            handleChange({ target: { value: value } });
        } else if (maxId && hasBeenMounted.current && value !== previousValue) {
            handleChange({ target: { value: value } });
        } else {
            hasBeenMounted.current = true;
        }
    }, [dependentMaxValue, dependentMinValue, value]);

    useEffect(() => {
        setValue(componentValue);
        setPreviousValue(componentValue);
        // eslint-disable-next-line
    }, [componentValue]);

    const dependenciesToDisable =
        data.dependentParameterIds &&
        data.dependentParameterIds.length > 0 &&
        data.dependentParameterIds.map((dependant: number) => {
            return {
                parameterId: dependant,
                isDisabled: true,
            };
        });

    const disableDependencies = () => {
        dependenciesToDisable && setDisabledParameters(dependenciesToDisable);
    };

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
        setDeviceGuideButtonDisabled(false);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setError(null);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const dependsOnOtherComponentMinVal = () => {
        if (minId && !min) {
            return Number(dependentMinValue);
        }
        if (minId && Number(dependentMinValue) > min) {
            return Number(dependentMinValue);
        }
        return undefined;
    };

    const dependsOnOtherComponentMaxVal = () => {
        if (maxId && !max) {
            return Number(dependentMaxValue);
        }
        if (maxId && Number(dependentMaxValue) < max) {
            return Number(dependentMaxValue);
        }
        return undefined;
    };

    const handleChange = (e: any) => {
        let newValue = e.target.value;
        if (decimalCount(increment) < decimalCount(e.target.value)) {
            newValue = Number(
                Number(e.target.value).toFixed(decimalCount(increment)),
            );
        }

        const errorValue = validateNumberLengthAndRegex(
            newValue,
            dependsOnOtherComponentMinVal() ?? min,
            dependsOnOtherComponentMaxVal() ?? max,
            validate,
            validationMessage,
            t,
        );

        setError(errorValue);
        setValue(String(newValue));

        if (onChange) {
            onChange({
                id: parameterId,
                value: newValue,
                error: errorValue ?? "",
            });
        } else if (errorValue) {
            setLoading(false);
            !isWasm && debouncedChangeHandler.cancel();
        } else {
            setLoading(true);

            disableDependencies();

            debouncedChangeHandler(
                parameterId,
                newValue,
                label,
                elementRef,
                () => onSuccessfulRequest(newValue),
                onFailedRequest,
                () => setLoading(false),
                true,
                onCanceledRequest,
            );
        }
    };

    const deviceGuideSelector = `component${String(index)}`;

    const renderRightIcon = () => {
        if (parameterId && !withoutLabelAndId && !isParameterIdsHidden) {
            return (
                <Fragment>
                    {error && stacked && !isNumberInputDisabled && (
                        <Tooltip title={error} small placement="top">
                            <span>
                                <AlertWarningIcon
                                    css={css({
                                        width: "18px",
                                        height: "18px",
                                        display: "flex",
                                        marginRight: "5px",
                                        cursor: "pointer",
                                    })}
                                />
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={getIdTooltipText(parameterId, avlId)}
                        small
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                </Fragment>
            );
        }
        return null;
    };

    if (condition === "Hidden") {
        return null;
    }

    return (
        <TooltipLarge
            title={
                isNumberInputDisabled && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            buttonText={disabledTooltip ? disabledTooltip.btnTitle : ""}
            onBtnClick={() => handleSearchNavigation(disabledTooltip)}
        >
            <span>
                <InputField
                    id={String(parameterId)}
                    deviceGuideId={deviceGuideSelector}
                    ref={elementRef}
                    fullWidth
                    size="medium"
                    label={withoutLabelAndId || withoutLabel ? "" : label}
                    iconRight={renderRightIcon()}
                    requesting={loading}
                    requestFailed={requestError}
                    info={tooltip}
                    disabled={isNumberInputDisabled}
                    placeholder={placeholder ?? ""}
                    type="number"
                    inputProps={{
                        min: dependsOnOtherComponentMinVal() ?? min,
                        max: dependsOnOtherComponentMaxVal() ?? max,
                        step: increment ?? 1,
                    }}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={preventENotation}
                    error={!isNumberInputDisabled ? error : ""}
                    wrapperStyle={css`
                        scroll-margin: 16px;
                    `}
                    onInfoIconClick={() => setDrawerParameterId(parameterId)}
                    otherInputSx={otherInputSx}
                    stacked={stacked}
                />
            </span>
        </TooltipLarge>
    );
};

export default NumberInput;
