/** @jsxRuntime classic */

/** @jsx jsx */
import { SyntheticEvent, useContext } from "react";

import { css, jsx } from "@emotion/react";

import { NameValueStringsPair } from "../../../../../constants/constants";
import ConfigurationContext from "../../../../../context/configuration/configurationContext";
import LanguageContext from "../../../../../context/language/languageContext";
import FilterDropdown from "../../../../MuiComponents/FilterDropdown";

export interface FilterProps {
    changePage: (
        event: SyntheticEvent<Element, Event>,
        newPage: number,
    ) => void;
}

const Filter = ({ changePage }: FilterProps) => {
    const { t } = useContext(LanguageContext);

    const { filteredValues, technologies, filterConfigurations } =
        useContext(ConfigurationContext);

    const handleChange = (
        event: SyntheticEvent<Element, Event>,
        newValues: NameValueStringsPair[],
    ) => {
        const convertedValues = newValues.map((entry) => entry.value);

        filterConfigurations(convertedValues);
        changePage(event, 0);
    };

    const convertedTechnologies: NameValueStringsPair[] = technologies.map(
        (entry) => ({
            name: entry.technology,
            value: entry.technology,
        }),
    );

    const currentValues = filteredValues.map((filteredValue: string) => {
        return convertedTechnologies.find(
            (convertedTech) => convertedTech.value === filteredValue,
        )!;
    });

    return (
        <FilterDropdown
            checkbox
            currentValue={currentValues}
            placeholder={t.AllTechnologies}
            options={convertedTechnologies}
            handleChange={handleChange}
            isDisabled={false}
            customCSS={css({
                margin: "0 24px",
                width: "212px",

                "@media (max-width: 1439px)": {
                    width: "180px",
                },

                "@media (max-width: 839px)": {
                    flex: "1",
                    margin: "0 8px 0 0",
                },
            })}
        />
    );
};

export default Filter;
