/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css } from "@emotion/react";

import LanguageContext from "../../../../../context/language/languageContext";
import ThemeContext from "../../../../../context/theme/themeContext";

import { ConvertedCellValue } from "./ScheduleTable/types";

const useCellStylingAndTooltips = () => {
    const { t } = useContext(LanguageContext);

    const {
        colors: { textSecondary },
    } = useContext(ThemeContext);

    const renderRowIds = (rowValues: ConvertedCellValue[]) => {
        const valuesString = rowValues
            .filter((cell) => cell.parameterId !== 0)
            .map((cell) => {
                const { switchId, parameterId, label } = cell;

                const displayTitle = switchId ? t.RecordsPerDay : label;
                const IdValue = switchId !== 0 ? switchId : parameterId;

                return `${displayTitle}:<br> ID: ${IdValue}`;
            })
            .join("<hr>");
        return valuesString;
    };

    const getReadOnlyTableCellStyle = (
        colIndex: number,
        nextCellIsEmpty: boolean,
        isSwitchIdCase: boolean = false,
    ) =>
        css({
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: textSecondary,
            textAlign: !isSwitchIdCase ? "center" : undefined,
            paddingRight: colIndex === 6 ? 0 : "6px",
            borderRight:
                !isSwitchIdCase && !nextCellIsEmpty
                    ? `2px solid ${textSecondary}`
                    : 0,
            minWidth: isSwitchIdCase ? "149px" : undefined,
        });

    return {
        renderRowIds,
        getReadOnlyTableCellStyle,
    };
};

export default useCellStylingAndTooltips;
