import { createContext } from "react";

import { FirmwareType } from "../../constants/constants";
import { DumpData } from "../../utils/types";

import { InitialState } from "./DeviceStatusState";

export type BundleProgress = {
    completedPercent: number;
    progressBarName: string;
    stage: string;
    status: string;
};

export type UpdateBundleModalInfo = {
    isOpen: boolean;
    path?: string;
    step: number;
    newVersion?: string;
    firmwareType?: FirmwareType;
    currentVersion?: string | null;
    error?: string;
    stepWithError?: number;
    isSuccessful?: boolean;
    isFailed?: boolean;
};

export interface DeviceStatusContextState extends InitialState {
    setDumpReadingData: (data: DumpData) => void;
    setDallasSensorData: (data: string[]) => void;
    setLlsSensorData: (data: string[]) => void;
    setActiveDallasSensorValue: (data: string) => void;
    setActiveLlsSensorValue: (data: string) => void;
    setProgressBar: (data: {
        progressBarName: string;
        completedPercent: number;
    }) => void;
    setBundleProgress: (data: BundleProgress) => void;
    setUpdateFirmwareModalInfo: (data: UpdateBundleModalInfo) => void;
    resetProgressBars: () => void;
    setUpdateBundleModalInfo: (data: UpdateBundleModalInfo) => void;
    setBundleUpdateProgressSteps: (
        progress: BundleProgress | null,
        steps: any,
    ) => void;
    setShouldStepUpdate: (
        mcuType: string,
        fileType: string,
        shouldUpdate: boolean,
    ) => void;
}

const DeviceStatusContext = createContext({} as DeviceStatusContextState);

export default DeviceStatusContext;
