import { useContext, useEffect, useMemo } from "react";

import { IoTableColumns } from "../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import { getCanFilters } from "../columns/canColumns";
import { getIoFilters } from "../columns/ioTableColumns";

interface UseIoTableEffectsProps {
    data: any[];
    setHiddenColumns: (columns: any[]) => void;
    isCanTable: boolean;
    setAllFilters: (filters: any[]) => void;
    setGlobalFilter: (value: string) => void;
}

export const useIoTableEffects = ({
    data,
    setHiddenColumns,
    isCanTable,
    setAllFilters,
    setGlobalFilter,
}: UseIoTableEffectsProps): void => {
    const {
        activeColumns,
        activeFilters,
        activePriority,
        activeOperand,
        activeStatus,
        searchValue,
        setColumns,
        columns: defaultColumns,
    } = useContext(InputOutputContext);

    const memoizedData = useMemo(() => data, []);

    const { layoutData } = useContext(LayoutContext);

    useEffect(() => {
        const firstIoElement = memoizedData[0];
        const hasPhone = Boolean(firstIoElement?.phone);
        const hasSmsText = Boolean(firstIoElement?.text);
        const hasAvgConst =
            Boolean(firstIoElement?.averagingConstant) ||
            Boolean(firstIoElement?.avgConst);

        let filteredColumns = defaultColumns;

        if (!hasPhone && !hasSmsText) {
            filteredColumns = filteredColumns.filter(
                (item: any) =>
                    item.value !== IoTableColumns.sendSmsTo &&
                    item.value !== IoTableColumns.smsText,
            );
        }

        if (!hasAvgConst) {
            filteredColumns = filteredColumns.filter(
                (item: any) => item.value !== IoTableColumns.avgConst,
            );
        }

        if (!layoutData?.isOnline) {
            filteredColumns = filteredColumns.filter(
                (item: any) => item.value !== IoTableColumns.currentValue,
            );
        }

        setColumns(filteredColumns);

        const hideCurrentValueColumn = (item: string): boolean =>
            layoutData?.isOnline ? item !== IoTableColumns.currentValue : true;

        const hiddenColumns = Object.values(IoTableColumns).filter(
            (item) =>
                !activeColumns.includes(item) &&
                item !== IoTableColumns.name &&
                hideCurrentValueColumn(item),
        );

        !isCanTable && setHiddenColumns(hiddenColumns);
    }, [activeColumns]);

    // Filter management effect
    useEffect(() => {
        const filters = isCanTable
            ? getCanFilters(activeOperand, activeStatus)
            : getIoFilters(
                  activeFilters,
                  activePriority,
                  activeOperand,
                  activeStatus,
              );
        setAllFilters(filters);
    }, [activeFilters, activePriority, activeOperand, activeStatus]);

    // Search management effect
    useEffect(() => {
        setGlobalFilter(searchValue);
    }, [searchValue]);
};
