/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect, useState } from "react";

import ReactGA from "react-ga4";

import { css, jsx } from "@emotion/react";

import { useAtom } from "jotai";

import { AlertStatus, ConnectionTypes } from "../../../../constants/constants";
import LayoutContext from "../../../../context/layout/layoutContext";
import { DeviceStatusAtom } from "../../../../context/webSockets/webSocketsState";
import { DeviceStatusWindow } from "../../../../generatedTypes";
import { chooseValue } from "../../../../utils/helpers";
import { AlertData } from "../../../../utils/types";
import useMediaQuries from "../../../../utils/useMediaQueries";
import Alert from "../../../Alert";
import Container from "../../../Container";
import Loader from "../../../Loader/EditorSkeleton/GeneralSkeleton";
import TypedGrid from "../../../MuiComponents/TypedGrid";

import CellularInformation from "./CellularInformation";
import DeviceInformation from "./DeviceInformation";
import DoutInformation from "./DoutInformation";
import GnssInformation from "./GnssInformation";
import MaintenanceInformation from "./MaintenanceInformation";
import SimSecurityInformation from "./SimSecurityInformation";
import useOnlineView from "./useOnlineView";

const OnlineView = () => {
    const { layoutData, actionLoading } = useContext(LayoutContext);

    const showStatuses = chooseValue(
        layoutData?.isConfigurationSupported,
        true,
        false,
    );
    const visibilitySettings = chooseValue(
        layoutData?.deviceStatusWindow,
        layoutData?.deviceStatusWindow,
        {} as DeviceStatusWindow,
    );

    const [deviceStatus] = useAtom(DeviceStatusAtom);

    const [visibleAlerts, setVisibleAlerts] = useState<AlertData[]>([]);
    const [hasSimInformation, setHasSimInformation] = useState<boolean>(false);

    const {
        getFilterAlerts,
        hasSimStates,
        showGnssInfo,
        showDoutInfo,
        showMaintenanceInfo,
        keyBaseStyles,
        valueBaseStyles,
    } = useOnlineView();

    useEffect(() => {
        let filteredAlerts: AlertData[] = getFilterAlerts();
        setVisibleAlerts(filteredAlerts);
    }, [deviceStatus]);

    useEffect(() => {
        const simAvailable = chooseValue(hasSimStates(), true, false);
        setHasSimInformation(simAvailable);
    }, [deviceStatus?.simSecurity]);

    const { toXl, fromXl } = useMediaQuries();

    useEffect(() => {
        ReactGA.gtag("event", "device_connection", {
            connectionType: chooseValue(
                (layoutData?.connectionType ?? 0) === ConnectionTypes.Serial,
                "serial",
                "bluetooth",
            ),
            deviceModel: layoutData?.fmType,
        });
    }, []);

    const showLoader = () => actionLoading || !deviceStatus;

    return showLoader() ? (
        <Loader fullWidth />
    ) : (
        <Container
            css={css({
                paddingTop: "24px",
                paddingBottom: "80px",
                width: "100%",
            })}
        >
            <TypedGrid
                container
                rowSpacing={2}
                columnSpacing={{ md: 2, xl: 3 }}
            >
                <TypedGrid item xs0={12}>
                    {visibleAlerts.map((alert) => (
                        <Alert
                            key={alert.title}
                            status={alert.type.toLowerCase() as AlertStatus}
                            title={alert.title}
                            description={alert.description}
                        />
                    ))}
                </TypedGrid>
                <TypedGrid
                    item
                    xs0={12}
                    xl={6}
                    css={css({
                        "& > div + div": {
                            marginTop: "16px",
                        },
                    })}
                >
                    <DeviceInformation
                        keyBaseStyles={keyBaseStyles}
                        valueBaseStyles={valueBaseStyles}
                        visibilitySettings={visibilitySettings}
                    />

                    {toXl && showGnssInfo() && (
                        <GnssInformation
                            keyBaseStyles={keyBaseStyles}
                            valueBaseStyles={valueBaseStyles}
                        />
                    )}

                    {showStatuses && (
                        <Fragment>
                            {deviceStatus!.cellularInformation && (
                                <CellularInformation
                                    data={deviceStatus!.cellularInformation}
                                    keyBaseStyles={keyBaseStyles}
                                    valueBaseStyles={valueBaseStyles}
                                    visibilitySettings={visibilitySettings}
                                />
                            )}
                            {toXl && hasSimInformation && (
                                <SimSecurityInformation
                                    valueBaseStyles={valueBaseStyles}
                                />
                            )}
                            {showMaintenanceInfo() && (
                                <MaintenanceInformation
                                    visibilitySettings={visibilitySettings}
                                    valueBaseStyles={valueBaseStyles}
                                />
                            )}
                        </Fragment>
                    )}

                    {showDoutInfo() && (
                        <DoutInformation
                            data={deviceStatus!.doutInformation!}
                        />
                    )}
                </TypedGrid>

                <TypedGrid
                    item
                    xs0={12}
                    xl={6}
                    css={css({
                        "& > div + div": {
                            marginTop: "16px",
                        },
                    })}
                >
                    {fromXl && showGnssInfo() && (
                        <GnssInformation
                            keyBaseStyles={keyBaseStyles}
                            valueBaseStyles={valueBaseStyles}
                        />
                    )}
                    {fromXl && hasSimInformation && (
                        <SimSecurityInformation
                            valueBaseStyles={valueBaseStyles}
                        />
                    )}
                </TypedGrid>
            </TypedGrid>
        </Container>
    );
};

export default OnlineView;
