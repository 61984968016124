/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import { MenuItem } from "@mui/material";

import { defaultListLanguageFlag } from "../../../../../../../constants/constants";
import DeviceStatusContext from "../../../../../../../context/deviceStatus/deviceStatusContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import ListContext from "../../../../../../../context/list/listContext";
import { ErrorsObj } from "../../../../../../../context/list/listReducer";
import SettingsContext from "../../../../../../../context/settings/settingsContext";
import {
    getIdTooltipText,
    replacePlaceholders,
} from "../../../../../../../utils/helpers";
import { validateTextLengthAndRegex } from "../../../../../../../utils/validations";
import InputField from "../../../../../../MuiComponents/InputField";
import PhoneNumberInput from "../../../../../../MuiComponents/PhoneNumberInput";
import Select from "../../../../../../MuiComponents/Select";
import Tag from "../../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import OneWireInput from "../../../../../../MuiComponents/reusableInputs/OneWireInput";
import { ListItemInterface } from "../List";

interface ListInputProps {
    i: number;
    errors: ErrorsObj;
    data: ListItemInterface;
    isDisabled: boolean;
    setSize: (i: number, size: number) => void;
    change: (
        inputIndex: number,
        inputValue: string,
        errorValue: string | null,
        parameterId: number,
        language?: string,
    ) => void;
    placeholder: string;
    hasTopPosition?: boolean;
    isLastItem?: boolean;
}

const ListInput = ({
    i,
    errors,
    data,
    isDisabled,
    setSize,
    change,
    placeholder,
    hasTopPosition,
}: ListInputProps) => {
    const { index, value: initialValue, language, parameterId } = data;

    const {
        setActiveDallasSensorValue,
        activeDallasSensorValues,
        llsSensorData,
        activeLlsSensorValues,
        setActiveLlsSensorValue,
    } = useContext(DeviceStatusContext);

    const { isParameterIdsHidden } = useContext(SettingsContext);

    const {
        maxLength,
        modalItemLabel,
        renderType,
        isNumberType,
        validate,
        validationMessage,
    } = useContext(ListContext);

    const { t } = useContext(LanguageContext);

    const [value, setValue] = useState("");

    const inputListRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!activeDallasSensorValues.length) {
            setActiveDallasSensorValue(initialValue);
        }
        if (llsSensorData && !activeLlsSensorValues.length) {
            setActiveLlsSensorValue(initialValue);
        }
    }, []);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {}, [renderType]);

    useEffect(() => {
        inputListRef.current &&
            setSize(i, inputListRef.current.getBoundingClientRect().height);
    }, [errors, i, setSize]);

    const handlePhoneChange = (e: string, country?: any): void => {
        const newValue = e;
        const errorValue =
            newValue.length > maxLength
                ? replacePlaceholders(t.ValidateMaxLength, maxLength)
                : null;

        change(index, newValue, errorValue, parameterId, country?.countryCode);
    };

    const handleSensorChange = (e: any): void => {
        const newValue = e.target.value;
        initialValue !== "0"
            ? setActiveDallasSensorValue(initialValue)
            : setActiveDallasSensorValue(newValue);
        change(index, newValue, null, parameterId);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const newValue = e.target.value;

        const errorValue = validateTextLengthAndRegex(
            newValue,
            0,
            maxLength,
            validate || "",
            validationMessage || "",
            t,
        );

        change(index, newValue, errorValue, parameterId);
    };

    const renderIconRight = () => {
        if (!isParameterIdsHidden) {
            return (
                <Tooltip
                    title={getIdTooltipText(parameterId, 0)}
                    small
                    placement="top"
                >
                    <span>
                        <Tag size="tiny" color="white" title="ID" />
                    </span>
                </Tooltip>
            );
        }
        return null;
    };

    const isDallasList = renderType === "DallasLLS";
    const isLlsList = renderType === "RS485LLS";

    const renderInput = () => {
        if (renderType === "Phone") {
            return (
                <PhoneNumberInput
                    hasTopPosition={hasTopPosition}
                    label={`${modalItemLabel} #${index}`}
                    rightLabel={`ID: ${parameterId}`}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    onChange={handlePhoneChange}
                    value={value}
                    iconRight={renderIconRight()}
                    locale={language || defaultListLanguageFlag}
                />
            );
        }
        if (isDallasList) {
            return (
                <OneWireInput
                    data={{
                        id: String(parameterId),
                        ref: inputListRef,
                        blockLabel: `${modalItemLabel} ${index}`,
                        iconRight: renderIconRight(),
                        placeholder: placeholder,
                        value: value,
                        disabled: isDisabled,
                        onChange: handleInputChange,
                        error: errors[index] ?? "",
                    }}
                />
            );
        }
        if (isLlsList) {
            const renderListItems = () => {
                return ["0", ...llsSensorData].map((item, index) => {
                    return (
                        <MenuItem
                            key={item}
                            disabled={activeLlsSensorValues.some(
                                (val) => val === item,
                            )}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    );
                });
            };
            return (
                <Select
                    id={String(parameterId)}
                    value={value}
                    label={
                        isDallasList
                            ? `Temperature Sensor ${index}`
                            : `LLS Sensor ${index}`
                    }
                    iconRight={renderIconRight()}
                    onChange={handleSensorChange}
                    wrapperStyle={css({
                        scrollMargin: "16px",

                        "& .MuiInputBase-input > span": {
                            padding: "0 !important",
                        },
                    })}
                    medium
                >
                    {renderListItems()}
                </Select>
            );
        }

        return (
            <InputField
                id={String(parameterId)}
                ref={inputListRef}
                fullWidth
                size="medium"
                label={`${modalItemLabel} #${index}`}
                iconRight={renderIconRight()}
                placeholder={placeholder}
                type={isNumberType ? "number" : "text"}
                value={value}
                disabled={isDisabled}
                onChange={handleInputChange}
                error={errors[index]}
                wrapperStyle={css({
                    paddingTop: i === 0 ? "0" : "16px",
                })}
            />
        );
    };

    const getPaddingTop = () => {
        if (isDallasList) {
            return "8px";
        }
        if (i === 0) {
            return "0";
        }
        return "16px";
    };

    return (
        <div
            ref={inputListRef}
            css={css({
                paddingTop: getPaddingTop(),
            })}
        >
            {renderInput()}
        </div>
    );
};

export default ListInput;
