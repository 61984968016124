/** @jsxRuntime classic */

/** @jsx jsx */
import React, { Fragment, useContext, useEffect } from "react";

import { css, jsx } from "@emotion/react";

import { ReactComponent as BluetoothMarkIcon } from "../../../../../assets/icons/bluetooth/BluetoothMark.svg";
import { ReactComponent as EmptyBluetoothStateIcon } from "../../../../../assets/icons/bluetooth/EmptyBluetoothState.svg";
import { TableTypes } from "../../../../../constants/constants";
import BluetoothViewContext from "../../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../../context/language/languageContext";
import usePolling from "../../../../../utils/usePolling";
import useTct from "../../../../../utils/useTct";
import Button from "../../../../MuiComponents/Button";
import Table from "../../../../MuiComponents/Table";

import BluetoothViewToolbar from "./BluetoothViewToolbar";

export interface BluetoothViewProps {}

const BluetoothView: React.FunctionComponent<BluetoothViewProps> = () => {
    const {
        searchValue,
        resetBluetoothView,
        setAddDeviceModalOpen,
        setBluetoothDevices,
        bluetoothDevices,
        isAddDeviceModalOpen,
        setBluetoothScenarioModalInfo,
    } = useContext(BluetoothViewContext);

    const { t } = useContext(LanguageContext);

    const { getBluetoothDevicesAsync, deleteBluetoothDevicesAsync } = useTct();

    const handleFetchBluetoothDevices = async () => {
        const data = await getBluetoothDevicesAsync();
        setBluetoothDevices(data);
    };

    usePolling(handleFetchBluetoothDevices, 5000, !isAddDeviceModalOpen);

    useEffect(() => {
        return () => {
            resetBluetoothView();
        };
    }, []);

    const handleDeleteDevices = async (items: any) => {
        try {
            await deleteBluetoothDevicesAsync(items);
            await handleFetchBluetoothDevices();
        } catch (error) {
            console.log(error);
        }
    };

    const handleOnEditClick = (item: any) => {
        setBluetoothScenarioModalInfo({
            isOpen: true,
            data: item,
            isEdit: true,
        });
    };

    return (
        <div>
            <BluetoothViewToolbar />
            <div
                css={css({
                    maxWidth: "100%",
                    overflowX: "auto",
                })}
            >
                <Table
                    data={
                        bluetoothDevices?.map((item: any, index: number) => ({
                            ...item,
                            id: index,
                        })) || []
                    }
                    searchValue={searchValue}
                    tableType={TableTypes.Bluetooth}
                    onDeleteClick={handleDeleteDevices}
                    onActionColumnEdit={handleOnEditClick}
                    customContainerStyles={{ maxHeight: "580px" }}
                    customTableStyles={{ height: "580px" }}
                    emptyState={
                        <Fragment>
                            <EmptyBluetoothStateIcon />
                            <div
                                css={css({
                                    fontFamily: "Open Sans",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "20px", // 125%
                                    letterSpacing: "0.15px",
                                    marginBottom: "8px",
                                })}
                            >
                                {t.BluetoothTableEmptyStateTitle}
                            </div>
                            <div
                                css={css({
                                    fontFamily: "Open Sans",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "20px", // 142.857%
                                    letterSpacing: "0.1px",
                                    marginBottom: "8px",
                                })}
                            >
                                {t.BluetoothTableEmptyStateDescription}
                            </div>
                            <Button
                                variant="iconLeft"
                                color="primary"
                                onClick={() => setAddDeviceModalOpen(true)}
                                size="small"
                                idForTesting="emptyBluetoothState"
                                icon={<BluetoothMarkIcon />}
                            >
                                {t.AddBluetoothDevice}
                            </Button>
                        </Fragment>
                    }
                />
            </div>
        </div>
    );
};

export default BluetoothView;
