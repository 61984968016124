/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import InfoIcon from "../../../../../assets/icons/InfoIcon";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import { Component, TableControl } from "../../../../../generatedTypes";
import Tooltip from "../../../../MuiComponents/Tooltip";

import { ConvertedCellValue } from "./ScheduleTable/types";
import useCellStylingAndTooltips from "./useCellStylingAndTooltips";

const useScheduleTableData = (data?: TableControl) => {
    const { columns } = data || {};

    const {
        colors: { blue700 },
    } = useContext(ThemeContext);

    const { findParameterValueById, findDisabledParameterById } =
        useContext(MenuItemContext);

    const { renderRowIds, getReadOnlyTableCellStyle } =
        useCellStylingAndTooltips();

    const tableRows = columns
        ? columns[0]?.components.map((_, rowIndex) =>
              columns.map((column) => column.components[rowIndex]),
          )
        : [];

    const getRowValues = (row: Component[]) => {
        return row.map((cell, colIndex) => {
            const { parameterValue, parameterId, label, switchId } = cell;

            const paramValFromContext = findParameterValueById(parameterId);

            return {
                parameterValue: paramValFromContext || parameterValue,
                parameterId,
                label,
                switchId,
                colIndex,
            };
        });
    };

    const renderReadOnlyTableCell = (
        cell: any,
        rowIndex: number,
        colIndex: number,
        nextCellIsEmpty: boolean,
        rowValues: ConvertedCellValue[],
    ) => {
        const switchIdFromFirstCell = Number(rowValues[0].switchId);
        const isRowDisabled = findDisabledParameterById(switchIdFromFirstCell);

        if (cell.parameterId === 0) {
            return (
                <div
                    css={[
                        {
                            ...getReadOnlyTableCellStyle(
                                colIndex,
                                nextCellIsEmpty,
                                true,
                            ),
                        },
                        {
                            display: "flex",
                            alignItems: "flex-start",
                            padding: "8px 8px 8px 0",
                            columnGap: "8px",
                        },
                    ]}
                    key={`cell-${rowIndex}-${colIndex}-read-only`}
                >
                    <span>{cell.label}</span>

                    <Tooltip
                        title={renderRowIds(rowValues)}
                        small
                        placement="top"
                    >
                        <span
                            css={css({
                                display: "flex",
                                alignItems: "center",
                                margin: "2px 0",

                                "& > svg": {
                                    fontSize: "16px",
                                    color: blue700,
                                },
                            })}
                        >
                            <InfoIcon />
                        </span>
                    </Tooltip>
                </div>
            );
        }

        if ((!cell.parameterValue || isRowDisabled) && colIndex === 1) {
            return (
                <div
                    key={`cell-${rowIndex}-${colIndex}-read-only-unavailable`}
                    css={getReadOnlyTableCellStyle(
                        colIndex,
                        nextCellIsEmpty || isRowDisabled,
                    )}
                >
                    N/A
                </div>
            );
        }

        if (cell.parameterValue && !isRowDisabled) {
            return (
                <span
                    key={`cell-${rowIndex}-${colIndex}-read-only-value`}
                    css={getReadOnlyTableCellStyle(colIndex, nextCellIsEmpty)}
                >
                    {cell.parameterValue}
                </span>
            );
        }

        return null;
    };

    return {
        columns,
        tableRows,
        getRowValues,
        renderRowIds,
        renderReadOnlyTableCell,
    };
};

export default useScheduleTableData;
