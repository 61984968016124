/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Segment from "..";
import LanguageContext from "../../../../../context/language/languageContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import ManualGeofenceContext, { EditorState, } from "../../../../../context/manualGeofence/manualGeofenceContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import Button from "../../../../MuiComponents/Button";
import AlertContext from "../../../../../context/alert/alertContext";
import useTct from "../../../../../utils/useTct";
import { AlertStatus } from "../../../../../constants/constants";

export interface ManualGeofenceEditorProps {
    index: number;
}

const ManualGeofenceEditor: React.FunctionComponent<
    ManualGeofenceEditorProps
> = ({ index }) => {
    const {
        colors: { gray200, textDark, blue700 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const { setManualGeofenceParameters, hasParameterErrors } =
        useContext(MenuItemContext);
    const { setAlert, removeAlert } = useContext(AlertContext);

    const {
        setPreviousGeofenceParameters,
        previousGeofenceParameters,
        setLatitudeLongituteValues,
        setRadius,
        isSaveDisabled,
        isLoading,
        setEditState,
        setRefetchList,
        editState,
    } = useContext(ManualGeofenceContext);

    const [segments, setSegments] = useState([]);
    const [geozoneName, setGeozoneName] = useState("");

    const { getGeozoneAsync, updateParametersAsync } = useTct();

    const previousParameters = Object.entries(previousGeofenceParameters).map(
        (item) => {
            return {
                id: item[0],
                value: item[1],
            };
        }
    );

    useEffect(() => {
        const getSelectedGeozone = async () => {
            try {
                if (!layoutData) {
                    throw new Error("Cannot get a geozone without a layout");
                }

                const data = await getGeozoneAsync(layoutData.id, index, false);

                setManualGeofenceParameters(data);
                setPreviousGeofenceParameters(data);
                setSegments(data.manualGeozones.segments as never[]);
                setGeozoneName((data.geozoneName ?? ""));
            } catch (error) {
                console.log(error);
            }
        };

        getSelectedGeozone();
        removeAlert();
        setLatitudeLongituteValues({
            lat: [],
            long: [],
        });
        setRadius(undefined);
    }, [editState.currentIndex]);

    const onEditCancel = () => {
        setEditState({
            currentIndex: -1,
            previousIndex: editState.currentIndex,
            currentEditorState: EditorState.Cancel,
        });
    };

    const handleCancel = async () => {
        try {
            await updateParametersAsync(
                layoutData?.id || 0,
                previousParameters,
                false,
                true
            );
            setManualGeofenceParameters({
                manualGeozones: {
                    segments: previousParameters.map((item) => {
                        return {
                            component: {
                                componentType: "",
                                parameterId: item.id,
                                parameterValue: item.value,
                            },
                        };
                    }),
                },
            });
            onEditCancel();
        } catch (error) {
            console.log(error);
        }
    };

    const hasErrors = hasParameterErrors();

    const onEditCommit = () => {
        setEditState({
            currentIndex: -1,
            previousIndex: editState.currentIndex,
            currentEditorState: EditorState.Commit,
        });
        setRefetchList(true);
    };

    return (
        <div>
            <div
                css={css({
                    padding: "16px 24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: `1px solid ${gray200}`,
                    borderRadius: "8px 0px 0px 0px",
                })}
            >
                <h3
                    css={css({
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20px",
                        textAlign: "center",
                        letterSpacing: "0.15px",
                        color: textDark,
                    })}
                >
                    {geozoneName}
                </h3>
                <div
                    css={css({
                        display: "flex",
                        gap: "8px",
                    })}
                >
                    <Button
                        onClick={handleCancel}
                        size="normal"
                        color="secondary"
                        idForTesting="cancelButton"
                        variant="textOnly"
                    >
                        {t.Cancel}
                    </Button>
                    <Button
                        onClick={() => {
                            onEditCommit();
                            setAlert(
                                AlertStatus.Success,
                                `${t.ManualGeofenceNewGeozone} "${geozoneName}" ${t.ManualGeofenceNewGeozoneSuccess}`
                            );
                        }}
                        size="normal"
                        color="primary"
                        disabled={isSaveDisabled || hasErrors || isLoading}
                        idForTesting="saveButton"
                        variant="textOnly"
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={13}
                                css={css`
                                    color: ${blue700};
                                `}
                            />
                        ) : (
                            t.Save
                        )}
                    </Button>
                </div>
            </div>
            <div
                css={css({
                    padding: "16px 24px",
                    maxHeight: "588px",
                    overflow: "auto",
                })}
            >
                {segments.map((segment, segmentIndex) => (
                    <Segment
                        key={segmentIndex}
                        data={segment}
                        lastSegment={false}
                    />
                ))}
            </div>
        </div>
    );
};

export default ManualGeofenceEditor;
